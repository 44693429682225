// SummaryComponent.js
import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

function FlightSummary({ flights }) {

    const [selectedFlight, setSelectedFlight] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const handleBuyClick = (flight) => {
        setSelectedFlight(flight);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedFlight(null);
    };



    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            {/* Flight Summary Header */}
            <div
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderBottom: '1px solid #ddd',
                }}
            >
                <Typography sx={{fontSize: '16px'}}>Flight Summary ({flights.length})</Typography>
            </div>

            {/* Scrollable Flight List */}
            <div style={{overflow: 'auto', flex: 1, padding: '10px'}}>
                {flights.map((flight, idx) => {
                    const totalAmount = parseFloat(flight.total_amount) || 0; // Safely parse total_amount

                    return (
                        <Box
                            key={idx}
                            border={1}
                            padding={2}
                            marginBottom={2}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {/* Left-aligned: Owner's name and flight number */}
                            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <Typography sx={{fontSize: '16px'}}>{flight.owner.name}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    # {flight.slices[0]?.segments[0]?.marketing_carrier_flight_number || 'N/A'}
                                </Typography>
                            </div>

                            {/* Right-aligned: Price and Buy button */}
                            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                    ${totalAmount.toFixed(2)}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleBuyClick(flight)}
                                >
                                    Buy
                                </Button>
                            </div>
                        </Box>
                    );
                })}
            </div>

            {/* Dialog for Flight Details */}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
                <DialogTitle>Flight Details</DialogTitle>
                <DialogContent>
                    {selectedFlight && (
                        <Box>
                            <Typography variant="h6">{selectedFlight.owner.name}</Typography>
                            <Typography>{`Flight Number: # ${selectedFlight.slices[0]?.segments[0]?.marketing_carrier_flight_number || 'N/A'}`}</Typography>
                            <Typography>{`From: ${selectedFlight.slices[0]?.origin.iata_code}`}</Typography>
                            <Typography>{`To: ${selectedFlight.slices[0]?.destination.iata_code}`}</Typography>
                            <Typography>{`Duration: ${selectedFlight.slices[0]?.duration}`}</Typography>
                            <Typography>{`Price: $${parseFloat(selectedFlight.total_amount || 0).toFixed(2)}`}</Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};


export default FlightSummary;
