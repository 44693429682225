import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';

import {
    Autocomplete,
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText,
    ListItem,
    ListItemAvatar, Menu, MenuItem, MenuList, Tab, TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import Badge from "@mui/material/Badge";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import Box from "@mui/material/Box";
import './activities.css';
import ListItemIcon from "@mui/material/ListItemIcon";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot, TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import {editChipStyle} from "../../ButtonStyle/Edit";
import {addChipStyle} from "../../ButtonStyle/Add";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, stringAvatar, stringToColor} from "../../Helpers/TextStyling";
import MealLocationTab from "../Meals/MealLocationTab";
import sample_attendees from "../../../sampleData/Attendees";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ActivityLocationTab from "./ActivityLocationTab";

dayjs.extend(duration);


const CustomTimelineDot = ({ beginTime }) => {
    // Ensure the beginTime is valid before rendering
    if (!beginTime) return null;

    // Display the begin_time in a rectangle box
    return (
        <Box
            sx={{
                width: 'auto', // Set width to auto to accommodate the time length
                height: 40, // Adjust height as needed
                px: 1, // Add horizontal padding for a rectangular look
                borderRadius: 4, // Small border radius for slightly rounded corners (or remove for a full rectangle)
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body2" noWrap>
                {beginTime} {/* Show the provided begin_time */}
            </Typography>
        </Box>
    );
};





function ActivitiesCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Attendees, SampleAttendees } = dataArray;

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    const [ selectedAttendeesArray, setSelectedAttendeesArray ] = useState( []);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [eventActivity, setEventActivity] = useState(props.activity);
    const [selectedAction, setSelectedAction] = useState("");
    const [activityTabSelected, setActivityTabSelected] = useState("");

    const fetchedRef = useRef(false);
    useEffect(() => {
        console.log("props.activity has been updated --- : ", props.activity);

        if (eventActivity.attendees && eventActivity.attendees.length > 0) {
            console.log("attendeesReferenceArray : ", attendeesReferenceArray);
            const initialSelectedAttendees = eventActivity.attendees
                .map((activityAttendee) =>
                    attendeesReferenceArray.find((attendee) => attendee.id === activityAttendee.id)
                );

            console.log("initialSelectedAttendees : ", initialSelectedAttendees);
            setSelectedAttendeesArray(initialSelectedAttendees);
        }

        const fetchPhotos = async () => {
            // Ensure no redundant fetch
            if (
                fetchedRef.current ||
                (props.activity.photos && props.activity.photos.length > 0) ||
                !props.activity.location.google_place_id
            ) {
                return; // Exit if photos already exist, have been fetched, or no place ID is available
            }

            fetchedRef.current = true; // Mark as fetched to prevent duplicate fetches

            const request = {
                placeId: props.activity.location.google_place_id,
                fields: ['photo'],
            };

            try {
                const results = await new Promise((resolve, reject) => {
                    const _global_map_service = new window.google.maps.places.PlacesService(
                        props._global_map_ref.current
                    );
                    _global_map_service.getDetails(request, (place, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve(place);
                        } else {
                            reject(status);
                        }
                    });
                });

                if (results.photos) {
                    const newPhotos = results.photos.map(photo =>
                        photo.getUrl({ maxWidth: 175, maxHeight: 175 })
                    );

                    // Update state without mutating props
                    setEventActivity(prev => ({ ...prev, photos: newPhotos }));
                } else {
                    console.log("No photos available for this activity.");
                }
            } catch (error) {
                console.error("Error fetching photos for activity:", error);
            }
        };

        fetchPhotos();
    }, [props.activity]);


    const [beginDate, setBeginDate] = useState(
        props.activity?.time_table?.dates?.[0]?.date || ""
    );
    const [beginTime, setBeginTime] = useState(
        props.activity?.time_table?.dates?.[0]?.['begin_time'] || ""
    );
    const [endDate, setEndDate] = useState(
        props.activity?.time_table?.dates?.[props.activity?.time_table?.dates?.length - 1]?.date || ""
    );
    const [endTime, setEndTime] = useState(
        props.activity?.time_table?.dates?.[props.activity?.time_table?.dates?.length - 1]?.['end_time'] || ""
    );

    const [tabValue, setTabValue] = useState(
        props.activity?.time_table?.dates?.length > 0 || !props.activity?.time_table?.days?.length
            ? "date"
            : "day"
    );

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);
    const [showClockSelectBeginTime, setShowClockSelectBeginTime] = useState(false);
    const [showClockSelectEndTime, setShowClockSelectEndTime] = useState(false);
    const [showSelectActivityBeginDay, setShowSelectActivityBeginDay] = useState(false);
    const [showSelectActivityEndDay, setShowSelectActivityEndDay] = useState(false);
    const [showCalendarSelectBeginDay, setShowCalendarSelectBeginDay] = useState(false);
    const [showCalendarSelectEndDay, setShowCalendarSelectEndDay] = useState(false);
    const [showClockSelectBeginDayTime, setShowClockSelectBeginDayTime] = useState(false);
    const [showClockSelectEndDayTime, setShowClockSelectEndDayTime] = useState(false);
    const [selectedActivityBeginDay, setSelectedActivityBeginDay] = useState('');
    const [selectedActivityEndDay, setSelectedActivityEndDay] = useState('');

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);


    useEffect(() => {
        console.log("!!! kickoff --- : ");
        setEventActivity(props.activity);

        buildTimeTableForActivity();
    }, []);

    useEffect(() => {
        console.log("eventActivity has been updated --- : ");

        if (eventActivity !== usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities
            .find(_activity => _activity.id === eventActivity.id || _activity.id === eventActivity.temp_id)) {
            console.log("the Activity needs to be updated --- : ", eventActivity);
            if (Object.keys(eventActivity).length > 0 && eventActivity !== props.activity){
                props.updateActivityDetails(eventActivity);
            }
        }

    }, [eventActivity]);

    useEffect(()=> {

        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move activity attendees to the top of the list
            for (let i = eventActivity.attendees.length - 1; i >= 0; i--) {
                const activityAttendee = eventActivity.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === activityAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);

    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.activity[amount_total] : "+props.activity["amount_total"]+" and "+eventActivity["amount_total"]);
        if (props.activity["amount_total"] !== eventActivity["amount_total"] || props.activity["currency"] !== eventActivity["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventActivity["attendees"].some((_attendee, index) => {
                const initialAttendee = props.activity["attendees"].find(a => a.id === _attendee.id);

                console.log("comparing attendee costs : "+_attendee.cost);
                console.log("comparing initialAttendee costs : "+initialAttendee["cost"]);

                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
            //turn on the button

            console.log("an attendee cost has changed");

            setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);


    function handlePromptShowActivityBeginDateCalendar(){
        //toggle the button
        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }
    function handlePromptShowActivityEndDateCalendar(){
        //toggle the button
        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);
    }

    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setTabValue(newValue);
    };

    function handleBeginDateChange(begin_date){
        setBeginDate(dayjs(begin_date).format('YYYY-MM-DD'));

        if (endDate === ""){
            setEndDate(dayjs(begin_date).format('YYYY-MM-DD'));
        }
    }
    function handleEndDateChange(end_date){
        setEndDate(dayjs(end_date).format('YYYY-MM-DD'));
    }
    function handlePromptShowActivityBeginTime(){
        //toggle the button
        //change button back to 'Edit'
        setShowClockSelectBeginTime(!showClockSelectBeginTime);
    }
    function handlePromptShowActivityEndTime(){
        //toggle the button

        //change button back to 'Edit'
        setShowClockSelectEndTime(!showClockSelectEndTime);
    }

    function handleBeginTimeChange(begin_time){
        console.log("begin_time : "+dayjs(begin_time).format('h:mm A'));
        setBeginTime(dayjs(begin_time).format('h:mm A'));
    }
    function handleEndTimeChange(end_time){
        setEndTime(dayjs(end_time).format('h:mm A'));
    }

    useEffect(() => {
        buildTimeTableForActivity();
    }, [beginDate, endDate, beginTime, endTime]);

    const buildTimeTableForActivity = () => {
        console.log("---- buildTimeTableForActivity");
        console.log("beginDate:", beginDate);
        console.log("beginTime:", beginTime);
        console.log("endDate:", endDate);
        console.log("endTime:", endTime);

        // Retrieve the existing time_table
        const existingTimeTable = eventActivity?.time_table || new TimeTable();

        console.log("---- EXISTING time_table", existingTimeTable);

        // Parse dates only if they are not empty
        const start = beginDate ? dayjs(beginDate, "YYYY-MM-DD") : null;
        const end = endDate ? dayjs(endDate, "YYYY-MM-DD") : null;

        // Generate updates based on the provided data
        const updates = [];
        if (start && start.isValid() && end && end.isValid()) {
            console.log("---- valid begin date and end date");
            try {
                let currentDate = start;
                while (currentDate.isSameOrBefore(end)) {
                    const isStart = currentDate.isSame(start, "day");
                    const isEnd = currentDate.isSame(end, "day");

                    let currentBeginTime = null;
                    let currentEndTime = null;

                    if (isStart && beginTime) {
                        currentBeginTime = dayjs(beginTime, "h:mm A").format("h:mm A");
                    }

                    if (isEnd && endTime) {
                        currentEndTime = dayjs(endTime, "h:mm A").format("h:mm A");
                    }

                    updates.push({
                        date: currentDate.format("YYYY-MM-DD"),
                        dayOfWeek: currentDate.format("dddd"),
                        'all-day': !currentBeginTime && !currentEndTime, // Mark as all-day if no times are present
                        'begin_time': currentBeginTime,
                        'end_time': currentEndTime,
                        notes: "",
                    });

                    currentDate = currentDate.add(1, "day");
                }
            } catch (e) {
                console.error(e);
            }
        } else if (start && start.isValid()) {
            console.log("---- valid begin date");
            updates.push({
                date: start.format("YYYY-MM-DD"),
                dayOfWeek: start.format("dddd"),
                'all-day': !beginTime, // All-day if no begin time is provided
                'begin_time': beginTime ? dayjs(beginTime, "h:mm A").format("h:mm A") : null,
                'end_time': null,
                notes: "",
            });
        } else if (end && end.isValid()) {
            console.log("---- valid end date");
            updates.push({
                date: end.format("YYYY-MM-DD"),
                dayOfWeek: end.format("dddd"),
                'all-day': !endTime, // All-day if no end time is provided
                'begin_time': null,
                'end_time': endTime ? dayjs(endTime, "h:mm A").format("h:mm A") : null,
                notes: "",
            });
        }

        console.log("---- the updates", updates);


        // Merge updates with the existing time_table
        const mergedTimeTable = {
            dates: mergeDates(existingTimeTable.dates, updates),
            days: [...new Set([...existingTimeTable.days])], // Retain unique days (assuming no changes to days in this function)
            id: existingTimeTable.id, // Preserve existing ID
        };

        console.log("---- the MERGED time_table", mergedTimeTable);

        // Update the activity with the merged time_table
        setEventActivity(prevState => ({
            ...prevState,
            time_table: mergedTimeTable
        }));

    };

    const handlePromptShowActivityBeginDaySelect  = () => {
        console.log("handlePromptShowActivityBeginDaySelect ");
        setShowSelectActivityBeginDay(!showSelectActivityBeginDay);
    };
    const handleActivityBeginDayChange = (dayKey, dayName) => {
        console.log("handleActivityBeginDayChange", dayKey, ":", dayName);

        // Set the selected meal day in state
        setSelectedActivityBeginDay(dayKey);

        setEventActivity(prevState => {
            // Only allow one day in the time_table.days array
            const updatedDays = [{ [dayKey]: dayName, 'all-day': false, notes: "" }];

            console.log("updatedDays", JSON.stringify(updatedDays, null, '\t'));

            // Return the updated state with the new day replacing any existing day
            return {
                ...prevState,
                time_table: {
                    ...prevState.time_table,
                    days: updatedDays, // Replace with the new selected day
                },
            };
        });
    };
    const renderActivityBeginDayLayout = () => {
        const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]; // Week layout starting with Sunday

        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName.slice(0, 2)); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayKey === selectedActivityBeginDay // Set the selected status based on the selectedActivityBeginDay
            });
        });

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name.slice(0, 2));
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '5px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{day.name.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: day.isSelected
                                                ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                                : 'rgba(0, 123, 255, 0.2)',
                                        }}
                                        clickable
                                        onClick={() => handleActivityBeginDayChange(day.key, day.name)} // Pass full day object on click
                                    />
                                );
                            } else {
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };

    // Function to merge dates
    const mergeDates = (existingDates, updates) => {
        const merged = [...existingDates];
        updates.forEach(update => {
            const index = merged.findIndex(existingDate => existingDate.date === update.date);
            if (index !== -1) {
                // Update existing date entry
                merged[index] = { ...merged[index], ...update };
            } else {
                // Add new date entry
                merged.push(update);
            }
        });
        return merged;
    };


    // Function to generate a new TimeTable for the Activity
    const buildTimeTableForActivityOLD = () => {
        console.log("---- buildTimeTableForActivity");

        const timeTable = new TimeTable();

        // Parse dates only if they are not empty
        const start = beginDate ? dayjs(beginDate, "MM/DD/YYYY") : null;
        const end = endDate ? dayjs(endDate, "MM/DD/YYYY") : null;

        // Generate dates based on the available data
        if (start && start.isValid() && end && end.isValid()) {
            console.log("---- valid begin date and end date");
            // Both dates are valid
            try {
                let currentDate = start;
                while (currentDate.isSameOrBefore(end)) {
                    const isStart = currentDate.isSame(start, "day");
                    const isEnd = currentDate.isSame(end, "day");

                    let currentBeginTime = null;
                    let currentEndTime = null;

                    if (isStart && beginTime) {
                        currentBeginTime = dayjs(beginTime, "h:mm A").format("h:mm A");
                    }

                    if (isEnd && endTime) {
                        currentEndTime = dayjs(endTime, "h:mm A").format("h:mm A");
                    }

                    timeTable.dates.push({
                        date: currentDate.format("YYYY-MM-DD"),
                        dayOfWeek: currentDate.format("dddd"),
                        'all-day': !currentBeginTime && !currentEndTime, // Mark as all-day if no times are present
                        'begin_time': currentBeginTime,
                        'end_time': currentEndTime,
                        notes: "",
                    });

                    currentDate = currentDate.add(1, "day");
                }
            } catch (e){console.error(e);}
            console.log("---- the PROPOSED time_table", timeTable);

        } else if (start && start.isValid()) {
            console.log("---- valid begin date");
            // Only the start date is valid
            timeTable.dates.push({
                date: start.format("YYYY-MM-DD"),
                dayOfWeek: start.format("dddd"),
                'all-day': !beginTime, // All-day if no begin time is provided
                'begin_time': beginTime ? dayjs(beginTime, "h:mm A").format("h:mm A") : null,
                'end_time': null,
                notes: "",
            });
        } else if (end && end.isValid()) {
            console.log("---- valid end date");
            // Only the end date is valid
            timeTable.dates.push({
                date: end.format("YYYY-MM-DD"),
                dayOfWeek: end.format("dddd"),
                'all-day': !endTime, // All-day if no end time is provided
                'begin_time': null,
                'end_time': endTime ? dayjs(endTime, "h:mm A").format("h:mm A") : null,
                notes: "",
            });
        }

        // If only times are provided but no valid dates, return an empty timeTable
        if ((!start || !start.isValid()) && (!end || !end.isValid()) && (beginTime || endTime)) {
            console.warn("Times provided but no valid dates available. TimeTable remains empty.");
        }

        console.log("---- the new time_table", timeTable);

        setEventActivity(prevState => ({
            ...prevState,
            time_table: timeTable
        }));

    };



    const buildTimeTableForActivity1 = () => {
        const timeTable = new TimeTable();

        // Parse the begin and end dates
        const start = dayjs(beginDate, "MM/DD/YYYY");
        const end = dayjs(endDate, "MM/DD/YYYY");

        // Check if dates are valid
        if (!start.isValid() || !end.isValid()) {
            console.error("Invalid dates provided for the Activity.");
            return timeTable;
        }

        // Generate dates between start and end dates
        let currentDate = start;
        while (currentDate.isSameOrBefore(end)) {
            timeTable.dates.push({
                date: currentDate.format("YYYY-MM-DD"),
                dayOfWeek: currentDate.format("dddd"),
                'all-day': true,
                notes: "",  // You can modify this as needed
            });
            currentDate = currentDate.add(1, 'day');  // Move to the next day
        }

        return timeTable;
    };

    const handleParticipantsToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = eventActivity["attendees"].findIndex(item => item.id === _attendee_id);

        let updatedList = [...eventActivity["attendees"]];

        if (index !== -1) {
            // Remove attendee from activity list
            updatedList.splice(index, 1);
        } else {

            // Find the attendee in the reference array
            const newAttendee = attendeesReferenceArray.find(att => att.id === _attendee_id);

            if (!newAttendee) {
                console.error(`Attendee with ID ${_attendee_id} not found.`);
                return;
            }

            // Add to global context if not already present
            const globalAttendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;
            if (!globalAttendees.some(att => att.id === _attendee_id)) {
                set_PA_GlobalObject(prev => ({
                    Planning: {
                        ...prev.Planning,
                        attendees: [...prev.Planning.attendees, newAttendee],
                    },
                }));
            }

            // Add the attendee to the meal attendee list
            const attendeeWithCost = {
                id: _attendee_id,
                cost: 0,
                is_business_expense: false,
                business_expense_amount_authorized: 0,
                business_expense_amount_authorized_by: "",
                business_expense_amount_authorized_timestamp: 0,
                business_expense_amount: 0,
                business_expense_paid: 0,
                business_expense_reference_id: "",
            };
            updatedList = [...eventActivity.attendees, attendeeWithCost];
        }

        if (eventActivity["assign_equal"]) {

            const activityCost = parseFloat(eventActivity["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

        }

        setEventActivity(prevState => ({
            ...prevState,
            attendees: updatedList
        }));

    };
    useEffect(() => {
        console.log('Attendees have been updated:', eventActivity.attendees);
        props.updateActivityDetails(eventActivity);
    }, [eventActivity.attendees]);

    function handleCostOverride(event) {
        const valueToAdd = parseFloat(event.target.value);

        setEventActivity(prevState => ({
            ...prevState,
            amount_total: valueToAdd
        }));

    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventActivity["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventActivity["attendees"]];
                let activityCost = parseFloat(eventActivity["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("participants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

                props.updateActivityDetails(eventActivity);

            }
        }

        setShowPriceOverride(!showPriceOverride);
    }

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventActivity(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        //recalculate to see if that changes the Attendees total breakdown
        setCheckShowPriceChanges(true);


        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventActivity["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventActivity(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventActivity["attendees"]];

            const activityCost = parseFloat(eventActivity["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventActivity(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventActivity(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);
    }

    const [editModeMap, setEditModeMap] = useState({});
    const handleBusinessExpenseToggle = (attendeeId, isChecked) => {
        setEventActivity((prevActivity) => ({
            ...prevActivity,
            attendees: prevActivity.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: isChecked,
                        business_expense_amount: isChecked ? att.cost : 0, // Sync with cost if toggled on
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseAmountEdit = (attendeeId) => {
        setEditModeMap((prev) => ({
            ...prev,
            [attendeeId]: !prev[attendeeId], // Toggle edit mode
        }));
    };
    const handleBusinessExpenseAmountChange = (attendeeId, value) => {
        // Use a regex to allow only valid decimal numbers
        const validInput = value.match(/^\d*\.?\d{0,2}$/); // Allows up to two decimal places
        if (!validInput) return;

        setEventActivity((prevActivity) => ({
            ...prevActivity,
            attendees: prevActivity.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        business_expense_amount: value, // Keep raw string for input control
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseEnableOnEdit = (attendeeId) => {
        setEventActivity((prevActivity) => ({
            ...prevActivity,
            attendees: prevActivity.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: true, // Automatically toggle checkbox
                    }
                    : att
            ),
        }));
    };


    function handleSaveActivityCostsAssignment(event){

        props.updateActivityDetails(eventActivity);

        setShowPriceChangesUpdateButton(false);

    }

    function handleBookedThroughChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            booked_through: _newValue
        }));
    }
    function handleConfirmationNumberChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            confirmation_number: _newValue
        }));
    }
    function handleReservationNameChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            reservation_name: _newValue
        }));
    }
    function handleReservationNumberChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            reservation_number: _newValue
        }));
    }
    function handleNotesChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            notes: _newValue
        }));
    }

    const handleFooterItemClick = (_item) => {
        setSelectedAction(_item);
        activityTabSelected === _item ? setActivityTabSelected("") : setActivityTabSelected(_item);
    };

    const [openDialogDateRange, setOpenDialogDateRange] = useState(false);
    function handleDialogDateRangeClose() {
        setOpenDialogDateRange(false);
    }
    function handleDialogDateRangeOK(){
        setOpenDialogDateRange(false);
    }


    const calculateElapsedTime = () => {
        console.log("beginDate : "+ beginDate);
        console.log("beginTime : "+ beginTime);
        console.log("endDate : "+ endDate);
        console.log("endTime : "+ endTime);


        // Default endDate to startDate if not provided
        const resolvedEndDate = endDate || beginDate;

        // Use 'YYYY-MM-DD' format for dates and 'h:mm A' for times
        const start = dayjs(`${beginDate} ${beginTime}`, 'YYYY-MM-DD h:mm A');
        const end = dayjs(`${resolvedEndDate} ${endTime || beginTime}`, 'YYYY-MM-DD h:mm A');

        console.log("start : "+ start);
        console.log("end : "+ end);

        // Check if the start time is valid
        if (!start.isValid() || !end.isValid()) {
            return 'Invalid time format';
        }

        const totalDays = end.diff(start, 'day'); // Get total number of days between start and end
        const elapsed = dayjs.duration(end.diff(start));

        const hours = elapsed.hours();
        const minutes = elapsed.minutes();

        const elapsedTimeComponents = [];
        if (totalDays > 0) elapsedTimeComponents.push(`${totalDays} d`);
        if (hours > 0) elapsedTimeComponents.push(`${hours} h`);
        if (minutes > 0) elapsedTimeComponents.push(`${minutes} m`);

        return elapsedTimeComponents.join(' '); // If no time difference, return '0 m'
    };

    const calculateElapsedTimeOLD = (startDate, startTime, endDate, endTime) => {
        // Use 'YYYY-MM-DD' format for dates and 'h:mm A' for times
        const start = dayjs(`${startDate} ${startTime}`, 'YYYY-MM-DD h:mm A');
        const end = dayjs(`${endDate} ${endTime}`, 'YYYY-MM-DD h:mm A');

        // Check if the start and end times are valid
        if (!start.isValid() || !end.isValid()) {
            return 'Invalid time format';
        }

        const totalDays = end.diff(start, 'day');  // Get total number of days between start and end
        const elapsed = dayjs.duration(end.diff(start));

        const hours = elapsed.hours();
        const minutes = elapsed.minutes();

        const elapsedTimeComponents = [];
        if (totalDays > 0) elapsedTimeComponents.push(`${totalDays} d`);
        if (hours > 0) elapsedTimeComponents.push(`${hours} h`);
        if (minutes > 0) elapsedTimeComponents.push(`${minutes} m`);

        return elapsedTimeComponents.join(' ') || '0 m';  // If no time difference, return '0m'
    };

    const [anchorActivityListItemMenu, setAnchorActivityListItemMenu] = useState(null);
    const handleActivityListItemMenuOpenMenu = (event) => {
        setAnchorActivityListItemMenu(event.currentTarget);
    };
    const handleActivityListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorActivityListItemMenu(event.currentTarget);

    };
    const handleActivityListItemMenuClose = () => {
        setAnchorActivityListItemMenu(null);
    };
    
    
    return (


        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >

                {/* add any photos if they exist */}
                {
                    eventActivity['photos'] && eventActivity['photos'].length >= 0 ? (
                        <div className="activities-suggestions-add-list-item-photos">
                            {
                                eventActivity['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }

                <CardHeader
                    title={
                        (() => {
                            const { time_table } = eventActivity;

                            if (!time_table || !time_table.dates || time_table.dates.length === 0) {
                                return ''; // Return an empty string if no time_table or dates are available
                            }

                            // Extract the first and last dates from the time_table
                            const firstDate = time_table.dates[0];
                            const lastDate = time_table.dates[time_table.dates.length - 1];

                            // Format the start and end dates
                            const startDate = dayjs(firstDate.date, "YYYY-MM-DD").format('dddd'); // Full day name
                            const shortStartDate = dayjs(firstDate.date, "YYYY-MM-DD").format('ddd'); // Short day name
                            const endDate = firstDate.date !== lastDate.date
                                ? dayjs(lastDate.date, "YYYY-MM-DD").format('ddd') // Short day name for the end date
                                : shortStartDate; // Use short start date if both dates are the same

                            // Include times if available
                            const timeString = (() => {
                                const beginTime = firstDate['begin_time'] || null;
                                const endTime = lastDate['end_time'] || null;

                                if (beginTime && endTime) {
                                    return ` (${dayjs(beginTime, "h:mm A").format('h:mm A')} - ${dayjs(endTime, "h:mm A").format('h:mm A')})`;
                                } else if (beginTime) {
                                    return ` (${dayjs(beginTime, "h:mm A").format('h:mm A')})`;
                                } else if (endTime) {
                                    return ` (${dayjs(endTime, "h:mm A").format('h:mm A')})`;
                                }

                                return ''; // No times available
                            })();

                            // Build the title
                            return firstDate.date !== lastDate.date
                                ? `${startDate} - ${endDate}${timeString}` // Multi-day event
                                : `${shortStartDate}${timeString}`; // Single-day event
                        })()
                    }

                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleActivityListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{paddingBottom:'0'}}
                    titleTypographyProps={{ fontSize: '18px' }}
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorActivityListItemMenu}
                    id="activity-item-menu"
                    open={Boolean(anchorActivityListItemMenu)}
                    onClose={handleActivityListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="delete"
                            onClick={() => handleFooterItemClick('Delete')}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '0 16px 16px 16px' }}>
                    {/* Left-aligned: Dates */}
                    <Typography variant="body2" color="textSecondary">
                        {(() => {
                            const { time_table } = eventActivity;

                            if (time_table?.dates?.length > 0) {
                                // Extract the first and last date from time_table.dates
                                const beginDate = dayjs(time_table.dates[0].date, "YYYY-MM-DD");
                                const endDate = dayjs(time_table.dates[time_table.dates.length - 1].date, "YYYY-MM-DD");

                                // Format the dates, e.g., "Aug 3 - Aug 6"
                                const formattedBeginDate = beginDate.format('MMM D');
                                const formattedEndDate = endDate.isSame(beginDate, 'day') ? '' : ` - ${endDate.format('MMM D')}`;

                                return `${formattedBeginDate}${formattedEndDate}`;

                            } else if (time_table?.days?.length > 0) {
                                // If dates don't exist, show days from time_table.days
                                const startDay = Object.keys(time_table.days[0])[0];
                                const endDay = time_table.days.length > 1
                                    ? Object.keys(time_table.days[time_table.days.length - 1])[0]
                                    : startDay;

                                return startDay === endDay ? startDay : `${startDay} - ${endDay}`;
                            }

                            return ''; // If no valid dates or days
                        })()}
                    </Typography>

                    {/* Right-aligned: Activity total time */}
                    <Typography variant="body2" color="primary" sx={{ textAlign: 'right' }}>
                        {(() => {
                            const { time_table } = eventActivity;

                            if (time_table?.dates?.length > 0) {
                                const firstEntry = time_table.dates[0];
                                const lastEntry = time_table.dates[time_table.dates.length - 1];

                                // Handle 'all-day' case
                                if (firstEntry['all-day']) {
                                    return 'All Day';
                                }

                                // Safely parse dates and times
                                const beginDate = dayjs(firstEntry.date, "YYYY-MM-DD");
                                const endDate = dayjs(lastEntry.date, "YYYY-MM-DD");
                                const beginTime = firstEntry['begin_time'] ? dayjs(firstEntry['begin_time'], "h:mm A") : null;
                                const endTime = lastEntry['end_time'] ? dayjs(lastEntry['end_time'], "h:mm A") : null;

                                // Validate times
                                if (beginTime && !beginTime.isValid()) {
                                    console.error("Invalid begin time format:", firstEntry['begin_time']);
                                    return "Invalid Begin Time";
                                }

                                if (endTime && !endTime.isValid()) {
                                    console.error("Invalid end time format:", lastEntry['end_time']);
                                    return "Invalid End Time";
                                }

                                // Use calculateElapsedTime or directly format output
                                if (beginDate.isValid() && endDate.isValid()) {
                                    return calculateElapsedTime();
                                } else {
                                    return 'Invalid Date';
                                }
                            } else if (time_table?.days?.length > 0) {
                                const firstDayEntry = time_table.days[0];

                                if (firstDayEntry['all-day']) {
                                    return 'All Day';
                                }

                                const totalDays = time_table.days.length;
                                return totalDays === 1 ? '1 Day' : `${totalDays} Days`;
                            }

                            return ''; // Fallback for no valid dates or days
                        })()}
                    </Typography>

                </Box>

                {/* show the Activity timeline */}
                <CardContent sx={{padding:0}}>

                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            margin: 0
                        }}
                    >
                        <TimelineItem sx={{marginTop:0}}>
                            <TimelineSeparator>
                                {
                                    eventActivity.time_table?.dates?.[0]?.begin_time === "" ? (
                                        <TimelineDot color="primary" />
                                    ) : (
                                        eventActivity.time_table?.dates?.[0]?.date !== "" && !eventActivity.time_table?.dates?.[0]?.['all-day']
                                        || eventActivity.time_table?.days?.length > 0  && !eventActivity.time_table?.days?.[0]?.['all-day'] ? (
                                            <TimelineDot
                                                sx={{
                                                    border: '1px solid #cbe0f7',
                                                    borderRadius: '5px',
                                                    padding: 0,
                                                    bgcolor: 'transparent',
                                                    color: '#151269'
                                                }}
                                            >
                                                <CustomTimelineDot
                                                    beginTime={
                                                        eventActivity.time_table?.dates?.[0]?.begin_time || // Check dates first
                                                        eventActivity.time_table?.days?.[0]?.begin_time || // Fallback to days
                                                        null // If none, return null (which will result in an empty dot)
                                                    }
                                                />

                                            </TimelineDot>
                                        ) : (
                                            <TimelineDot color="primary" />
                                        )
                                    )
                                }
                                {
                                    eventActivity.time_table?.dates?.[eventActivity['time_table'].dates.length - 1]?.end_time || // Check dates first
                                    eventActivity.time_table?.days?.[eventActivity['time_table'].days.length - 1]?.['end_time'] ? (
                                        <TimelineConnector sx={{ bgcolor: '#cbe0f7' }}/>
                                    ) : null
                                }

                            </TimelineSeparator>

                            <TimelineContent sx={{py: '12px', px: 2}}>
                                <Typography sx={{ mb: 1.5}} variant="body2">
                                    {eventActivity['title']}
                                </Typography>
                                <Typography sx={{ fontSize:'12px' }} color="text.secondary">
                                    {eventActivity['location'].name}
                                </Typography>
                                <Typography sx={{ fontSize:'12px' }} color="text.secondary">
                                    {eventActivity['location'].address}
                                </Typography>
                                <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                    {eventActivity['location'].city} {eventActivity['location'].state} {eventActivity['location'].zip}
                                </Typography>
                            </TimelineContent>

                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                {
                                    eventActivity.time_table?.dates?.length === 0 && eventActivity.time_table?.days?.length === 0 ? (
                                        <></>
                                    ) : (
                                        eventActivity.time_table?.dates?.[0]?.date !== "" && !eventActivity.time_table?.dates?.[0]?.['all-day']
                                        || eventActivity.time_table?.days?.length > 0  && !eventActivity.time_table?.days?.[0]?.['all-day'] ? (
                                            <TimelineDot
                                                sx={{
                                                    border: '1px solid #cbe0f7',
                                                    borderRadius: '5px',
                                                    padding: 0,
                                                    bgcolor: 'transparent',
                                                    color: '#151269'
                                                }}
                                            >
                                                <CustomTimelineDot
                                                    beginTime={
                                                        eventActivity.time_table?.dates?.[eventActivity['time_table'].dates.length - 1]?.end_time || // Check dates first
                                                        eventActivity.time_table?.days?.[eventActivity['time_table'].days.length - 1]?.['end_time'] || // Fallback to days
                                                        null // If none, return null (which will result in an empty dot)
                                                    }
                                                />
                                            </TimelineDot>
                                        ) : (
                                            <TimelineDot color="primary" />
                                        )
                                    )
                                }
                            </TimelineSeparator>
                            <TimelineContent sx={{py: '12px', px: 2}}>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                </CardContent>

                <CardActions>
                    <div className="activities-suggestions-add-list-item-footer-container">
                        {/* add the activity date */}
                        <div
                            style={{backgroundColor: selectedAction === "Dates" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-dates-container"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <i className="icon-calendar3" title="Dates"/>
                        </div>
                        {/* add the activity location */}
                        <div
                            style={{backgroundColor: selectedAction === "Location" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-location-container"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <i className="icon-map-marker" title="Location"/>
                        </div>
                        {/* add the activity participants */}
                        <div
                            style={{backgroundColor: selectedAction === "Participants" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-occupants-container"
                            onClick={() => handleFooterItemClick('Participants')}
                        >
                            <i className="icon-user-24" title="Participants"/>
                        </div>
                        {/* add the activity costs */}
                        <div
                            style={{backgroundColor: selectedAction === "Costs" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-costs-container"
                            onClick={()=>handleFooterItemClick('Costs')}
                        >
                            <i className="icon-wallet"  title="Costs"/>
                        </div>
                        {/* add the activity details */}
                        <div
                            style={{backgroundColor: selectedAction === "Details" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-details-container"
                            onClick={()=>handleFooterItemClick('Details')}
                        >
                            <Badge
                                badgeContent={0}
                                color="badge"
                            >
                                <i className="icon-settings-icons"  title="Details"/>
                            </Badge>
                        </div>
                    </div>
                </CardActions>

                <CardContent sx={{padding:'20px 0', display:activityTabSelected?'block':'none'}}>
                    {
                        selectedAction === "Dates" ? (
                            <Box className="activities-suggestions-add-list-item-footer-dates-holder">

                                <Grid item sx={{maxWidth: '100%'}} >
                                    <TabContext sx={{ position:'relative'}} value={tabValue}>
                                        <TabList
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                                backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                                backdropFilter: 'blur(6px)',
                                            }}
                                            centered
                                            variant="fullWidth"
                                            onChange={handleTabChange}
                                            aria-label="Meal Date / Day"
                                        >
                                            <Tab
                                                label="Date"
                                                value="date"
                                            />
                                            <Tab
                                                label="Day"
                                                value="day"
                                            />
                                        </TabList>
                                        <TabPanel value="date" sx={{padding: '24px 0'}}>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkin"
                                            >
                                                Begin Date :&nbsp;
                                                {
                                                    eventActivity['time_table'].dates[0]?.date
                                                        ? dayjs(eventActivity['time_table'].dates[0].date, 'YYYY-MM-DD').isValid()
                                                            ? dayjs(eventActivity['time_table'].dates[0].date, 'YYYY-MM-DD').format('D MMM, YYYY')
                                                            : '' // If invalid date
                                                        : '' // If no date exists
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityBeginDateCalendar}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkin-input"
                                                style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}>
                                                <DatePicker
                                                    sx={{width: '100%'}}
                                                    label="Begin Date"
                                                    value={dayjs(eventActivity['time_table'].dates[0]?.date, 'YYYY-MM-DD')}
                                                    onChange={handleBeginDateChange}
                                                />
                                            </div>

                                            {/* for the begin time */}
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-begin"
                                            >
                                                Begin Time :&nbsp;
                                                {
                                                    eventActivity['time_table'].dates[0]?.['begin_time'] || ''
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectBeginTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityBeginTime}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-begin-input"
                                                style={{display: showClockSelectBeginTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="Begin Time"
                                                    value={dayjs(eventActivity['begin_time'], 'h:mm A')}
                                                    onChange={handleBeginTimeChange}
                                                />
                                            </div>


                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkout"
                                            >
                                                End Date :&nbsp;
                                                {
                                                    eventActivity['time_table'].dates[eventActivity['time_table'].dates.length - 1]?.date
                                                        ? dayjs(eventActivity['time_table'].dates[eventActivity['time_table'].dates.length - 1].date, 'YYYY-MM-DD').isValid()
                                                            ? dayjs(eventActivity['time_table'].dates[eventActivity['time_table'].dates.length - 1].date, 'YYYY-MM-DD').format('D MMM, YYYY')
                                                            : '' // If invalid date
                                                        : '' // If no date exists
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityEndDateCalendar}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-dates-checkout-input"
                                                style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                                <DatePicker
                                                    sx={{width: '100%'}}
                                                    label="End Date"
                                                    value={dayjs(eventActivity['end_date'], 'MM/DD/YYYY')}
                                                    minDate={dayjs(eventActivity['begin_date'], 'MM/DD/YYYY')}
                                                    onChange={handleEndDateChange}
                                                />
                                            </div>

                                            {/* for the end time */}
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-end"
                                            >
                                                End Time :&nbsp;
                                                {
                                                    eventActivity['time_table'].dates[0]?.['end_time'] || ''
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectEndTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityEndTime}
                                                />
                                            </div>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-times-end-input"
                                                style={{display: showClockSelectEndTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="End Time"
                                                    value={dayjs(eventActivity['time_table'].dates[0]?.['end_time'], 'h:mm A') || ''}
                                                    onChange={handleEndTimeChange}
                                                />
                                            </div>

                                        </TabPanel>

                                        <TabPanel value="day" sx={{padding: '24px 0'}}>
                                            <div
                                                className="activities-suggestions-add-list-item-footer-date"
                                            >
                                                Begin Day :&nbsp;
                                                {
                                                    (() => {
                                                        const {time_table} = eventActivity;

                                                        // If no dates, check for time_table.days
                                                        if (time_table?.days?.length > 0) {
                                                            return time_table.days[0][Object.keys(time_table.days[0])[0]]; // Display day (e.g., 'Day 1')
                                                        }

                                                        // If neither, display nothing
                                                        return '';
                                                    })()
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showSelectActivityBeginDay ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowActivityBeginDaySelect}
                                                />
                                            </div>

                                            <div
                                                className="activities-suggestions-add-list-item-footer-date-input"
                                                style={{display: showSelectActivityBeginDay ? 'block' : 'none'}}
                                            >

                                                {
                                                    renderActivityBeginDayLayout()
                                                }

                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                            </Box>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="activities-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{marginTop: '0'}}>
                                    <ActivityLocationTab
                                        eventActivity={eventActivity}
                                    />
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }

                    {
                        selectedAction === "Participants" && (
                            <List dense sx={{ width: '100%' }}>
                                {selectedAttendeesArray
                                    .sort((a, b) =>
                                        `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                            `${formatName(b.lname)}, ${formatName(b.fname)}`
                                        )
                                    )
                                    .map((_attendee, index) => (
                                        <div key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleParticipantsToggle(_attendee.id)}
                                                        checked={eventActivity['attendees'].some(_att => _att.id === _attendee.id)}
                                                        inputProps={{ 'aria-labelledby': index }}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                            sx={{
                                                                boxShadow: '' +
                                                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                borderRadius: '10px',
                                                                textAlign: 'center',
                                                                fontWeight: '700',
                                                                backgroundColor: '#FFF',
                                                                color: 'black',
                                                                fontSize: '15px',
                                                                width: '30px',
                                                                height: '30px',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={index}
                                                        primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    ))
                                }

                                {/* Optional autocomplete for adding attendees */}
                                <Box mt={6} mb={1}>
                                    <Divider />
                                </Box>

                                {/* Autocomplete for adding attendees */}
                                <Autocomplete
                                    multiple
                                    options={attendeesReferenceArray.filter(
                                        (_attendee) => !selectedAttendeesArray.some((selected) => selected.id === _attendee.id)
                                    )}
                                    getOptionLabel={(option) => `${formatName(option.lname)}, ${formatName(option.fname)}`}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    {...stringAvatar(`${formatName(option.fname)}, ${formatName(option.lname)}`)}
                                                    sx={{
                                                        boxShadow: '' +
                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                        border: `1px solid ${stringToColor(`${formatName(option.fname)}, ${formatName(option.lname)}`)}`,
                                                        borderRadius: '10px',
                                                        textAlign: 'center',
                                                        fontWeight: '700',
                                                        backgroundColor: '#FFF',
                                                        color: 'black',
                                                        fontSize: '15px',
                                                        width: '30px',
                                                        height: '30px',
                                                    }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={`${formatName(option.lname)}, ${formatName(option.fname)}`} />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Add Attendees"
                                            placeholder="Search Attendees"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        newValue.forEach((newAttendee) => {
                                            handleParticipantsToggle(newAttendee.id)();
                                        });
                                    }}
                                />
                            </List>
                        )
                    }

                    {
                        selectedAction === "Costs" ? (
                            <div className="activities-suggestions-add-list-item-footer-costs-holder">
                                {/* Cost ActivitySummary */}
                                <Typography
                                    variant="body2"
                                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    Cost Summary
                                    {/* show the receipt icon if there is a receipt linked to this Meal */}
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['receipts'].some(_receipt => _receipt.section_item === eventActivity.id) && (
                                            <i className='icon-receipt' />
                                        )
                                    }
                                </Typography>
                                <div
                                    className="activities-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="activities-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventActivity['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventActivity['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventActivity['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="activities-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            type="number"
                                            label="Activity Total"
                                            value={eventActivity["amount_total"]}
                                            onChange={handleCostOverride}
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_activity is the Assignment area */}
                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.length === 0 ? (
                                            <div
                                                className="activities-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="activities-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_activity is the toggle section */}
                                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventActivity['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventActivity['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_activity is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventActivity['attendees']
                                                            .sort((a, b) => {
                                                                const _a = Attendees.find((_att)=>_att.id === a.id);
                                                                const _b = Attendees.find((_att)=>_att.id === b.id);
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => {
                                                                const attendee = Attendees.find((_att) => _att.id === _attendee.id);
                                                                const businessExpenseAmount = _attendee.business_expense_amount || _attendee.cost;

                                                                return (
                                                                    <div
                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                        key={index}
                                                                    >
                                                                        <ListItem
                                                                            className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            secondaryAction={
                                                                                eventActivity['assign_equal'] ? (
                                                                                    <span
                                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost">
                                                                                        {_attendee.cost}&nbsp;{eventActivity['currency']}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                        <TextField
                                                                                            label={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                                                                            value={_attendee.cost}
                                                                                            onChange={(e) => handleCustomOccupantCost(_attendee.id, e)}
                                                                                            variant="standard"
                                                                                        />
                                                                                    </span>
                                                                                )
                                                                            }
                                                                            disablePadding
                                                                        >
                                                                            <ListItemAvatar>
                                                                                {attendee && (
                                                                                    <Avatar
                                                                                        {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                                                        sx={{
                                                                                            boxShadow: '' +
                                                                                                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                                                '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                                            border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                                                            borderRadius: '10px',
                                                                                            textAlign: 'center',
                                                                                            fontWeight: '700',
                                                                                            backgroundColor: '#FFF',
                                                                                            color: 'black',
                                                                                            fontSize: '15px',
                                                                                            width: '30px',
                                                                                            height: '30px'
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </ListItemAvatar>

                                                                            <ListItemText
                                                                                sx={{display: eventActivity['assign_equal'] ? 'block' : 'none'}}
                                                                                id={index}
                                                                                primary={attendee ? `${formatName(attendee.lname)}, ${formatName(attendee.fname)}` : ''}
                                                                            />
                                                                        </ListItem>


                                                                        {/* Business Expense Section */}
                                                                        <div
                                                                            className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Checkbox
                                                                                    checked={_attendee.is_business_expense || false}
                                                                                    onChange={(e) => handleBusinessExpenseToggle(_attendee.id, e.target.checked)}
                                                                                />
                                                                                <span style={{ marginLeft: '8px' }}>Business Expense</span>
                                                                            </div>

                                                                            {editModeMap[_attendee.id] ? (
                                                                                <TextField
                                                                                    label="Amount"
                                                                                    value={_attendee.business_expense_amount || 0.00}
                                                                                    onChange={(e) => handleBusinessExpenseAmountChange(_attendee.id, e.target.value)}
                                                                                    variant="standard"
                                                                                    onFocus={() => handleBusinessExpenseEnableOnEdit(_attendee.id)}
                                                                                />
                                                                            ) : (
                                                                                <span
                                                                                    className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost"
                                                                                >
                                                                                    {parseFloat(_attendee.business_expense_amount || 0).toFixed(2)}&nbsp;{eventActivity['currency']}
                                                                                </span>
                                                                            )}

                                                                            <Chip
                                                                                sx={editChipStyle}
                                                                                label={editModeMap[_attendee.id] ? 'Done' : 'Edit'}
                                                                                size="small"
                                                                                onClick={() => handleBusinessExpenseAmountEdit(_attendee.id)}
                                                                            />
                                                                        </div>


                                                                        {/* Divider */}
                                                                        <Divider/>

                                                                    </div>

                                                                );
                                                            })
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span className="activities-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventActivity['amount_total']) - eventActivity['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventActivity['currency']
                                                        }
                                                    </span>

                                                </div>

                                                {/* show the update button if changes were made */}

                                                {
                                                    showPriceChangesUpdateButton ? (
                                                        <div style={{cursor: 'pointer'}} onClick={handleSaveActivityCostsAssignment}>
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label='Save Changes'
                                                                size="small"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_booked_through"
                                                label="Booked Through"
                                                value={eventActivity['booked_through']}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_confirmation_number"
                                                label="Confirmation Number"
                                                value={eventActivity['confirmation_number']}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_name"
                                                label="Reservation Name"
                                                value={eventActivity['reservation_name']}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_number"
                                                label="Reservation Number"
                                                value={eventActivity['reservation_number']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_website"
                                                label="Website"
                                                value={eventActivity['website']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_bring"
                                                label="Bring"
                                                multiline
                                                rows={4}
                                                value={eventActivity['bring']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_details"
                                                label="Details"
                                                multiline
                                                rows={4}
                                                value={eventActivity['details']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={eventActivity['notes']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                </CardContent>

            </Card>

            <Dialog
                open={openDialogDateRange}
                onClose={handleDialogDateRangeClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The date for the Activity is outside of the date range for the Event.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDateRangeOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ActivitiesCard;
