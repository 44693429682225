import React, {useEffect, useState} from 'react';

import {Card, CardContent, CardMedia, Chip, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";

import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";

import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";
import './activities.css';
import ActivitiesCard from "./ActivitiesCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import Box from "@mui/material/Box";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import InfoAttendees from "../../Dialogs/InfoAttendees";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";



function ActivitiesList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const [eventActivities, setEventActivities] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);

    const [viewType, setViewType] = useState('all'); // Either 'dates' or 'days'
    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [selectedDate, setSelectedDate] = useState(null); // For selected date
    const [selectedDay, setSelectedDay] = useState(null); // For selected day

    const [anchorActivitiesListMenu, setAnchorActivitiesListMenu] = useState(null);
    const handleActivitiesListMenuOpenMenu = (event) => {
        setAnchorActivitiesListMenu(event.currentTarget);
    };
    const handleActivitiesListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorActivitiesListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Activities/Add');
        }
    };
    const handleActivitiesListMenuClose = () => {
        setAnchorActivitiesListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    useEffect(() => {
        console.log("Planning.activities : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities);

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities !== undefined) {
            setEventActivities([...usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities]);
        }

    }, [PA_GlobalObject.Planning.activities]);
    useEffect(() => {
        console.log("eventActivities triggered again: ", eventActivities);

        if (viewType === 'all') {
            handleActivityChipAllClick();
        }
    }, [eventActivities]);

    function handleActivityChipAllClick(){
        console.log("handleActivityChipAllClick");

        if (viewType !== "all"){
            setViewType("all");
        }

        let filtered = [...eventActivities];

        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date
                || (Array.isArray(a.time_table.days) && a.time_table.days[0]
                    ? dayjs().day(Object.keys(a.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
            const dateB = b.time_table.dates?.[0]?.date
                || (Array.isArray(b.time_table.days) && b.time_table.days[0]
                    ? dayjs().day(Object.keys(b.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
//            const dateA = a.time_table.dates?.[0]?.date || dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
//            const dateB = b.time_table.dates?.[0]?.date || dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        setFilteredActivities(filtered);
    }
    function handleActivityChipDateClick(_activity_date){
        console.log("handleActivityChipDateClick : "+_activity_date);

        if (viewType !== "dates"){
            setViewType("dates");
        }
        setSelectedDate(_activity_date);

        let filtered = [];
        filtered = eventActivities.filter(activity =>
            activity.time_table?.dates?.length > 0 && activity.time_table.dates[0].date === _activity_date
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date;
            const dateB = b.time_table.dates?.[0]?.date;

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        console.log("filtered : ", filtered);

        setFilteredActivities(filtered);
    }
    function handleActivityChipDayClick(_activity_day){
        console.log("handleActivityChipDayClick : "+_activity_day);

        if (viewType !== "days"){
            setViewType("days");
        }
        setSelectedDay(_activity_day);

        let filtered = [];
        filtered = eventActivities.filter(activity =>
            activity.time_table?.days?.length > 0 && Object.keys(activity.time_table.days[0])[0] === _activity_day
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
            const dateB = dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });
        console.log("filtered : "+JSON.stringify(filtered, null, '\t'));

        setFilteredActivities(filtered);
    }

    const updateActivityDetails = (updatedActivity) => {
        // Find the index of the updated Activity in eventActivities
        const activityIndex = eventActivities.findIndex(
            (activity) => activity.id === updatedActivity.id || activity.temp_id === updatedActivity.temp_id
        );

        console.log("trying to update Activity index : "+activityIndex);

        if (activityIndex !== -1) {
            // If the activity is found, update it in the eventActivities array
            const updatedEventActivitiesArray = [...eventActivities];
            updatedEventActivitiesArray[activityIndex] = updatedActivity;

            //this updates the meal at the Global level
            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    activities: updatedEventActivitiesArray, // Update only the title
                },
            }));

            console.log("updatedEventActivities");
        }

    };

    const [isAttendeeInfoDialogOpen, setAttendeeInfoDialogOpen] = useState(false);




    return (

        <div className="activities-list-container" >

            {/* show the header */}
            <div
                className="activities-list-header"
            >
                <div className="activities-list-header-text">
                    <h5>Activities</h5>
                </div>

                {
                    eventActivities.length === 0 ? (
                        <div className="activities-list-header-info-icon-holder">
                            <i
                                className="icon-info-circle"
                                style={{fontSize: '20px', padding: '5px'}}
                                onClick={() => setAttendeeInfoDialogOpen(!isAttendeeInfoDialogOpen)}
                            />
                        </div>
                    ) : (
                        <></>
                    )
                }

                <div className="meals-list-header-controls">
                    {/* Toggle for Dates/Days */}
                    {
                        (eventHasDates && eventHasDays) && (
                            <Box sx={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
                                {/* Icon Button for Dates */}
                                <IconButton
                                    type="button"
                                    sx={{
                                        padding: '3px',
                                        backgroundColor: viewType === 'dates' ? '#FFF' : '',
                                        boxShadow: '' +
                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                        border: '1px solid #0096ff',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        color: 'black', // Text color (adjust as needed)
                                        '&:hover': {
                                            background: '#cbe0f7', // Reverse the gradient on hover
                                        },
                                    }}
                                    aria-label="toggle to dates"
                                    onClick={() => setViewType('dates')}
                                >
                                    <Typography variant="caption">Dates</Typography>
                                </IconButton>

                                {/* Divider between buttons */}
                                <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>

                                {/* Icon Button for Days */}
                                <IconButton
                                    type="button"
                                    sx={{
                                        padding: '3px',
                                        backgroundColor: viewType === 'days' ? '#FFF' : '',
                                        boxShadow: '' +
                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                        border: '1px solid #0096ff',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        color: 'black', // Text color (adjust as needed)
                                        '&:hover': {
                                            background: '#cbe0f7', // Reverse the gradient on hover
                                        },
                                    }}
                                    aria-label="toggle to days"
                                    onClick={() => setViewType('days')}
                                >
                                    <Typography variant="caption">Days</Typography>
                                </IconButton>
                            </Box>
                        )
                    }

                    {/* Menu Chip */}
                    <Chip
                        sx={menuChipStyle}
                        label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                        size="small"
                        onClick={handleActivitiesListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorActivitiesListMenu}
                        id="library-menu"
                        open={Boolean(anchorActivitiesListMenu)}
                        onClose={handleActivitiesListMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                            <MenuItem data-id="new-entry" onClick={handleActivitiesListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handlePromptChangeClose}>
                                <ListItemText>Close</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the Event dates/days */}
            <div className="activities-list-timetable-list-container">
                {/* "All" Chip to show all meals */}
                {
                    eventHasDates || eventHasDays ? (
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                marginTop: '5px',
                                marginRight: '10px',
                            }}
                        >
                            <Chip
                                label="All"
                                sx={viewType === 'all' ? selectedChipStyle : unselectedChipStyle}
                                style={{ margin: '0.2rem', height: '40px' }}
                                onClick={handleActivityChipAllClick}

                            />
                        </Box>
                    ) : (
                        <></>
                    )
                }


                {
                    (
                        viewType === 'all' || viewType === 'dates') && eventHasDates ? (
                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'].dates.map((eventDateObj) => {
                            const eventDate = eventDateObj.date;
                            const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd');
                            const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D');

                            // Filter activities for this specific date
                            const activitiesForDate = eventActivities.filter(activity =>
                                activity.time_table?.dates?.length > 0 && activity.time_table.dates[0].date === eventDate
                            );
                            const activityCount = activitiesForDate.length;

                            return (
                                <Box
                                    key={`meal-${eventDate}`}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Chip
                                        label={
                                            <div style={{textAlign: 'center'}}>
                                                <Typography variant="body2">{formattedDay}</Typography>
                                                <Typography variant="caption">{formattedDate}</Typography>
                                            </div>
                                        }
                                        sx={viewType === 'dates' && selectedDate === eventDate ? selectedChipStyle : unselectedChipStyle}
                                        style={{margin: '0.2rem', height: '40px'}}
                                        onClick={() => handleActivityChipDateClick(eventDate)}
                                    />
                                    {/* Badge or warning icon */}
                                    {activityCount > 0 ? (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                backgroundColor: 'white',
                                                color: 'green',
                                                borderRadius: '35%',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                border: '1px solid',
                                                fontWeight: '700',
                                            }}
                                        >
                                {activityCount}
                            </span>
                                    ) : (
                                        <i
                                            className="icon-warning"
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                fontSize: '10px',
                                                color: 'red',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })
                    ) : (viewType === 'all' || viewType === 'days') && eventHasDays ? (
                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'].days.map((dayObj) => {
                            const dayKey = Object.keys(dayObj)[0];
                            const dayName = dayObj[dayKey];

                            // Filter activities for this specific day
                            const activitiesForDay = eventActivities.filter(activity =>
                                activity.time_table?.days?.length > 0 && activity.time_table.days[0][dayKey] === dayName
                            );
                            const activityCount = activitiesForDay.length;

                            return (
                                <Box
                                    key={`meal-${dayKey}`}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Chip
                                        label={
                                            <div style={{textAlign: 'center'}}>
                                                <Typography variant="body2">{dayName.slice(0, 3)}</Typography>
                                                <Typography
                                                    variant="caption">{capitalizeFirstLetter(dayKey)}</Typography>
                                            </div>
                                        }
                                        sx={viewType === 'days' && selectedDay === dayKey ? selectedChipStyle : unselectedChipStyle}
                                        style={{margin: '0.2rem', height: '40px'}}
                                        onClick={() => handleActivityChipDayClick(dayKey)}
                                    />
                                    {/* Badge or warning icon */}
                                    {activityCount > 0 ? (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                backgroundColor: 'white',
                                                color: 'green',
                                                borderRadius: '35%',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                border: '1px solid',
                                                fontWeight: '700',
                                            }}
                                        >
                                    {activityCount}
                                </span>
                                    ) : (
                                        <i
                                            className="icon-warning"
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                fontSize: '10px',
                                                color: 'red',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })
                    ) : (
                        <></>
                    )
                }
            </div>



            {/* show the list of activities */}
            <div className="activities-list-items-container">

                {
                    filteredActivities.length > 0 ? (
                        filteredActivities
                            .sort((a, b) => {
                                // First, compare by date
                                const dateComparison = dayjs(a.begin_date).diff(dayjs(b.begin_date));
                                if (dateComparison !== 0) {
                                    return dateComparison;
                                }
                                // If dates are the same, compare by time
                                return dayjs(a.begin_time, 'h:mm A').diff(dayjs(b.begin_time, 'h:mm A'));
                            })
                            .map((activityDetails, index) => (

                                <Grid item key={index} sx={{
                                    display:'block',
                                    marginBottom:'10px'
                                }}>

                                    <ActivitiesCard
                                        activity={activityDetails}
                                        updateActivityDetails={updateActivityDetails}
                                        _global_map_ref={props._global_map_ref}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item >
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 140 }}
                                        image={lodgingHeaderImage}
                                        title="Add Activity"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Activity
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Activities:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels, Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and let you see what we've
                                            found. You can edit the results before we actually add the details to the Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click on the
                                            <i className="icon-menu2" /> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

            <InfoAttendees
                isOpen={isAttendeeInfoDialogOpen}
                onClose={() => setAttendeeInfoDialogOpen(false)}
            />

        </div>
    )

}

export default ActivitiesList;
