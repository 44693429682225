import React, {useEffect, useState} from 'react';

import {Chip} from "@mui/material";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Timeline,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";



function ItineraryView() {

    /*
        shows all that's going on for each day of the Event
        so cycle through each day
     */

    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);

    const [selectedTimetable, setSelectedTimetable] = useState(eventHasDates || !eventHasDays ? "dates" : "days");
    const handlePromptTimetableClick = (timetable) => {
        setSelectedTimetable(timetable);
    }

    const [selectedSection, setSelectedSection] = useState("Intro");
    const handleSectionClick = (sectionName, type) => {
        setSelectedSection(sectionName);
//        props.setItineraryViewSection(sectionName);
//        props.setItineraryViewType(type);
//        props.setShowEventItemConfig(true);
//        props.setShowEventItemExtendedConfig(true);
//        navigate('/Planner/Itinerary/Section');
    }

    const [sortedItems, setSortedItems] = useState([]);
    useEffect(() => {
        console.log("change the view to : "+selectedSection);
        console.log("selectedTimetable : "+selectedTimetable);
        /*
            this is either going to look like 2024-09-25 for dates or day 2 for days
         */

        if (!usePA_GlobalStore.getState().PA_GlobalObject.Planning
            || !usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities
            || !usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals
            || !usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging) {
            return;
        }

        // Filter activities based on view type
        const filteredActivities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities.filter(activity => {
            if (selectedTimetable === 'dates') {
                // Ensure time_table.dates exists and is not an empty array
                if (activity.time_table?.dates?.length > 0) {
                    return activity.time_table.dates[0]['date'] === selectedSection
                        || activity.time_table.dates[activity.time_table.dates.length - 1]['date'] === selectedSection;
                }
                return false;
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (activity.time_table?.days?.length > 0) {
                    return Object.keys(activity.time_table.days[0]).includes(selectedSection)
                        || Object.keys(activity.time_table.days[activity.time_table.days.length - 1]).includes(selectedSection);
                }
                return false;
            }
        });

        // Filter meals based on view type
        const filteredMeals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals.filter(meal => {
            if (selectedTimetable === 'dates') {
                // Ensure time_table.dates exists and is not an empty array
                if (meal.time_table?.dates?.length > 0) {
                    return meal.time_table.dates[0]['date'] === selectedSection;
                }
                return false;
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (meal.time_table?.days?.length > 0) {
                    return Object.keys(meal.time_table.days[0]).includes(selectedSection);
                }
                return false;
            }
        });


        // Filter lodgings based on view type
        const filteredLodgings = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging.filter(lodging => {
            console.log(" lodging time_table : " , lodging);

            if (selectedTimetable === 'dates') {
                // Ensure time_table.dates exists and is not an empty array
                if (lodging.time_table?.dates?.length > 0) {
                    return lodging.time_table.dates[0]['date'] === selectedSection
                        || lodging.time_table.dates[lodging.time_table.dates.length - 1]['date'] === selectedSection;
                }
                return false;
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (lodging.time_table?.days?.length > 0) {
                    return Object.keys(lodging.time_table.days[0]).includes(selectedSection)
                        || Object.keys(lodging.time_table.days[lodging.time_table.days.length - 1]).includes(selectedSection);
                }
                return false;
            }
        });


        // Filter flights based on view type
        const filteredFlights = usePA_GlobalStore.getState().PA_GlobalObject.Planning.flights.filter(flight => {
            if (selectedTimetable === 'dates') {
                // Check if the depart_timestamp matches the itineraryViewSection date
                return dayjs(flight.depart_timestamp).isSame(dayjs(selectedSection, 'YYYY-MM-DD'), 'day');
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (flight.time_table?.days?.length > 0) {
                    return Object.keys(flight.time_table.days[0]).includes(selectedSection)
                        || Object.keys(flight.time_table.days[flight.time_table.days.length - 1]).includes(selectedSection);
                }
                return false;
            }
        });


        // Combine and sort items
        const combinedItems = [
            ...filteredActivities.map(activity => ({ ...activity, type: 'activity' })),
            ...filteredMeals.map(meal => ({ ...meal, type: 'meal' })),
            ...filteredLodgings.map(lodging => ({
                ...lodging,
                type: 'lodging',
                time: dayjs(selectedSection, 'YYYY-MM-DD').isSame(dayjs(lodging.begin_date, 'MM/DD/YYYY'), 'day') ? lodging.check_in : lodging.check_out
            })),
            ...filteredFlights.map(flight => ({ ...flight, type: 'flight' })),
        ];

        const sortedItems = combinedItems.sort((a, b) => {
            const getDateTime = (item) => {

                switch (item.type) {
                    case "activity" :
                        return dayjs(`${item['time_table'].dates[0]?.date} ${item['time_table'].dates[0]?.begin_time}`, 'YYYY-MM-DD hh:mm A');
                    case "meal" :
                        return dayjs(`${item.date} ${item.time}`, 'MM/DD/YYYY hh:mm A');
                    case "lodging" :
                        return dayjs(`${item.begin_date} ${item.time}`, 'MM/DD/YYYY hh:mm A');
                    case "flight" :
                        return dayjs(item.depart_timestamp);
                    default :
                        return dayjs();
                }

            };

            const aTime = getDateTime(a);
            const bTime = getDateTime(b);

            return aTime.isBefore(bTime) ? -1 : 1;
        });

        console.log("sortedItems : ", sortedItems);

        setSortedItems(sortedItems);

    }, [selectedSection]);


    function formatTime(time) {
        if (!time) return null;

        // Handle Day.js objects or string times
        const formattedTime = dayjs(time, ['h:mm A', 'HH:mm']).format('h:mm A');

        // Check for Noon or Midnight
        if (formattedTime === '12:00 PM') return 'Noon';
        if (formattedTime === '12:00 AM') return 'Midnight';

        return formattedTime;
    }



    return (

        <div className="itinerary-list-container">
            {/* show the header */}
            <div
                className="itinerary-list-header"
            >
                <div className="itinerary-list-header-text">
                    <h5>Itinerary</h5>
                </div>
                <div className="itinerary-section-header-chip-holder">
                    <Badge
                        badgeContent={
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                            },
                        }}
                    >
                        <Chip
                            sx={selectedTimetable === "dates" ? selectedChipStyle : unselectedChipStyle}
                            label="Dates"
                            size="small"
                            onClick={() => handlePromptTimetableClick('dates')}
                        />
                    </Badge>
                    <Badge
                        badgeContent={
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                            },
                        }}
                    >
                        <Chip
                            sx={{
                                ...(selectedTimetable === "days" ? selectedChipStyle : unselectedChipStyle),
                                marginLeft: '10px'
                            }}
                            label="Days"
                            size="small"
                            onClick={() => handlePromptTimetableClick('days')}
                        />
                    </Badge>

                </div>
            </div>

            <div className="itinerary-list-items-container">
                <Box sx={{display: 'flex', flexDirection: 'row', overflowX: 'auto', padding: '0.5rem'}}>
                    {/* Show the Intro button */}
                    <Chip
                        sx={selectedSection === "Intro" ? selectedChipStyle : unselectedChipStyle}
                        label="Intro"
                        clickable
                        onClick={() => handleSectionClick("Intro")}
                        style={{margin: '0.5rem', height: '50px'}}
                    />

                    {/* Show a button for each day/date of the Event */}
                    {
                        selectedTimetable === "dates" ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0 ? (
                                usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates
                                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                                    .map((date, index) => (
                                        <Chip
                                            key={index}
                                            sx={selectedSection === date.date ? selectedChipStyle : unselectedChipStyle}
                                            label={
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography
                                                        variant="body2">{dayjs(date.date).format('ddd')}</Typography> {/* Day abbreviation */}
                                                    <Typography
                                                        variant="caption">{dayjs(date.date).format('M/D')}</Typography> {/* Date */}
                                                </div>
                                            }
                                            style={{
                                                margin: '0.5rem',
                                                height: '50px',
                                                width: '70px'
                                            }} // Adjust Chip size as needed
                                            onClick={() => handleSectionClick(`${date.date}`, 'date')}
                                        />
                                    ))
                            ) : (
                                <></>
                            )
                        ) : (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0 ? (
                                usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.map((day, index) => {
                                    const dayName = Object.values(day)[0]; // e.g., 'Wednesday'
                                    const dayAbbreviation = dayName.slice(0, 3); // Shorten to 'Wed'

                                    return (
                                        <Chip
                                            key={index}
                                            sx={selectedSection === Object.keys(day)[0] ? selectedChipStyle : unselectedChipStyle}
                                            label={
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography
                                                        variant="body2">{dayAbbreviation}</Typography> {/* Shortened day name */}
                                                    <Typography variant="caption">
                                                        {capitalizeFirstLetter(Object.keys(day)[0])} {/* Capitalize 'day' */}
                                                    </Typography> {/* Display day number */}
                                                </div>
                                            }
                                            style={{
                                                margin: '0.5rem',
                                                height: '50px',
                                                width: '70px'
                                            }} // Adjust Chip size as needed
                                            onClick={() => handleSectionClick(`${Object.keys(day)[0]}`, 'days')}
                                        />
                                    );
                                })
                            ) : (
                                <></>
                            )
                        )
                    }
                </Box>
            </div>

            <div className="itinerary-section-list-items-container">
                {sortedItems.length === 0 ? (
                    <Typography variant="subtitle2" align="center" color="textSecondary">
                        The day is free to do whatever you enjoy!
                    </Typography>
                ) : (
                    <Timeline>
                        {sortedItems.map((item, index) => {
                            const isActivity = item.type === 'activity';
                            const isMeal = item.type === 'meal';
                            const isLodging = item.type === 'lodging';
                            const isFlight = item.type === 'flight';
                            const isLastItem = index === sortedItems.length - 1;

                            return (
                                <TimelineItem key={item.id}>
                                    <TimelineOppositeContent>
                                        {
                                            isLodging ? (
                                            <>
                                                {
                                                    selectedTimetable === "dates" ? (
                                                        dayjs(selectedSection, 'YYYY-MM-DD').isSame(item.time_table?.dates?.[0]?.date, 'day') ? (
                                                            <>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    Check In:
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {item.time_table?.dates?.[0]?.checkin_time}
                                                                </Typography>
                                                            </>
                                                        ) : dayjs(selectedSection, 'YYYY-MM-DD').isSame(item.time_table?.dates?.[item.time_table.dates.length - 1]?.date, 'day') ? (
                                                            <>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    Check Out:
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {item.time_table?.dates?.[item.time_table.dates.length - 1]?.checkout_time}
                                                                </Typography>
                                                            </>
                                                        ) : null
                                                    ) : (
                                                        selectedSection === Object.keys(item.time_table?.days?.[0])[0] ? (
                                                            <>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    Check In:
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {item.check_in}
                                                                </Typography>
                                                            </>
                                                        ) : selectedSection === Object.keys(item.time_table?.days?.[item.time_table.days.length - 1])[0] ? (
                                                            <>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    Check Out:
                                                                </Typography>
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {item.check_out}
                                                                </Typography>
                                                            </>
                                                        ) : null
                                                    )
                                                }
                                            </>
                                        ) : isActivity ? (
                                            <>
                                                {
                                                    selectedTimetable === "dates" ? (
                                                        <>
                                                            {item['time_table'].dates[0].begin_time && (
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {formatTime(item['time_table'].dates[0].begin_time)}
                                                                </Typography>
                                                            )}
                                                            {item['time_table'].dates[item['time_table'].dates.length - 1].end_time && (
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {formatTime(item['time_table'].dates[item['time_table'].dates.length - 1].end_time)}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item['time_table'].days[0].begin_time && (
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {formatTime(item['time_table'].days[0].begin_time)}
                                                                </Typography>
                                                            )}
                                                            {item['time_table'].days[item['time_table'].days.length - 1].end_time && (
                                                                <Typography variant="caption" color="textSecondary" component="div">
                                                                    {formatTime(item['time_table'].days[item['time_table'].days.length - 1].end_time)}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </>
                                        ) : isFlight ? (
                                            // Flight logic
                                            <>
                                                {item.depart_timestamp && (
                                                    <Typography variant="caption" color="textSecondary" component="div">
                                                        {`Depart: ${formatTime(dayjs(item.depart_timestamp).format('h:mm A'))}`}
                                                    </Typography>
                                                )}
                                                {item.arrive_timestamp && (
                                                    <Typography variant="caption" color="textSecondary" component="div">
                                                        {`Arrive: ${formatTime(dayjs(item.arrive_timestamp).format('h:mm A'))}`}
                                                    </Typography>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    selectedTimetable === "dates" ? (
                                                        // Default logic for single time
                                                        <Typography variant="caption" color="textSecondary" component="div">
                                                            {formatTime(item.time_table?.dates?.[0]?.begin_time || item.time)}
                                                        </Typography>
                                                    ) : (
                                                        // Default logic for single time
                                                        <Typography variant="caption" color="textSecondary" component="div">
                                                            {formatTime(item.time_table?.days?.[0]?.begin_time || item.time)}
                                                        </Typography>
                                                    )
                                                }
                                            </>

                                        )}
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        {isActivity ? (
                                            <TimelineDot color="success">
                                                <i className="icon-list-heart"
                                                   style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : isMeal ? (
                                            <TimelineDot color="info">
                                                <i className="icon-fork-knife2"
                                                   style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : isLodging ? (
                                            <TimelineDot color="warning">
                                                <i className="icon-bed"
                                                   style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : isFlight ? (
                                            <TimelineDot color="warning">
                                                <i className="icon-airplane"
                                                   style={{fontSize: '1rem'}}/> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : null}
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Typography variant="subtitle2">
                                            {isActivity ? item.title : isMeal ? item.location.name !== "" ? item.location.name : item.name : isLodging ? item.location.name : item.depart_airport_city}
                                        </Typography>
                                        <Typography variant="caption">
                                            {
                                                isActivity ?
                                                    item.location.address
                                                    :
                                                    isMeal ?
                                                        item.location.address !== "" ? item.location.address : item.eat_in_details
                                                        :
                                                        isLodging ?
                                                            item.location.name : item.depart_airport_city
                                            }
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                )}
            </div>


        </div>

    )
}

export default ItineraryView;
