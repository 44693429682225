import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import usePA_GlobalStore from "../../Services/ZustandGlobalStore";

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import small_logo from '../../graphics/Color-logo-image-only.png';
import large_logo from '../../graphics/Color_logo_no_background.png';
import {
    Alert, Avatar,
    Button, Card, CardContent, CardMedia, Checkbox, Chip,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    FormControl, FormHelperText,
    InputAdornment,
    InputLabel, LinearProgress,
    ListItemButton, ListSubheader, Menu, MenuItem, MenuList,
    OutlinedInput, Tooltip
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {PAContext} from "../../Services/PAContext";
import {GoogleLogin} from "@react-oauth/google";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import './header.css';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {getAllEventsFromDatabase, getEventFromDatabase} from "../../Services/BrowserDatabaseFunctions";
import dayjs from "dayjs";
import SampleLibraryEvent1 from "../../sampleData/SampleLibraryEvent1";
import {capitalizeFirstLetter, formatName, stringAvatar} from "../../components/Helpers/TextStyling";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import GettingStarted from "../../components/Dialogs/GettingStarted";
import MasterAttendees from "../../components/Dialogs/MasterAttendees";
import Badge from "@mui/material/Badge";






const Header = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray, setSideMenuWidth, setLoginFailed } = useContext(PAContext);
    const { Account, Attendees } = dataArray;

    const [progressBarIndicator, setProgressBarIndicator] = useState(0);
    useEffect(() => {
        console.log("dataArray.progressBar : "+dataArray.progressBar);
        setProgressBarIndicator(dataArray.progressBar);
    }, [dataArray.progressBar]);

    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    useEffect(() => {

        const path = location.pathname.replaceAll('/', '');

        if (path === "") {
            setSelectedMenuItem('home');
        } else if (path === "Features") {
            setSelectedMenuItem('features');
        } else if (path === "Pricing") {
            setSelectedMenuItem('pricing');
        } else if (path === "ContactUs") {
            setSelectedMenuItem('contact');
        } else if (path.startsWith("FlightPlanner")) {
            setSelectedMenuItem('flightplanner');
        } else if (path.startsWith("Planner")) {
            setSelectedMenuItem('planner');
        } else if (path === "Dashboard") {
            setSelectedMenuItem('dashboard');
        } else if (path === "SignUp") {
            setSelectedMenuItem('signup');
        }

        console.log("getting Events from browser database for : "+Account.id);
        //load and sort any Events from the browser database
        if (Account.id !== undefined && Account.id !== ""){
            getAllEventsFromDatabase(Account.id).then((_events) => {
                console.log("back from the database : ",_events);

                const sorted = [..._events]
                    .filter(event => event.time_table?.dates && event.time_table.dates.length > 0) // Check that dates is defined and has elements
                    .sort((a, b) => {
                        const dateA = a.time_table.dates && a.time_table.dates[0] ? dayjs(a.time_table.dates[0].date) : dayjs(0); // Default to an early date if undefined
                        const dateB = b.time_table.dates && b.time_table.dates[0] ? dayjs(b.time_table.dates[0].date) : dayjs(0);
                        return dateB.isAfter(dateA) ? 1 : -1; // Descending order
                    });

                console.log("sorted Events length : "+sorted.length);
                setSortedEvents(sorted);

                // Process unique locations grouped by state
                const locationsMap = {};

                _events.forEach((event) => {
                    event.lodging.forEach((lodging) => {
                        const { city, state } = lodging.location || {};
                        if (city && state) {
                            // Normalize to lowercase for comparison and uniqueness
                            const normalizedCity = city.trim().toLowerCase();
                            const normalizedState = state.trim().toLowerCase();

                            // Initialize the state in the map if it doesn't exist
                            if (!locationsMap[normalizedState]) {
                                locationsMap[normalizedState] = new Set();
                            }

                            // Add city to the state's set (Set automatically handles uniqueness)
                            locationsMap[normalizedState].add(normalizedCity);
                        }
                    });
                });

                // Convert each state's set of cities back to an array and capitalize first letters
                const locationsObj = {};
                for (const [state, citiesSet] of Object.entries(locationsMap)) {
                    const formattedState = state.charAt(0).toUpperCase() + state.slice(1); // Capitalize state
                    locationsObj[formattedState] = Array.from(citiesSet).map(city =>
                        city.charAt(0).toUpperCase() + city.slice(1)  // Capitalize city
                    );
                }

                // Set unique locations as an object with states and their unique cities
                setUniqueLocations(locationsObj);


                //build the list of unique categories for this Account id
                const categoriesSet = new Set();

                _events.forEach((event) => {
                    event.menu_items.forEach((menuItem) => {
                        if (menuItem.id === Account.id) {
                            menuItem.categories.forEach((category) => {
                                categoriesSet.add(category);
                            });
                        }
                    });
                });

                // Set the unique categories as an array
                setUniqueCategories(Array.from(categoriesSet));

            });
        } else {
            console.log("not logged in so no checking for Events!!");
        }

    }, []);

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const onLogoClick = () => {
        setSelectedMenuItem('');
        navigate('/');
        setSideMenuWidth(0);
    }
    const onButtonClick = (event) => {
        console.log('button click ', event.target.innerText);
        setDrawerOpen(false);

        if (event.target.innerText === "home"){
            setSelectedMenuItem('');
            navigate('/');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Features"){
            setSelectedMenuItem('features');
            navigate('/Features');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Pricing"){
            setSelectedMenuItem('pricing');
            navigate('/Pricing');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Contact Us"){
            setSelectedMenuItem('contact');
            navigate('/ContactUs');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "FlightPlanner"){
            setSelectedMenuItem('flightplanner');
            navigate('/FlightPlanner');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Events") {
            // Open the dialog when "Events" is clicked
            setIsEventDialogOpen(true);
        }
        if (event.target.innerText === "Contacts") {
            // Open the dialog when "Contacts" is clicked
            setIsAttendeesDialogOpen(true);
        }
        if (event.target.innerText === "Planner"){
            setSelectedMenuItem('planner');
            navigate('/Planner');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Admins"){
            setSelectedMenuItem('admins');
            navigate('/Admins');
            setSideMenuWidth(0);
        }
        if (event.target.innerText === "Login"){
            setIsModalOpen(true);
        }
        if (event.target.innerText === "Logout"){
            //logout
            const _sendString = {};
            _sendString.action = "E_processUserLogout";
            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
        }
        if (event.target.innerText === "Dashboard"){
            setSelectedMenuItem('dashboard');
            props.setViewingEventGroup("current");
            navigate('/dashboard');
            dataArray.authenticated ? setSideMenuWidth(70) : setSideMenuWidth(0);
        }
        if (event.target.innerText === "Sign Up"){
            setSelectedMenuItem('signup');
            navigate('/SignUp');
            setSideMenuWidth(0);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [usernameErrorStatus, setUsernameErrorStatus] = useState(false);
    const [passwordErrorStatus, setPasswordErrorStatus] = useState(false);


    const handleUsernameInputChange = (event) => {
        const inputValue = event.target.value;
        setUsername(inputValue);
        if (inputValue.length > 0){
            setUsernameErrorStatus(false);
            setLoginFailed(false);
        }
    }
    const handlePasswordInputChange = (event) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
        if (inputValue.length > 0){
            setPasswordErrorStatus(false);
            setLoginFailed(false);
        }
    }

    const handleOk = () => {
        console.log("handleOk");

        //check input values
        if (username !== "" && password !== ""){

            console.log("dataArray.ws_connected : "+dataArray.ws_connected);

            if (dataArray.ws_connected){

                const _sendString = {};
                _sendString.action = "E_processUserLogin";
                _sendString.username = username;
                _sendString.password = encode64(password);

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            } else {
                //websocket not available

            }

        } else {
            //something wrong with username or password
            username === "" ? setUsernameErrorStatus(true):setUsernameErrorStatus(false)
            password === "" ? setPasswordErrorStatus(true):setPasswordErrorStatus(false)
        }

    };
    const handleCancel = () => {
        console.log("handleCancel");
        setIsModalOpen(false);

    };

    useEffect(() => {
        setIsModalOpen(false);

        if (Account.id !== undefined && Account.id !== "") {
            getAllEventsFromDatabase(Account.id).then((_events) => {
                console.log("back from the database : ", _events);

                if (_events){
                    const sorted = [..._events]
                        .filter(event => event.time_table?.dates && event.time_table.dates.length > 0) // Check that dates is defined and has elements
                        .sort((a, b) => {
                            const dateA = a.time_table.dates && a.time_table.dates[0] ? dayjs(a.time_table.dates[0].date) : dayjs(0); // Default to an early date if undefined
                            const dateB = b.time_table.dates && b.time_table.dates[0] ? dayjs(b.time_table.dates[0].date) : dayjs(0);
                            return dateB.isAfter(dateA) ? 1 : -1; // Descending order
                        });

                    console.log("sorted Events length : " + sorted.length);
                    setSortedEvents(sorted);

                    // Process unique locations grouped by state
                    const locationsMap = {};

                    _events.forEach((event) => {
                        event.lodging.forEach((lodging) => {
                            const { city, state } = lodging.location || {};
                            if (city && state) {
                                // Normalize to lowercase for comparison and uniqueness
                                const normalizedCity = city.trim().toLowerCase();
                                const normalizedState = state.trim().toLowerCase();

                                // Initialize the state in the map if it doesn't exist
                                if (!locationsMap[normalizedState]) {
                                    locationsMap[normalizedState] = new Set();
                                }

                                // Add city to the state's set (Set automatically handles uniqueness)
                                locationsMap[normalizedState].add(normalizedCity);
                            }
                        });
                    });

                    console.log("locationsMap : ", locationsMap);

                    // Convert each state's set of cities back to an array and capitalize first letters
                    const locationsObj = {};
                    for (const [state, citiesSet] of Object.entries(locationsMap)) {
                        const formattedState = state.charAt(0).toUpperCase() + state.slice(1); // Capitalize state
                        locationsObj[formattedState] = Array.from(citiesSet).map(city =>
                            city.charAt(0).toUpperCase() + city.slice(1)  // Capitalize city
                        );
                    }

                    console.log("locationsObj : ", locationsObj);
                    // Set unique locations as an object with states and their unique cities
                    setUniqueLocations(locationsObj);


                    //build the list of unique categories for this Account id
                    const categoriesSet = new Set();

                    _events.forEach((event) => {
                        event.menu_items.forEach((menuItem) => {
                            if (menuItem.id === Account.id) {
                                menuItem.categories.forEach((category) => {
                                    categoriesSet.add(category);
                                });
                            }
                        });
                    });

                    console.log("categoriesSet : ", categoriesSet);
                    // Set the unique categories as an array
                    setUniqueCategories(Array.from(categoriesSet));

                }

            });
        }

    }, [dataArray.authenticated]);


    const keyStr = "ABCDEFGHIJKLMNOP" +
        "QRSTUVWXYZabcdef" +
        "ghijklmnopqrstuv" +
        "wxyz0123456789+/" +
        "=";

    function encode64(input) {
        input = escape(input);
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;

        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                keyStr.charAt(enc1) +
                keyStr.charAt(enc2) +
                keyStr.charAt(enc3) +
                keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);

        return output;
    }


    const [anchorTemplateMenu, setAnchorTemplateMenu] = useState(null);
    const handleTemplateMenuOpenMenu = (event) => {
        setAnchorTemplateMenu(event.currentTarget);
    };
    const handleTemplateMenuClick = (event) => {
        console.log("handleTemplateMenuClick");
        setAnchorTemplateMenu(null);

        //load the Event
        const _libraryEvent = SampleLibraryEvent1();
        set_PA_GlobalObject({ Planning: _libraryEvent });

        //navigate to the Planning page if not there already
        setSelectedMenuItem('planner');
        navigate('/Planner');
        setSideMenuWidth(0);

    };
    const handleTemplateMenuClose = () => {
        setAnchorTemplateMenu(null);
    };

    const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
    const closeEventDialog = () => {
        setIsEventDialogOpen(false);
    };
    const handleEventMenuClick = (event_id) => {
        console.log("handleEventMenuClick");
        console.log("event_id : "+event_id);

        //set the Planner
        getEventFromDatabase(event_id).then(specificEvent => {
            console.log("specificEvent : "+specificEvent);

            if (specificEvent !== null){

                console.log("the new Event : ", specificEvent);

                //set the Event
                set_PA_GlobalObject({ Planning: specificEvent });

                /*
                    //see if there are any NEW Event messages
                 */
                const _send_string = {};
                _send_string.action = "E_getEventMessages";
                _send_string.event_id = specificEvent.id;

                if (specificEvent.messages.length === 0){
                    _send_string.latest_message_timestamp = 0;
                } else {
                    _send_string.latest_message_timestamp = specificEvent.messages.reduce((latest, message) => {
                        return message.created > latest ? message.created : latest;
                    }, 0);
                }

                dataArray.Websocket.send(JSON.stringify(_send_string) + "|^");


                /*
                    let AI know of the Event switch?
                 */

                //navigate to the Planning page if not there already
                setSelectedMenuItem('planner');
                navigate('/Planner');
                setSideMenuWidth(0);

                setIsEventDialogOpen(false);

            } else {
                //no Event found
                console.log("specificEvent NOT FOUND");

            }
        });

    }

    const [sortedEvents, setSortedEvents] = useState([]);
    const [sortOrderEvents, setSortOrderEvents] = useState('asc');
    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [uniqueCategories, setUniqueCategories] = useState([]);

    const [anchorElCategory, setAnchorElCategory] = useState(null);
    const [anchorElFilter, setAnchorElFilter] = useState(null);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedEventAttendees, setSelectedEventAttendees] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);


    useEffect(() => {

        if (Account.id !== undefined && Account.id !== "") {
            if (selectedLocations.length === 0 && selectedEventAttendees.length === 0){

                getAllEventsFromDatabase(Account.id).then((_events) => {

                    const sorted = [..._events].filter(event => event.time_table.dates && event.time_table.dates.length > 0)
                        .sort((a, b) => {
                            const dateA = dayjs(a.time_table.dates[0].date);
                            const dateB = dayjs(b.time_table.dates[0].date);
                            return dateB.isAfter(dateA) ? 1 : -1; // Descending order
                        });

                    setSortedEvents(sorted);
                });


            } else {
                // Find events matching selected locations
                // Normalize the selected locations to lowercase and trim any spaces
                const normalizedSelectedLocations = selectedLocations.map(location => location.toLowerCase().trim());

                // Filter events based on selected locations
                let locationFilteredEvents = [];
                if (selectedLocations.length > 0) {

                    getAllEventsFromDatabase(Account.id).then((_events) => {

                        locationFilteredEvents = _events.filter(event => {
                            return selectedLocations.length === 0 || event.lodging.some(lodging => {
                                const {city, state} = lodging.location || {};
                                // Compare each city and state separately
                                const normalizedCity = city?.toLowerCase().trim();
                                const normalizedState = state?.toLowerCase().trim();
                                const combinedLocation = `${normalizedCity}, ${normalizedState}`;
                                console.log("comparing : " + normalizedSelectedLocations + " : " + combinedLocation);
                                console.log("888888888888888 result : " + normalizedSelectedLocations.includes(combinedLocation));
                                return normalizedSelectedLocations.includes(combinedLocation);
                            });
                        });

                        console.log("locationFilteredEvents : " + locationFilteredEvents.length);

                        // If there are no selected attendees, don't return all events—return an empty list
                        let attendeeFilteredEvents = [];
                        if (selectedEventAttendees.length > 0) {
                            attendeeFilteredEvents = _events.filter(event =>
                                event.attendees.some(attendee => selectedEventAttendees.includes(attendee.id))
                            );
                        }

                        // Combine both location and attendee filtered events without duplicates
                        const combinedEvents = [...new Set([...locationFilteredEvents, ...attendeeFilteredEvents])];

                        console.log("combinedEvents : " + JSON.stringify(combinedEvents));

                        // Sort the combined events by date (descending order)
                        const sorted = combinedEvents.sort((a, b) => {
                            const dateA = dayjs(a.time_table.dates[0].date);
                            const dateB = dayjs(b.time_table.dates[0].date);
                            return dateB.isAfter(dateA) ? 1 : -1;
                        });

                        console.log("sorted : " + JSON.stringify(sorted));

                        // Set the filtered and sorted events
                        setSortedEvents(sorted);

                    });

                }

            }
        }


    }, [selectedLocations, selectedEventAttendees]);

    useEffect(() => {

        if (Account.id !== undefined && Account.id !== "") {
            getAllEventsFromDatabase(Account.id).then((_events) => {

                if (selectedCategories.length === 0) {
                    // No category selected, show all events
                    const sorted = _events.sort((a, b) => {
                        const dateA = dayjs(a.time_table.dates[0].date);
                        const dateB = dayjs(b.time_table.dates[0].date);
                        return dateB.isAfter(dateA) ? 1 : -1;
                    });
                    setSortedEvents(sorted);
                    return;
                }

                // Filter the events based on selected categories and Account.id
                const categoryFilteredEvents = _events.filter(event => {
                    // Check if the event has any menu_items related to this Account.id
                    return event.menu_items.some(menuItem => {
                        // Check if the Account.id matches and if the menu item has any categories that match the selected categories
                        return menuItem.id === Account.id && menuItem.categories.some(category => selectedCategories.includes(category));
                    });
                });

                // Update the sortedEvents with the filtered results
                setSortedEvents(categoryFilteredEvents);
            });
        }


    }, [selectedCategories]);

    const handleSortToggle = () => {
        setIsUploadMode(false);

        const sorted = [...sortedEvents].filter(event => event.time_table.dates && event.time_table.dates.length > 0) // Only events with valid dates
            .sort((a, b) => {
                const dateA = dayjs(a.time_table.dates[0].date);
                const dateB = dayjs(b.time_table.dates[0].date);

                if (sortOrderEvents === 'asc') {
                    return dateA.isAfter(dateB) ? 1 : -1;
                } else {
                    return dateA.isBefore(dateB) ? 1 : -1;
                }
            });

        // Toggle sort order and update sorted events
        setSortedEvents(sorted);
        setSortOrderEvents((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };


    // Open the filter menu
    const handleFilterMenuOpen = (event) => {
        setAnchorElFilter(event.currentTarget);
        setIsUploadMode(false);
    };

    // Close the filter menu
    const handleFilterMenuClose = () => {
        setAnchorElFilter(null);
        setIsUploadMode(false);
    };

    // Toggle selected locations
    const handleLocationToggle = (location) => {

        if (selectedCategories.length > 0){
            setSelectedCategories([]);
        }

        if (selectedLocations.includes(location)) {
            setSelectedLocations(selectedLocations.filter(l => l !== location));
        } else {
            setSelectedLocations([...selectedLocations, location]);
        }
    };

    // Toggle selected attendees
    const handleEventAttendeeToggle = (attendeeId) => {

        if (selectedCategories.length > 0){
            setSelectedCategories([]);
        }

        if (selectedEventAttendees.includes(attendeeId)) {
            setSelectedEventAttendees(selectedEventAttendees.filter(id => id !== attendeeId));
        } else {
            setSelectedEventAttendees([...selectedEventAttendees, attendeeId]);
        }
    };


    // Handle category menu open
    const handleCategoryMenuOpen = (event) => {
        console.log("****************** : "+event.currentTarget);
        setAnchorElCategory(event.currentTarget); // Set the anchor element
    };

    // Handle category menu close
    const handleCategoryMenuClose = () => {
        setAnchorElCategory(null); // Close the menu
    };

    // Handle category selection
    const handleCategoryToggle = (category) => {

        if (selectedLocations.length  > 0 || selectedEventAttendees.length > 0){
            setSelectedLocations([]);
            setSelectedEventAttendees([]);
        }
        setSelectedCategories((prevCategories) => {
            if (prevCategories.includes(category)) {
                // Remove the category if already selected
                return prevCategories.filter((cat) => cat !== category);
            } else {
                // Add the category if not already selected
                return [...prevCategories, category];
            }
        });

    };


    const handleReset = () => {
        setSelectedLocations([]);
        setSelectedEventAttendees([]);
        setSelectedCategories([]);
    }


    const dialogRefAttendees = useRef(null);
    const [isAttendeesDialogOpen, setIsAttendeesDialogOpen] = useState(false);
    const closeAttendeesDialog = () => {
        setIsAttendeesDialogOpen(false);
    };
    const handleAttendeeMenuClick = (attendee_id) => {
        console.log("handleAttendeeMenuClick");
        console.log("attendee_id : "+attendee_id);

    }
    const [sortedAttendees, setSortedAttendees] = useState(Attendees);
    const [sortOrderAttendees, setSortOrderAttendees] = useState('asc');
    const [selectedAttendees, setSelectedAttendees] = useState(Attendees);
    const [searchAttendeesFname, setSearchAttendeesFname] = useState('');
    const [searchAttendeesLname, setSearchAttendeesLname] = useState('');

    useEffect(() => {
        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = (a.lname || '').toLowerCase();
            const lnameB = (b.lname || '').toLowerCase();

            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        });
        setSortedAttendees(sortedAttendees);
    }, [Attendees]);

    const handleSearchFnameChange = (event) => {
        setIsUploadMode(false);
        console.log("handleSearchFnameChange : "+event.target.value);
        setSearchAttendeesFname(event.target.value); // Update search term
    };
    useEffect(() => {
        console.log("searchAttendeesFname changed");

        const normalizedSearchTerm = searchAttendeesFname.toLowerCase();

        const filteredAttendees = Attendees.filter((att) => {
            return att.fname.toLowerCase().startsWith(normalizedSearchTerm);
        });

        setSortedAttendees(filteredAttendees);

    }, [searchAttendeesFname]);
    const handleSearchLnameChange = (event) => {
        setIsUploadMode(false);
        console.log("handleSearchLnameChange : "+event.target.value);
        setSearchAttendeesLname(event.target.value); // Update search term
    };
    useEffect(() => {
        console.log("searchAttendeesLname changed");

        const normalizedSearchTerm = searchAttendeesLname.toLowerCase();

        const filteredAttendees = Attendees.filter((att) => {
            return att.lname.toLowerCase().startsWith(normalizedSearchTerm);
        });

        setSortedAttendees(filteredAttendees);

    }, [searchAttendeesLname]);

    const handleAttendeeSort = () => {
        setIsUploadMode(false);

        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = a.lname.toLowerCase();
            const lnameB = b.lname.toLowerCase();
            const fnameA = a.fname.toLowerCase();
            const fnameB = b.fname.toLowerCase();

            if (sortOrderAttendees === 'asc') {
                if (lnameA < lnameB) return -1;
                if (lnameA > lnameB) return 1;
                // If last names are equal, compare first names
                if (fnameA < fnameB) return -1;
                if (fnameA > fnameB) return 1;
                return 0;
            } else {
                if (lnameA > lnameB) return -1;
                if (lnameA < lnameB) return 1;
                // If last names are equal, compare first names
                if (fnameA > fnameB) return -1;
                if (fnameA < fnameB) return 1;
                return 0;
            }
        });

        setSortedAttendees(sortedAttendees);
        setSortOrderAttendees((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };
    const handleAttendeeReset = () => {
        setIsUploadMode(false);
        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = a.lname.toLowerCase();
            const lnameB = b.lname.toLowerCase();

            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        });
        setSortedAttendees(sortedAttendees);
    }


    const [isUploadMode, setIsUploadMode] = useState(false);
    const fileInputRef = useRef(null);
    const [originalFileData, setOriginalFileData] = useState([]);
    const [fileDataWithIds, setFileDataWithIds] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedEntries, setSelectedEntries] = useState([]); // To store the selected entries from file
    const [duplicateEmails, setDuplicateEmails] = useState([]);

    const handleUploadClick = () => {
        if (!isUploadMode){
            //they are toggling ON; clear everything
            handleAttendeeFileReset();
        }

        setIsUploadMode(!isUploadMode);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setSelectedFile(file);

        const reader = new FileReader();
        const fileType = file.name.split('.').pop().toLowerCase();

        if (fileType === 'csv') {
            // Handle CSV Parsing
            reader.onload = (e) => {
                const text = e.target.result;
                const parsedData = Papa.parse(text, { header: true });

                /*
                    if there isn't a column labeled as id then we need to male one and
                    populate it with just numbered data
                 */
                const dataWithIds = parsedData.data
                    .filter(row => Object.values(row).some(value => value !== '' && value !== null && value !== undefined)) // Filter out empty rows
                    .map((row, index) => {
                        const idValue = row.id || row.ID || row.Id || index; // Use existing id if it exists, otherwise use index
                        const displayId = index + 1;
                        return {
                            id: idValue,  // Ensure the `id` field is always present
                            displayId,
                            ...row  // Spread the rest of the row's data
                        };
                    });

                setFileDataWithIds(dataWithIds);

                setOriginalFileData(parsedData.data);  // Store the original data
                setColumns(Object.keys(parsedData.data[0]).map((key) => ({ field: key, headerName: key, width: 150 })));
            };
            reader.readAsText(file);
        } else if (fileType === 'xls' || fileType === 'xlsx') {
            // Handle XLS/X Parsing
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                // First row is header
                const headers = sheet[0];
                const rows = sheet.slice(1).map((row) => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });

                const dataWithIds = rows
                    .filter(row => Object.values(row).some(value => value !== '' && value !== null && value !== undefined)) // Filter out empty rows
                    .map((row, index) => {
                        const idValue = row.id || row.ID || row.Id || index; // Use existing id if it exists, otherwise use index
                        const displayId = index + 1;
                        return {
                            id: idValue,  // Ensure the `id` field is always present
                            displayId,
                            ...row  // Spread the rest of the row's data
                        };
                    });

                setFileDataWithIds(dataWithIds);
                setOriginalFileData(rows);  // Store the original data
                setColumns(headers.map((header) => ({ field: header, headerName: header, width: 150 })));

            };
            reader.readAsArrayBuffer(file);
        } else {
            alert('Unsupported file format. Please upload a CSV or Excel file.');
        }
    };
    const highlightDuplicates = (duplicates) => {
        const updatedRows = fileDataWithIds.map((row) => {
            // Dynamically find the email field (case-insensitive check for 'email')
            const emailKey = Object.keys(row).find(key => key.toLowerCase().includes('email'));
            return {
                ...row,
                isDuplicate: duplicates.includes(row[emailKey]?.toLowerCase()) // Mark duplicates
            };
        });

        console.log("updatedRows: "+JSON.stringify(updatedRows));

        // Sort: move duplicates to the top
        const sortedData = updatedRows.sort((a, b) => {
            if (a.isDuplicate && !b.isDuplicate) return -1;
            if (!a.isDuplicate && b.isDuplicate) return 1;
            return 0;
        });

        // Update the grid data with sorted and marked rows (this updates dataWithIds)
        setFileDataWithIds(sortedData);  // Update the state for DataGrid to re-render
    };
    const handleButtonSubmit = () => {
        console.log("selectedEntries : ", selectedEntries);

        // Extract emails from the selectedEntries (case-insensitive lookup for any variation of 'email')
        const selectedEmails = selectedEntries.map((row) => {
            // Find a key that contains 'email' (case-insensitive)
            const emailKey = Object.keys(row).find(key => key.toLowerCase().includes('email'));
            // Return the email value if found, otherwise return null
            return row[emailKey]?.toLowerCase() || null;
        }).filter(Boolean);  // Filter out any null values

        // Extract emails from the Attendees array
        const attendeeEmails = Attendees.map((attendee) => attendee.email?.toLowerCase());

        // Find duplicates between selected emails and Attendees' emails
        const duplicates = selectedEmails.filter((email) => attendeeEmails.includes(email));
        console.log("duplicates : ", duplicates);

        // Show the alert if duplicates are found and highlight them
        if (duplicates.length > 0) {
            setDuplicateEmails(duplicates);  // Display duplicates as an alert or some other UI
            highlightDuplicates(duplicates); // Highlight duplicates in the DataGrid
        } else {
            setDuplicateEmails([]);  // Clear duplicates
            // Proceed with the upload logic if no duplicates
            console.log('No duplicates found. Proceeding with submission...');
        }
    };
    const handleAttendeeFileReset = () => {

        // Reset the file input to remove any selected file
        setSelectedFile(null);

        // Clear file data states to reset the uploaded data
        setFileDataWithIds([]); // If using dataWithIds as a processed version
        setColumns([]);

        // Clear duplicate alerts or state flags related to duplicates
        setDuplicateEmails([]);
        setSelectedEntries([]);

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the displayed file name
        }

    }






    const [anchorAccountMenu, setAnchorAccountMenu] = useState(null);
    const handleAccountMenuOpenMenu = (event) => {
        setAnchorAccountMenu(event.currentTarget);
    };
    const handleAccountMenuClick = (event) => {
        console.log("handleAccountMenuClick");
        setAnchorAccountMenu(null);

    };
    const handleAccountMenuClose = () => {
        setAnchorAccountMenu(null);
    };


    // Dynamically calculate the card width based on the dialog width
    const dialogRefEvent = useRef(null);
    const [dialogWidth, setDialogWidth] = useState(0);
    // Get the width of the Dialog once it's rendered
    useEffect(() => {
        if (isEventDialogOpen && dialogRefEvent.current) {
            const width = dialogRefEvent.current.offsetWidth;
            console.log("&&&&&&&& setting dialogWidth : "+width);
            setDialogWidth(width);
        }
    }, [isEventDialogOpen]);
    const calculateCardWidth = () => {
        const cardsPerRow = 3;  // You can adjust the number of cards per row here
        const spacing = 16;  // Space between cards (as defined by Grid spacing)
        const totalSpacing = (cardsPerRow - 1) * spacing;
        const calculatedWidth = (dialogWidth - totalSpacing) / cardsPerRow;

        // Handle cases where calculatedWidth is negative
        if (calculatedWidth <= 0) {
            return 250; // Minimum fallback width
        }

        // Ensure the card width does not exceed 250px
        return Math.min(calculatedWidth, 250);
    };


    const formatEventTime = (timeTable) => {
        // Helper function to format the time (handle begin and end time)
        const formatTimeRange = (beginTime, endTime) => {
            const formattedBeginTime = dayjs(`2024-01-01 ${beginTime}`).format('hA');
            const formattedEndTime = endTime ? dayjs(`2024-01-01 ${endTime}`).format('hA') : '';
            return formattedEndTime ? `${formattedBeginTime} - ${formattedEndTime}` : formattedBeginTime;
        };

        // Function to format the date range
        const getDateRange = () => {
            const { dates } = timeTable;

            if (dates && dates.length > 0) {
                const beginDate = dayjs(dates[0].date);
                const endDate = dayjs(dates[dates.length - 1].date);

                if (beginDate.isSame(endDate, 'day')) {
                    // Same day with begin and end time
                    const beginTime = dates[0]['begin-time'] || '12:00 AM';
                    const endTime = dates[0]['end-time'] || '11:59 PM';
                    return `${beginDate.format('D MMM YYYY')} ${formatTimeRange(beginTime, endTime)}`;
                } else if (beginDate.isSame(endDate, 'year')) {
                    // Same year, different days
                    return `${beginDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`;
                } else {
                    // Different years
                    return `${beginDate.format('D MMM YYYY')} - ${endDate.format('D MMM YYYY')}`;
                }
            }
            return null;
        };

        // Function to format the days (if no dates exist)
        const getDaysRange = () => {
            const { days } = timeTable;

            if (days && days.length > 0) {
                const firstDay = days[0]['day 1'];
                const lastDay = days[days.length - 1][`day ${days.length}`];
                const dayCount = days.length;

                return `${dayCount} Days ${firstDay} - ${lastDay}`;
            }
            return null;
        };

        // Return the event's date range or days as a formatted string
        const dateRange = getDateRange();
        const daysRange = getDaysRange();

        // Return either the date range or days range
        return dateRange || daysRange || '';
    };

    const [anchorAttendeeElements, setAnchorAttendeeElements] = useState(sortedAttendees.map(() => null));
    const handleOpenAttendeeMenu = (event, index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = event.currentTarget;
        setAnchorAttendeeElements(updatedAnchors);
    };

    // Function to handle closing the menu for a specific attendee.
    const handleCloseAttendeeMenu = (index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = null;
        setAnchorAttendeeElements(updatedAnchors);
    };


    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [attendeeToDeleteId, setAttendeeToDeleteId] = useState("");
    const [attendeeToDeleteName, setAttendeeToDeleteName] = useState("");

    function handleDialogDeleteOpen(index, attendee_id) {
        console.log("handleDialogDeleteOpen : "+attendee_id);

        handleCloseAttendeeMenu(index);

        setAttendeeToDeleteId(attendee_id);

        const foundAttendee = Attendees.find((_att) => _att.id === attendee_id);
        setAttendeeToDeleteName(foundAttendee.fname + ' ' + foundAttendee.lname);

        setOpenDialogDelete(true);

    }
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

    }


    const [selectedImage, setSelectedImage] = useState(""); // Initial image

    // Function to handle file input click on avatar
    const handleAvatarClick = (id) => {
        document.getElementById(`file-input-${id}`).click();
    };

    // Function to handle file change and set the image
    const handleImageChange = (event, id) => {
        const file = event.target.files[0];

        console.log("file : "+file);

        if (file) {
            const imageUrl = URL.createObjectURL(file); // Create a URL for the selected image


            // Optionally save the file to the attendee's profile
            setSortedAttendees(prevAttendees =>
                prevAttendees.map(attendee =>
                    attendee.id === id
                        ? { ...attendee, image: imageUrl } // Update the image for the matched attendee
                        : attendee
                )
            );
        }
    };



    const [groupedAttendees, setGroupedAttendees] = useState([]); // State to hold grouped attendees

    // Toggle attendee in and out of the group array
    const handleGroupToggle = (attendeeId) => {
        setGroupedAttendees(prevGrouped =>
            prevGrouped.includes(attendeeId)
                ? prevGrouped.filter(id => id !== attendeeId) // Remove if already in group
                : [...prevGrouped, attendeeId] // Add if not in group
        );
    };

    const [isGettingStartedDialogOpen, setGettingStartedDialogOpen] = useState(false);





    return (

        <Container
            sx={{
                position: 'sticky',
                top : 0,
                maxWidth: '100% !important',
                padding: '0 !important',
                zIndex: 1,
                overflow: 'auto'
            }}>

            <LinearProgress variant="determinate" value={progressBarIndicator} />

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: (dataArray.authenticated ? (dataArray.sideMenuWidth===0 ? 0 : dataArray.sideMenuWidth+"px") : 0),
                position: 'relative',
                top: 0,
                left: 0,
                backdropFilter: 'blur(6px)',
                backgroundColor: 'rgba(249, 250, 251, 0.8)',
                zIndex: 1
            }}>

                <div className="logo-holder">

                    <div
                        style={{
                            backgroundColor: dataArray.ws_connected ? "green" : "red"
                        }}
                        id="ws_connection"
                        className="ws-connection"
                    />
                    <Box sx={{display: {md: 'block', xs: 'none'}}}>
                        <img
                            className="logo-large"
                            src={large_logo}
                            onClick={onLogoClick}
                            alt=""
                        />
                    </Box>
                    <Box sx={{display: {md: 'none', xs: 'block'}}}>
                        <img
                            className="logo"
                            src={small_logo}
                            onClick={onLogoClick}
                            alt=""
                        />
                    </Box>
                </div>

                {/* Desktop links */}
                <Box sx={{display: {md: 'block', xs: 'none'}}}>
                    <div style={{ justifyContent: 'flex-end' }}>
                        <List style={{ display: 'flex', alignItems: 'center' }}>

                            {
                                dataArray.authenticated ? (
                                    <>
                                        <ListItemButton
                                            className={selectedMenuItem === 'templates' ? 'show-underline' : 'underline'}
                                            onClick={handleTemplateMenuOpenMenu}
                                        >
                                            <ListItemText
                                                primary="Templates"
                                                primaryTypographyProps={{ style: { fontSize: '14px', color:'#F00'} }}
                                            />
                                        </ListItemButton>
                                        <Menu
                                            sx={{ width: 320, maxWidth: '100%' }}
                                            anchorEl={anchorTemplateMenu}
                                            id="template-menu"
                                            open={Boolean(anchorTemplateMenu)}
                                            onClose={handleTemplateMenuClose}

                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuList dense sx={{ width: 320, maxWidth: '100%' }}>
                                                <MenuItem onClick={() => handleTemplateMenuClick('iceland')}>
                                                    <Typography>Iceland</Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                        <ListItemButton
                                            className={selectedMenuItem === 'events' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Events"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'contacts' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Contacts"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'planner' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Planner"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        {
                                            Account.role === "master_admin" ? (
                                                <ListItemButton
                                                    className={selectedMenuItem === 'flightplanner' ? 'show-underline' : 'underline'}
                                                    onClick={onButtonClick}
                                                >
                                                    <ListItemText
                                                        primary="FlightPlanner"
                                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                                    />
                                                </ListItemButton>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            Account.role === "master_admin" ? (
                                                <ListItemButton
                                                    className={selectedMenuItem === 'admins' ? 'show-underline' : 'underline'}
                                                    onClick={onButtonClick}
                                                >
                                                    <ListItemText
                                                        primary="Admins"
                                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                                    />
                                                </ListItemButton>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        <ListItemButton
                                            className='underline'
                                            onClick={() => setGettingStartedDialogOpen(!isGettingStartedDialogOpen)}
                                        >
                                            <i className="icon-question-circle" style={{fontSize: '20px', padding: '5px'}}/>
                                        </ListItemButton>
                                        <ListItemButton
                                            className='underline'
                                            onClick={handleAccountMenuOpenMenu}
                                        >
                                            <Avatar {...stringAvatar(`${formatName(dataArray.Account.fname)}, ${formatName(dataArray.Account.lname)}`)} />
                                        </ListItemButton>
                                        <Menu
                                            sx={{ width: 320, maxWidth: '100%' }}
                                            anchorEl={anchorAccountMenu}
                                            id="account-menu"
                                            open={Boolean(anchorAccountMenu)}
                                            onClose={handleAccountMenuClose}

                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuList dense sx={{ width: 320, maxWidth: '100%' }}>
                                                <MenuItem onClick={() => handleAccountMenuClick('account')}>
                                                    <Typography sx={{color:'#F00'}}>Account</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleAccountMenuClick('notifications')}>
                                                    <Typography sx={{color:'#F00'}}>Notifications</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleAccountMenuClick('supply_essentials')}>
                                                    <Typography sx={{color:'#F00'}}>Supply Essentials</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleAccountMenuClick('document_center')}>
                                                    <Typography sx={{color:'#F00'}}>Document Center</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleAccountMenuClick('event_transactions')}>
                                                    <Typography sx={{color:'#F00'}}>Event Transactions</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleAccountMenuClick('welcome')}>
                                                    <Typography sx={{color:'#F00'}}>Welcome & Tips</Typography>
                                                </MenuItem>
                                                <MenuItem onClick={() => handleAccountMenuClick('refresh_content')}>
                                                    <Typography sx={{color:'#F00'}}>Refresh Content</Typography>
                                                </MenuItem>
                                                {
                                                    Account.role === "master_admin" ? (
                                                        <MenuItem onClick={() => handleAccountMenuClick('admin_dashboard')}>
                                                            <Typography sx={{color:'#F00'}}>Admin Dashboard</Typography>
                                                        </MenuItem>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <MenuItem onClick={() => handleAccountMenuClick('logout')}>
                                                    <Typography sx={{color:'#F00'}}>Logout</Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </>
                                ) : (
                                    <>
                                        <ListItemButton
                                            className={selectedMenuItem === 'features' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Features"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'pricing' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Pricing"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'contact' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Contact Us"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'templates' ? 'show-underline' : 'underline'}
                                            onClick={handleTemplateMenuOpenMenu}
                                        >
                                            <ListItemText
                                                primary="Templates"
                                                primaryTypographyProps={{ style: { fontSize: '14px', color:'#F00'} }}
                                            />
                                        </ListItemButton>
                                        <Menu
                                            sx={{ width: 320, maxWidth: '100%' }}
                                            anchorEl={anchorTemplateMenu}
                                            id="template-menu"
                                            open={Boolean(anchorTemplateMenu)}
                                            onClose={handleTemplateMenuClose}

                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuList dense sx={{ width: 320, maxWidth: '100%' }}>
                                                <MenuItem onClick={() => handleTemplateMenuClick('iceland')}>
                                                    <Typography>Iceland</Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                        <ListItemButton
                                            className={selectedMenuItem === 'planner' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Planner"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'login' ? 'show-underlined' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Login"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={selectedMenuItem === 'signup' ? 'show-underline' : 'underline'}
                                            onClick={onButtonClick}
                                        >
                                            <ListItemText
                                                primary="Sign Up"
                                                primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                            />
                                        </ListItemButton>
                                    </>
                                )
                            }

                        </List>
                    </div>
                </Box>

                {/* Hamburger icon for mobile */}
                <Box sx={{display: {md: 'none', xs: 'block'}}}>
                    <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Box>

            </div>

            {/* Mobile drawer */}
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
                <List>

                    {
                        dataArray.authenticated ? (
                            <>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Templates"
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'red' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Templates' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Events"
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'green' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Events' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Contacts"
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'green' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Contacts' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Planner"
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'green' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Planner' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                {
                                    Account.role === "master_admin" ? (
                                        <ListItemButton onClick={onButtonClick} >
                                            <ListItemText
                                                primary="Admins"
                                                primaryTypographyProps={{ style: { fontSize: '14px', color:'green' } }}
                                                sx={{ textDecoration: selectedMenuItem === 'admins' ? 'show-underline' : 'underline'}}
                                            />
                                        </ListItemButton>
                                    ) : (
                                        <></>
                                    )
                                }
                                <ListItemButton
                                    className='underline'
                                    onClick={() => setGettingStartedDialogOpen(!isGettingStartedDialogOpen)}
                                >
                                    <ListItemText
                                        primary="Getting Started"
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'green' } }}
                                        sx={{ textDecoration: 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary={`Logout (${dataArray.Account.fname})`}
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'green' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'logout' ? 'underline' : 'none' }} />
                                </ListItemButton>
                            </>
                        ) : (
                            <>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Features"
                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Features' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Pricing"
                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Pricing' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Contact Us"
                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Contact Us' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Templates"
                                        primaryTypographyProps={{ style: { fontSize: '14px', color:'#F00' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Templates' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Planner"
                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Planner' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Login"
                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Login' ? 'underline' : 'none' }} />
                                </ListItemButton>
                                <ListItemButton onClick={onButtonClick}>
                                    <ListItemText
                                        primary="Sign Up"
                                        primaryTypographyProps={{ style: { fontSize: '14px' } }}
                                        sx={{ textDecoration: selectedMenuItem === 'Sign Up' ? 'underline' : 'none' }} />
                                </ListItemButton>
                            </>
                        )
                    }
                </List>
            </Drawer>


            <Dialog
                sx={{ '& .MuiDialog-paper': { maxHeight: 435 } }}
                open={isModalOpen}
            >
                <DialogTitle>Please Login</DialogTitle>

                <DialogContent >

                    <GoogleLogin
                        shape="pill"
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse.credential);

                            setLoginFailed(false);

                            //send this to the server for processing
                            const _sendString = {};
                            _sendString.action = "E_processUserLoginUsingGoogleJWT";
                            _sendString.jwt = credentialResponse;

                            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />

                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Divider>
                            <Chip label="OR" />
                        </Divider>
                    </div>

                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-username"
                            variant="outlined"
                            type="text"
                            label="Username"
                            onChange={handleUsernameInputChange}
                            value={username}
                            error={usernameErrorStatus}
                        />
                        {usernameErrorStatus && (
                            <FormHelperText sx={{color:"red"}}>* required</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={handlePasswordInputChange}
                            value={password}
                            error={passwordErrorStatus}
                        />
                        {passwordErrorStatus && (
                            <FormHelperText sx={{color:"red"}}>* required</FormHelperText>
                        )}
                    </FormControl>

                </DialogContent>


                {dataArray.loginFailed && (
                    <Alert severity="error">Incorrect username or password</Alert>
                )}


                <DialogActions>
                    <Button autoFocus onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk}>Ok</Button>
                </DialogActions>
            </Dialog>


            {/* Event Dialog */}
            <Dialog
                ref={dialogRefEvent}
                open={isEventDialogOpen}
                onClose={closeEventDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>{sortedEvents.length} Event{sortedEvents.length===1?'':'s'}</DialogTitle>
                <Tooltip title="Sort">
                    <IconButton
                        aria-label="Sort"
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 250,
                            top: 8,
                            color: theme.palette.grey[700],
                            cursor: 'pointer',
                            fontSize: '18px',
                        })}
                        onClick={handleSortToggle}
                    >
                        <i
                            className="icon-sort-time-desc"
                            style={{
                                color: 'green',
                                fontSize: '18px',
                                padding: '10px',
                                cursor: 'pointer'
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Filter">
                    <IconButton
                        aria-label="Filter"
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 200,
                            top: 8,
                            color: theme.palette.grey[700],
                            cursor: 'pointer',
                            fontSize: '18px',
                        })}
                        onClick={handleFilterMenuOpen}
                    >
                        <Badge
                            badgeContent={selectedLocations.length + selectedEventAttendees.length}  // Show number of selected categories
                            sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'white', // Customize the dot color here
                                    border: '1px solid', // Make it a circle
                                    width: '15px', // Adjust the dot size as needed
                                    height: '15px',
                                    color: 'green',
                                    fontWeight: '700',
                                    borderRadius: '35%', // Make it a circle
                                    top: '7px', // Adjust top and right values to move the dot
                                    right: '7px', // Adjust top and right values to move the dot
                                },
                            }}
                            invisible={selectedLocations.length + selectedEventAttendees.length === 0} // Hide the badge if none are selected
                        >
                            <i
                                className="icon-funnel"
                                style={{
                                    color: 'green',
                                    fontSize: '18px',
                                    padding: '10px',
                                    cursor: 'pointer'
                                }}
                            />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorElFilter}
                    open={Boolean(anchorElFilter)}
                    onClose={handleFilterMenuClose}

                >
                    {/* Locations Section */}
                    <List
                        subheader={
                            <ListSubheader
                                sx={{ bgcolor: 'rgba(198, 226, 255, 0.8)', color: '#343a40' }}
                            >
                                Locations
                            </ListSubheader>
                        }
                        dense
                    >
                        {Object.keys(uniqueLocations)
                            .sort()
                            .map((state) => (
                                <div key={state}>
                                    {/* Display the state as a header */}
                                    <MenuItem disabled>
                                        <ListSubheader
                                            sx={{ color: '#151269' }}
                                        >
                                            {state.toUpperCase()}
                                        </ListSubheader>
                                    </MenuItem>

                                    {/* Display cities for that state */}
                                    {uniqueLocations[state]
                                        .sort()
                                        .map((city) => {
                                            const location = `${city}, ${state}`;
                                            return (
                                                <MenuItem
                                                    key={location}
                                                    onClick={() => handleLocationToggle(location)}
                                                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                                                >
                                                    <Typography>{city}</Typography>
                                                    <Checkbox
                                                        checked={selectedLocations.includes(location)}
                                                        edge="end"
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={() => handleLocationToggle(location)} // Toggle Location selection
                                                    />
                                                </MenuItem>
                                            );
                                        })}
                                </div>
                            ))}
                    </List>

                    {/* Attendees Section */}
                    <List
                        subheader={
                            <ListSubheader
                                sx={{ bgcolor: 'rgba(198, 226, 255, 0.8)', color: '#151269' }} // Same light blue background and darker gray for attendees section title
                            >
                                Attendees
                            </ListSubheader>
                        }
                        dense
                    >
                        {Attendees
                            .sort((a, b) => (a.fname || '').localeCompare(b.fname || ''))
                            .map((attendee) => (
                                <MenuItem
                                    key={attendee.id}
                                    onClick={() => handleEventAttendeeToggle(attendee.id)}
                                    sx={{ display: 'flex', justifyContent: 'space-between' }} // Align label to the left and checkbox to the right
                                >
                                    <Typography>{`${capitalizeFirstLetter(attendee.fname)} ${capitalizeFirstLetter(attendee.lname)}`}</Typography>
                                    <Checkbox
                                        checked={selectedEventAttendees.includes(attendee.id)}
                                        edge="end"
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => handleEventAttendeeToggle(attendee.id)} // Toggle Attendee selection
                                    />
                                </MenuItem>
                            ))}
                    </List>
                </Menu>

                {
                    uniqueCategories.length > 0 && (
                        <>
                            <Tooltip title="Categories">
                                <IconButton
                                    aria-label="Categories"
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        right: 150,
                                        top: 8,
                                        color: theme.palette.grey[700],
                                        cursor: 'pointer',
                                        fontSize: '18px',
                                    })}
                                    onClick={handleCategoryMenuOpen} // Open the menu on click
                                >
                                    <Badge
                                        badgeContent={selectedCategories.length}  // Show number of selected categories
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                backgroundColor: 'white', // Customize the dot color
                                                border: '1px solid', // Border to make it stand out
                                                width: '15px', // Adjust the dot size as needed
                                                height: '15px',
                                                color: 'green', // Badge text color
                                                fontWeight: '700',
                                                borderRadius: '50%', // Perfect circle
                                                top: '7px', // Adjust top position of the badge
                                                right: '7px', // Adjust right position of the badge
                                            },
                                        }}
                                        invisible={selectedCategories.length === 0} // Hide badge if none are selected
                                    >
                                        <i
                                            className="icon-folder-heart"
                                            style={{
                                                color: 'green',
                                                fontSize: '18px',
                                                padding: '10px',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Badge>
                                </IconButton>
                            </Tooltip>

                            {/* Category Menu */}
                            <Menu
                                anchorEl={anchorElCategory}
                                open={Boolean(anchorElCategory)}
                                onClose={handleCategoryMenuClose}
                            >
                                <List
                                    subheader={
                                        <ListSubheader
                                            sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold', color: '#888' }}
                                        >
                                            Categories
                                        </ListSubheader>
                                    }
                                    dense
                                >
                                    {
                                        uniqueCategories
                                        .sort()
                                        .map((category) => (
                                            <MenuItem key={category} onClick={() => handleCategoryToggle(category)}>
                                                <ListItemText primary={category} />
                                                <Checkbox
                                                    edge="end"
                                                    checked={selectedCategories.includes(category)}
                                                    onClick={(e) => e.stopPropagation()} // Prevent parent click event
                                                    onChange={() => handleCategoryToggle(category)} // Toggle category selection
                                                />
                                            </MenuItem>
                                        ))
                                    }
                                </List>
                            </Menu>
                        </>
                    )
                }

                <Tooltip title="Search">
                    <IconButton
                        aria-label="Search"
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 100,
                            top: 8,
                            color: theme.palette.grey[700],
                            cursor: 'pointer',
                            fontSize: '18px',
                        })}
                    >
                        <i
                            className="icon-search"
                            style={{
                                color: 'red',
                                fontSize: '18px',
                                padding: '10px',
                                cursor: 'pointer'
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reset">
                    <IconButton
                        aria-label="Reset"
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 50,
                            top: 8,
                            color: theme.palette.grey[700],
                            cursor: 'pointer',
                            fontSize: '18px',
                        })}
                        onClick={handleReset}
                    >
                        <i
                            className="icon-cw"
                            style={{
                                color: 'green',
                                fontSize: '18px',
                                padding: '10px',
                                cursor: 'pointer'
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Close">
                    <IconButton
                        aria-label="Close"
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 0,
                            top: 8,
                            color: theme.palette.grey[700],
                            cursor: 'pointer',
                            fontSize: '18px',
                        })}
                        onClick={closeEventDialog}
                    >
                        <i
                            className="icon-cross"
                            style={{
                                color: 'green',
                                fontSize: '18px',
                                padding: '10px',
                                cursor: 'pointer'
                            }}
                        />
                    </IconButton>
                </Tooltip>

                <DialogContent>
                    <Grid container spacing={2}>
                        {sortedEvents.map((event, index) => (
                            <Grid
                                item
                                key={index}
                                sx={{
                                    width: calculateCardWidth(),
                                }}
                            >
                                <Card
                                    onClick={() => handleEventMenuClick(event.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: calculateCardWidth(),
                                        height: '220px',  // Fixed height for all cards
                                        display: 'flex',
                                        flexDirection: 'column',  // Arrange image and title vertically
                                        justifyContent: 'space-between',
                                    }}
                                >


                                    {
                                        (event.cover_image_thumbnail !== '' ) ? (
                                            <CardMedia
                                                sx={{
                                                    width: calculateCardWidth(), // Set the desired width
                                                    height: '150px', // Set the desired height
                                                    objectFit: 'cover', // Ensures the image fills the box while maintaining aspect ratio
                                                }}
                                                component="img"
                                                image={event.cover_image_thumbnail}
                                                alt={event.title}
                                            />
                                        ) : (
                                            <CardMedia
                                                sx={{
                                                    width: calculateCardWidth(), // Set the desired width
                                                    height: '150px', // Set the desired height
                                                    objectFit: 'cover', // Ensures the image fills the box while maintaining aspect ratio
                                                }}
                                                component="img"
                                                image={"https://plannedadventure.com/assets/images/user_bg1.png"}
                                                alt={event.title}
                                            />
                                        )
                                    }

                                    <CardContent sx={{paddingTop:0, paddingBottom:'15px !important'}}>
                                        <Typography variant="subtitle2" display="block">{event.title}</Typography>
                                        {/* add the dates or days */}

                                        <Typography variant="caption" display="block">{formatEventTime(event.time_table)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>

            </Dialog>


            {/* Contacts Dialog */}
            <MasterAttendees
                dialogRefAttendees={dialogRefAttendees}
                isAttendeesDialogOpen={isAttendeesDialogOpen}
                closeAttendeesDialog={closeAttendeesDialog}
                isEventDialogOpen={isEventDialogOpen}
                isUploadModeOn={false}
            />

            <GettingStarted
                isOpen={isGettingStartedDialogOpen}
                onClose={() => setGettingStartedDialogOpen(false)}
            />

            <Dialog
                open={openDialogDelete}
                onClose={handleDialogDeleteClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {attendeeToDeleteName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Header;
