import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    ListItem,
    ListItemAvatar, Menu, MenuItem, MenuList, Tab,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {addChipStyle} from "../../ButtonStyle/Add";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {editChipStyle} from "../../ButtonStyle/Edit";
import {Autocomplete} from "@mui/material";
import sample_attendees from "../../../sampleData/Attendees";
import MealLocationTab from "./MealLocationTab";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, stringAvatar, stringToColor} from "../../Helpers/TextStyling";


dayjs.extend(customParseFormat);


function MealCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Attendees, SampleAttendees } = dataArray;

    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);
    const [selectedTimetable, setSelectedTimetable] = useState(eventHasDates || !eventHasDays ? "dates" : "days");

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    const [ selectedAttendeesArray, setSelectedAttendeesArray ] = useState( []);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);


    const [eventMeal, setEventMeal] = useState(props.meal);
    const [selectedAction, setSelectedAction] = useState("");
    const [mealTabSelected, setMealTabSelected] = useState("");

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);

    const fetchedRef = useRef(false);
    useEffect(() => {
        console.log("props.meal has been updated --- : ", props.meal);

        if (eventMeal.attendees && eventMeal.attendees.length > 0) {
            console.log("attendeesReferenceArray : ", attendeesReferenceArray);
            const initialSelectedAttendees = eventMeal.attendees
                .map((mealAttendee) =>
                    attendeesReferenceArray.find((attendee) => attendee.id === mealAttendee.id)
                );

            console.log("initialSelectedAttendees : ", initialSelectedAttendees);
            setSelectedAttendeesArray(initialSelectedAttendees);
        }

        const fetchPhotos = async () => {
            // Ensure no redundant fetch
            if (
                fetchedRef.current ||
                (props.meal.photos && props.meal.photos.length > 0) ||
                !props.meal.location.google_place_id
            ) {
                return; // Exit if photos already exist, have been fetched, or no place ID is available
            }

            fetchedRef.current = true; // Mark as fetched to prevent duplicate fetches

            const request = {
                placeId: props.meal.location.google_place_id,
                fields: ['photo'],
            };

            try {
                const results = await new Promise((resolve, reject) => {
                    const _global_map_service = new window.google.maps.places.PlacesService(
                        props._global_map_ref.current
                    );
                    _global_map_service.getDetails(request, (place, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve(place);
                        } else {
                            reject(status);
                        }
                    });
                });

                if (results.photos) {
                    const newPhotos = results.photos.map(photo =>
                        photo.getUrl({ maxWidth: 175, maxHeight: 175 })
                    );

                    // Update state without mutating props
                    setEventMeal(prev => ({ ...prev, photos: newPhotos }));
                } else {
                    console.log("No photos available for this eatery.");
                }
            } catch (error) {
                console.error("Error fetching photos for eatery:", error);
            }
        };

        fetchPhotos();
    }, [props.meal]);

    useEffect(() => {
        console.log("eventMeal has been triggered --- : ");

        if (eventMeal !== props.meal) {
            props.updateMealDetails(eventMeal);
        }

    }, [eventMeal]);

    useEffect(()=> {

        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move activity attendees to the top of the list
            for (let i = eventMeal.attendees.length - 1; i >= 0; i--) {
                const activityAttendee = eventMeal.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === activityAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);

    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.meal[amount_total] : "+props.meal["amount_total"]+" and "+eventMeal["amount_total"]);
        if (props.meal["amount_total"] !== eventMeal["amount_total"] || props.meal["currency"] !== eventMeal["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventMeal["attendees"].some((_attendee, index) => {
                const initialAttendee = props.meal["attendees"].find(a => a.id === _attendee.id);

//                console.log("comparing attendee costs : "+_attendee.cost);
//                console.log("comparing initialAttendee costs : "+initialAttendee["cost"]);

                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
            //turn on the button

            console.log("an attendee cost has changed");

            setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);

    const handleAttendeesToggle = (attendeeId) => () => {
        // Determine if the attendee is already in the meal attendee list
        const attendeeIndex = eventMeal.attendees.findIndex(item => item.id === attendeeId);
        let updatedMealAttendees = [...eventMeal.attendees];

        // Check if the attendee already exists
        if (attendeeIndex !== -1) {
            // Remove attendee from the meal attendee list
            updatedMealAttendees.splice(attendeeIndex, 1);

        } else {
            // Find the attendee in the reference array
            const newAttendee = attendeesReferenceArray.find(att => att.id === attendeeId);

            if (!newAttendee) {
                console.error(`Attendee with ID ${attendeeId} not found.`);
                return;
            }

            // Add to global context if not already present
            const globalAttendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;
            if (!globalAttendees.some(att => att.id === attendeeId)) {
                set_PA_GlobalObject(prev => ({
                    Planning: {
                        ...prev.Planning,
                        attendees: [...prev.Planning.attendees, newAttendee],
                    },
                }));
            }

            // Add the attendee to the meal attendee list
            const attendeeWithCost = {
                id: attendeeId,
                cost: 0,
                is_business_expense: false,
                business_expense_amount_authorized: 0,
                business_expense_amount_authorized_by: "",
                business_expense_amount_authorized_timestamp: 0,
                business_expense_amount: 0,
                business_expense_paid: 0,
                business_expense_reference_id: "",
            };
            updatedMealAttendees = [...eventMeal.attendees, attendeeWithCost];

        }

        // Update costs if "assign_equal" is enabled
        if (eventMeal.assign_equal) {
            const mealCost = parseFloat(eventMeal.amount_total);
            const numAttendees = updatedMealAttendees.length;
            const baseCost = (Math.floor((mealCost / numAttendees) * 100) / 100).toFixed(2);
            const remainder = (mealCost - (baseCost * numAttendees)).toFixed(2);

            updatedMealAttendees.forEach((attendee, i) => {
                attendee.cost = i === numAttendees - 1
                    ? (parseFloat(baseCost) + parseFloat(remainder)).toFixed(2) // Add remainder to last attendee
                    : baseCost;
            });
        }

        // Update the event meal state
        setEventMeal(prevState => ({
            ...prevState,
            attendees: updatedMealAttendees,
        }));
    };


    function handleCostOverride(event) {
        const valueToAdd = parseFloat(event.target.value);

        setEventMeal(prevState => ({
            ...prevState,
            amount_total: valueToAdd
        }));

    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventMeal["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventMeal["attendees"]];
                let activityCost = parseFloat(eventMeal["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("participants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

            }

            props.updateMealDetails(eventMeal);
        }

        setShowPriceOverride(!showPriceOverride);
    }
    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventMeal(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        //recalculate to see if that changes the Attendees total breakdown
        setCheckShowPriceChanges(true);

        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };
    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventMeal["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventMeal(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventMeal["attendees"]];

            const activityCost = parseFloat(eventMeal["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventMeal(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }
    function handleCustomAttendeeCost(_attendee_id, e){
        console.log("handleCustomAttendeeCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventMeal(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);
    }

    const [editModeMap, setEditModeMap] = useState({});

    const handleBusinessExpenseToggle = (attendeeId, isChecked) => {
        setEventMeal((prevMeal) => ({
            ...prevMeal,
            attendees: prevMeal.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: isChecked,
                        business_expense_amount: isChecked ? att.cost : 0, // Sync with cost if toggled on
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseAmountEdit = (attendeeId) => {
        setEditModeMap((prev) => ({
            ...prev,
            [attendeeId]: !prev[attendeeId], // Toggle edit mode
        }));
    };
    const handleBusinessExpenseAmountChange = (attendeeId, value) => {
        // Use a regex to allow only valid decimal numbers
        const validInput = value.match(/^\d*\.?\d{0,2}$/); // Allows up to two decimal places
        if (!validInput) return;

        setEventMeal((prevMeal) => ({
            ...prevMeal,
            attendees: prevMeal.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        business_expense_amount: value, // Keep raw string for input control
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseEnableOnEdit = (attendeeId) => {
        setEventMeal((prevMeal) => ({
            ...prevMeal,
            attendees: prevMeal.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: true, // Automatically toggle checkbox
                    }
                    : att
            ),
        }));
    };


    function handleSaveMealCostAssignment(event){
        props.updateMealDetails(eventMeal);
        setShowPriceChangesUpdateButton(false);
    }


    function handleBookedThroughChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            booked_through: _newValue
        }));
    }
    function handleConfirmationNumberChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            confirmation_number: _newValue
        }));
    }
    function handleReservationNameChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            reservation_name: _newValue
        }));
    }
    function handleReservationNumberChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            reservation_number: _newValue
        }));
    }
    function handleNotesChange(event){
        const _newValue = event.target.value;
        setEventMeal(prevState => ({
            ...prevState,
            notes: _newValue
        }));
    }

    const handleFooterItemClick = (_item) => {

        console.log("1. "+eventMeal['time_table'].dates[0]?.begin_time);
        console.log("2. "+dayjs(eventMeal['time_table'].dates[0]?.begin_time, 'h:mm A').isValid());

        setSelectedAction(_item);
        mealTabSelected === _item ? setMealTabSelected("") : setMealTabSelected(_item);
    };

    const [anchorMealListItemMenu, setAnchorMealListItemMenu] = useState(null);
    const handleMealListItemMenuOpenMenu = (event) => {
        setAnchorMealListItemMenu(event.currentTarget);
    };
    const handleMealListItemMenuClose = () => {
        setAnchorMealListItemMenu(null);
    };

    const [tabValue, setTabValue] = useState(eventHasDates || !eventHasDays ? "dates" : "days");
    const [showCalendarSelectMealDate, setShowCalendarSelectMealDate] = useState(false);
    const [showSelectMealDay, setShowSelectMealDay] = useState(false);
    const [selectedMealDate, setSelectedMealDate] = useState(null);
    const [selectedMealDay, setSelectedMealDay] = useState('');

    const [showClockSelectMealDateTime, setShowClockSelectMealDateTime] = useState(false);
    const [showClockSelectMealDayTime, setShowClockSelectMealDayTime] = useState(false);

    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setTabValue(newValue);
    };
    const handlePromptShowMealDateCalendar  = () => {
        console.log("handlePromptShowMealDateCalendar ");
        setShowCalendarSelectMealDate(!showCalendarSelectMealDate);
    };
    const handlePromptShowMealDaySelect  = () => {
        console.log("handlePromptShowMealDaySelect ");
        setShowSelectMealDay(!showSelectMealDay);
    };

    const handleMealDateChange = (newDate) => {
        setSelectedMealDate(newDate);

        setEventMeal((prevState) => {
            const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
            const formattedDayOfWeek = dayjs(newDate).format("dddd");

            // Retrieve the global time_table for comparison
            const globalTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table;

            // Check if the date already exists in the `dates` array
            const dateExists = prevState.time_table.dates.some(
                (date) => date.date === formattedDate
            );

            // Create or update the `dates` array
            const updatedDates = dateExists
                ? prevState.time_table.dates.map((date) =>
                    date.date === formattedDate
                        ? {
                            ...date,
                            date: formattedDate,
                            dayOfWeek: formattedDayOfWeek,
                            "all-day": date["all-day"] || false,
                            notes: date.notes || "",
                        }
                        : date
                )
                : [
                    ...prevState.time_table.dates,
                    {
                        date: formattedDate,
                        dayOfWeek: formattedDayOfWeek,
                        "all-day": false, // Default to non-all-day event
                        notes: "",
                    },
                ];

            // Check if the selected date corresponds to an existing day in the global `days` array
            const globalDay = globalTimeTable.days.find((day) =>
                Object.values(day).includes(formattedDayOfWeek)
            );

            // If a corresponding global day is found, get its key
            const globalDayKey = globalDay
                ? Object.keys(globalDay).find((key) => globalDay[key] === formattedDayOfWeek)
                : `day ${prevState.time_table.days.length + 1}`;

            // Check if the corresponding day exists in the `days` array
            const dayExists = prevState.time_table.days.some(
                (day) => day[globalDayKey] === formattedDayOfWeek
            );

            // Create or update the `days` array
            const updatedDays = dayExists
                ? prevState.time_table.days.map((day) =>
                    day[globalDayKey] === formattedDayOfWeek
                        ? {
                            ...day,
                            [globalDayKey]: formattedDayOfWeek,
                            "all-day": day["all-day"] || false,
                            notes: day.notes || "",
                        }
                        : day
                )
                : [
                    ...prevState.time_table.days,
                    {
                        [globalDayKey]: formattedDayOfWeek,
                        "all-day": false, // Default to non-all-day event
                        notes: "",
                    },
                ];

            return {
                ...prevState,
                time_table: {
                    ...prevState.time_table,
                    dates: updatedDates,
                    days: updatedDays,
                },
            };
        });
    };



    const handlePromptShowMealDateTime  = () => {
        console.log("handlePromptShowMealDateTime ");
        setShowClockSelectMealDateTime(!showClockSelectMealDateTime);
    };
    const handlePromptShowMealDayTime  = () => {
        console.log("handlePromptShowMealDayTime ");
        setShowClockSelectMealDayTime(!showClockSelectMealDayTime);
    };
    const handleMealDateTimeChange = (beginTime) => {
        const formattedBeginTime = beginTime ? dayjs(beginTime).format('h:mm A') : null;

        setEventMeal((prevState) => {
            const globalTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table;

            // Update the `dates` array and handle corresponding days
            const updatedDates = prevState.time_table.dates.map((date) => {
                // Check for a corresponding day in the global `days` array for each date
                const correspondingGlobalDay = globalTimeTable.days.find((day) =>
                    Object.values(day).includes(date.dayOfWeek)
                );

                const dayKey = correspondingGlobalDay
                    ? Object.keys(correspondingGlobalDay).find((key) => correspondingGlobalDay[key] === date.dayOfWeek)
                    : `day ${prevState.time_table.days.length + 1}`;

                // Check if the corresponding day exists in `days` and update or add it
                const updatedDays = prevState.time_table.days.map((day) =>
                    day[dayKey]
                        ? {
                            ...day,
                            'begin_time': formattedBeginTime || day['begin_time'],
                        }
                        : day
                );

                // If the day does not exist, add a new entry
                if (!prevState.time_table.days.some((day) => day[dayKey])) {
                    updatedDays.push({
                        [dayKey]: date.dayOfWeek,
                        'begin_time': formattedBeginTime,
                        'all-day': false, // Default to non-all-day
                        notes: "",
                    });
                }

                return {
                    ...date,
                    'begin_time': formattedBeginTime || date['begin_time'],
                };
            });

            return {
                ...prevState,
                time_table: {
                    ...prevState.time_table,
                    dates: updatedDates,
                    days: prevState.time_table.days, // Keep the updated days array
                },
            };
        });
    };



    const handleMealDayChange = (dayKey, dayName) => {
        console.log("handleMealDayChange", dayKey, ":", dayName);

        // Set the selected meal day in state
        setSelectedMealDay(dayKey);

        setEventMeal((prevState) => {
            // Only allow one day in the time_table.days array
            const updatedDays = [{ [dayKey]: dayName, 'all-day': false, notes: "" }];

            console.log("updatedDays", JSON.stringify(updatedDays, null, '\t'));

            // Fetch the global time_table dates
            const globalDates = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates || [];

            // Find a matching `dates` entry in the global time_table
            const matchingDate = globalDates.find((dateEntry) => {
                const correspondingDay = dateEntry.dayOfWeek.toLowerCase();
                return correspondingDay === dayName.toLowerCase();
            });

            console.log("Matching date found:", matchingDate);

            // If a matching date is found, add it to the meal's `time_table.dates`
            const updatedDates = matchingDate
                ? [
                    {
                        date: matchingDate.date,
                        dayOfWeek: matchingDate.dayOfWeek,
                        'all-day': matchingDate['all-day'] || false,
                        notes: matchingDate.notes || "",
                    },
                ]
                : [];

            console.log("updatedDates", JSON.stringify(updatedDates, null, '\t'));

            // Return the updated state with the new day and date
            return {
                ...prevState,
                time_table: {
                    ...prevState.time_table,
                    days: updatedDays, // Replace with the new selected day
                    dates: updatedDates, // Add the matching date if found
                },
            };
        });
    };


    const renderMealDaysLayout = () => {
        const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]; // Week layout starting with Sunday

        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName.slice(0, 2)); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayKey === selectedMealDay // Set the selected status based on the selectedMealDay
            });
        });

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name.slice(0, 2));
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '5px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{day.name.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: day.isSelected
                                                ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                                : 'rgba(0, 123, 255, 0.2)',
                                        }}
                                        clickable
                                        onClick={() => handleMealDayChange(day.key, day.name)} // Pass full day object on click
                                    />
                                );
                            } else {
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };

    const handleMealDayTimeChange = (beginTime, endTime) => {
        // Update the time_table's days with the new time for the selected meal day
        setEventMeal(prevState => ({
            ...prevState,
            time_table: {
                ...prevState.time_table,
                days: prevState.time_table.days.map(day => ({
                    ...day,
                    'begin_time': beginTime ? dayjs(beginTime).format('h:mm A') : day['begin_time'],
                }))
            }
        }));
    };

    const handleDelete = () => {
        props.onDeleteMeal();  // Trigger the delete confirmation from MealList
    };

    function handleMealNameClick(mealName){
        setEventMeal(prevState => ({
            ...prevState,
            name: mealName
        }));
    }

    function getFormattedHours(openingHours, timeTable) {
        if (!timeTable || !timeTable.dates || timeTable.dates.length === 0) return '';

        // Get the day from the time_table (assuming time_table.dates contains the selected date)
        const selectedDate = dayjs(timeTable.dates[0].date, 'YYYY-MM-DD');
        const selectedDay = selectedDate.day(); // Returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday

        // Find the correct opening period for the selected day
        const period = openingHours.periods?.find(p => p.open.day === selectedDay);

        if (!period) return 'Closed'; // If no period is found, consider the place closed.

        // Function to format times with 'Noon' or 'Midnight'
        const formatTime = (timeStr) => {
            if (timeStr === '12:00 AM') return 'Midnight';
            if (timeStr === '12:00 PM') return 'Noon';
            return timeStr;
        };

        // Format the open and close times, and replace "12:00 AM/PM" with "Midnight" or "Noon"
        const openTime = formatTime(dayjs(period.open.time, 'HHmm').format('h:mm A'));
        const closeTime = formatTime(dayjs(period.close.time, 'HHmm').format('h:mm A'));

        // Return the formatted open and close hours (e.g., "11:00 AM - Midnight")
        return `${openTime} – ${closeTime}`;
    }

    const [openHoursDialog, setOpenHoursDialog] = useState(false);
    const handleClickOpenHoursDialog = () => {
        setOpenHoursDialog(true);
    };
    const handleCloseHoursDialog = () => {
        setOpenHoursDialog(false);
    };
    function HoursDialog({ open, handleClose, openingHours }) {
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Opening Hours</DialogTitle>
                <DialogContent>
                    {openingHours.weekday_text?.map((text, index) => (
                        <Typography key={index} variant="body1">
                            {text.replace('12:00 AM', 'Midnight').replace('12:00 PM', 'Noon')}
                        </Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }


    return (


        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >

                {/* add any photos if they exist */}
                {
                    eventMeal['photos'] && eventMeal['photos'].length >= 0 ? (
                        <div className="meals-suggestions-add-list-item-photos">
                            {
                                eventMeal['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }

                <CardHeader
                    title={(() => {
                        const { time_table } = eventMeal;

                        // Check if the time_table has dates and handle the dates case
                        if (time_table?.dates?.length > 0) {
                            const mealTime = time_table.dates[0].begin_time;
                            // If meal_time is not empty, format it, otherwise show only the date
                            return mealTime
                                ? `${dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('dddd')} ${dayjs(mealTime, 'HH:mm A').format('h:mm A')}`
                                : `${dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('dddd')}`;
                        }

                        // Check if the time_table has days and handle the days case
                        else if (time_table?.days?.length > 0) {
                            const mealTime = time_table.days[0].begin_time;
                            const dayKey = Object.keys(time_table.days[0])[0]; // e.g., 'day 1', 'day 2'

                            // Replace the day of the week with "Day X"
                            const dayNumber = dayKey.charAt(0).toUpperCase() + dayKey.slice(1); // Format as 'Day X'

                            // If meal_time is not empty, format it, otherwise show only the "Day X"
                            return mealTime
                                ? `${dayNumber} ${dayjs(mealTime, 'HH:mm A').format('h:mm A')}`
                                : dayNumber;
                        }

                        return ''; // Return an empty string if no dates or days are available
                    })()}

                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleMealListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{paddingBottom:'0'}}
                    titleTypographyProps={{ fontSize: '18px' }} // Set the font size here
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorMealListItemMenu}
                    id="meal-item-menu"
                    open={Boolean(anchorMealListItemMenu)}
                    onClose={handleMealListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="delete"
                            onClick={handleDelete}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '0 16px 16px 16px' }}>
                    <Typography variant="body2" color="textSecondary">
                        {eventMeal['name'].toUpperCase()}
                    </Typography>
                </Box>


                <CardContent sx={{ paddingTop: '0' }}>
                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                        {
                            (() => {
                                const { time_table } = eventMeal;

                                if (time_table?.dates?.length > 0) {
                                    return `${dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('D MMM')}`;
                                } else if (time_table?.days?.length > 0) {
                                    const mealTime = time_table.days[0].begin_time;
                                    // Get the day of the week from the value (e.g., 'Thursday' instead of 'day 2')
                                    const dayOfWeek = time_table.days[0][Object.keys(time_table.days[0])[0]]; // Extract the day of the week
                                    // If meal_time is not empty, format it, otherwise show only the day of the week
                                    return mealTime
                                        ? `${dayOfWeek} ${dayjs(mealTime, 'HH:mm A').format('h:mm A')}`
                                        : dayOfWeek;
                                }
                                return ''; // Return an empty string if no dates or days are available
                            })()
                        }
                    </Typography>
                    {
                        eventMeal['location'].name ? (
                            <>
                                <Typography variant="h6" sx={{ fontSize:'16px' }} color="text.secondary">
                                    {eventMeal['location'].name}
                                </Typography>
                                <Typography sx={{ fontSize:'13px' }} color="text.secondary">
                                    {eventMeal['location'].address}
                                </Typography>
                                <Typography sx={{ fontSize:'13px' }} color="text.secondary">
                                    {eventMeal['location'].city} {eventMeal['location'].state} {eventMeal['location'].zip}
                                </Typography>
                                <Typography sx={{ mb: 1.5, fontSize:'13px' }} color="text.secondary">
                                    {eventMeal['phone']}
                                </Typography>
                                <Typography
                                    sx={{
                                        mb: 1.5,
                                        fontSize: '13px',
                                        display: 'flex',
                                        justifyContent: 'space-between', // Space between left and right items
                                        alignItems: 'center'
                                    }}
                                    color="text.secondary"
                                >
                                    {/* Left side: Website (only if available) */}
                                    {
                                        eventMeal['Place'].website ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className="icon-earth" title="Website"
                                                   style={{ marginRight: '10px', fontSize: '20px' }}
                                                />
                                                <a
                                                    href={eventMeal['Place'].website}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: 'inherit', textDecoration: 'none' }} // Optional: Keep the styling consistent
                                                >
                                                    Website
                                                </a>
                                            </div>
                                        ) : (
                                            <div /> // Placeholder if website is undefined to keep layout consistent
                                        )
                                    }

                                    {/* Right side: Hours (show even if website is undefined) */}
                                    {
                                        eventMeal['Place'].opening_hours && eventMeal['time_table'] && (
                                            <div
                                                style={{cursor: 'pointer', color: '#1976d2'}}
                                                onClick={handleClickOpenHoursDialog} // Open dialog on click
                                            >
                                                {getFormattedHours(eventMeal['Place'].opening_hours, eventMeal['time_table'])}
                                            </div>
                                        )
                                    }
                                </Typography>

                                {/* Dialog to display all hours */}
                                {
                                    eventMeal['Place'].opening_hours && (
                                        <HoursDialog
                                            open={openHoursDialog}
                                            handleClose={handleCloseHoursDialog}
                                            openingHours={eventMeal['Place'].opening_hours}
                                        />
                                    )
                                }
                            </>

                        ):(
                            <></>
                        )
                    }
                    {
                        eventMeal["eatery_notes"] ? (
                            <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                Notes : {eventMeal['eatery_notes']}
                            </Typography>
                        ) : (
                            <></>
                        )
                    }
                    {
                        eventMeal["eat_in_details"] ? (
                            <Typography
                                sx={{ mb: 1.5, fontSize:'12px' }}
                                color="text.secondary"
                                dangerouslySetInnerHTML={{ __html: eventMeal['eat_in_details'].replace(/\n/g, '<br />') }}
                            />
                        ) : (
                            <></>
                        )
                    }

                </CardContent>

                <CardActions>
                    <div className="meals-suggestions-add-list-item-footer-container">
                        {/* add the meal date */}
                        <div
                            style={{
                                color: '#f09637',
                                backgroundColor: mealTabSelected && selectedAction === "DateTime" ? '#EDEDED' : 'initial'
                            }}
                            className="meals-suggestions-add-list-item-footer-dates-container"
                            onClick={() => handleFooterItemClick('DateTime')}
                        >
                            <i className="icon-calendar3" title="Date and Time"/>
                        </div>
                        {/* add the meal location */}
                        <div
                            style={{
                                color: '#f00',
                                backgroundColor: mealTabSelected && selectedAction === "Location" ? '#EDEDED' : 'initial'
                            }}
                            className="meals-suggestions-add-list-item-footer-location-container"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <i className="icon-map-marker" title="Location"/>
                        </div>
                        {/* add the meal participants */}
                        <div
                            style={{
                                color: '#f09637',
                                backgroundColor: mealTabSelected && selectedAction === "Participants" ? '#EDEDED' : 'initial'
                            }}
                            className="meals-suggestions-add-list-item-footer-attendees-container"
                            onClick={() => handleFooterItemClick('Participants')}
                        >
                            <i className="icon-user-24" title="Attendees"/>
                        </div>
                        {/* add the meal costs */}
                        <div
                            style={{
                                color: '#f09637',
                                backgroundColor: mealTabSelected && selectedAction === "Costs" ? '#EDEDED' : 'initial'
                            }}
                            className="meals-suggestions-add-list-item-footer-costs-container"
                            onClick={()=>handleFooterItemClick('Costs')}
                        >
                            <i className="icon-wallet"  title="Costs"/>
                        </div>
                        {/* add the meal details */}
                        <div
                            style={{
                                color: '#f00',
                                backgroundColor: mealTabSelected && selectedAction === "Details" ? '#EDEDED' : 'initial'
                            }}
                            className="meals-suggestions-add-list-item-footer-details-container"
                            onClick={() => handleFooterItemClick('Details')}
                        >
                            <i className="icon-settings-icons" title="Details"/>
                        </div>
                    </div>
                </CardActions>

                <CardContent sx={{padding:'20px 0', display:mealTabSelected?'block':'none'}}>
                    {
                        selectedAction === "DateTime" ? (
                            <Box className="meals-suggestions-add-list-item-footer-date-holder">

                                <Grid item sx={{maxWidth: '100%'}} >
                                    <TabContext sx={{ position:'relative'}} value={tabValue}>
                                        <TabList
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                                backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                                backdropFilter: 'blur(6px)',
                                            }}
                                            centered
                                            variant="fullWidth"
                                            onChange={handleTabChange}
                                            aria-label="Meal Date / Day"
                                        >
                                            <Tab
                                                label="Date"
                                                value="dates"
                                            />
                                            <Tab
                                                label="Day"
                                                value="days"
                                            />
                                        </TabList>

                                        <TabPanel value="dates" sx={{ padding: '24px 0' }}>
                                            <div
                                                className="meals-suggestions-add-list-item-footer-date"
                                            >
                                                Meal Date :&nbsp;
                                                {
                                                    (() => {
                                                        const { time_table } = eventMeal;

                                                        // Check for time_table.dates first
                                                        if (time_table?.dates?.length > 0) {
                                                            return dayjs(time_table.dates[0].date, 'YYYY-MM-DD').format('D MMM, YYYY');
                                                        }

                                                        // If neither, display nothing
                                                        return '';
                                                    })()
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectMealDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowMealDateCalendar}
                                                />
                                            </div>
                                            <div
                                                className="meals-suggestions-add-list-item-footer-date-input"
                                                style={{display: showCalendarSelectMealDate ? 'block' : 'none'}}>
                                                <DateCalendar
                                                    value={
                                                        dayjs(eventMeal['time_table'].dates[0]?.date, 'YYYY-MM-DD').isValid() ? dayjs(eventMeal['time_table'].dates[0]?.date, 'YYYY-MM-DD') : dayjs()
                                                    }
                                                    onChange={handleMealDateChange}
                                                    disablePast={false}
                                                    renderLoading={() => <Typography>Loading Calendar...</Typography>}
                                                />
                                            </div>

                                            {/* for the meal time */}
                                            <div
                                                className="meals-suggestions-add-list-item-footer-time"
                                            >
                                                Meal Time :&nbsp;
                                                {
                                                    (() => {
                                                        const { time_table } = eventMeal;

                                                        // Check for time_table.dates first
                                                        if (time_table?.dates?.length > 0) {
                                                            return time_table.dates[0].begin_time;
                                                        }

                                                        // If neither, display nothing
                                                        return '';
                                                    })()
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectMealDateTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowMealDateTime}
                                                />
                                            </div>
                                            <div
                                                className="meals-suggestions-add-list-item-footer-time-input"
                                                style={{display: showClockSelectMealDateTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="Meal Time"
                                                    value={dayjs(eventMeal['time_table'].dates[0]?.begin_time, 'h:mm A').isValid() ? dayjs(eventMeal['time_table'].dates[0]?.begin_time, 'h:mm A') : null}
                                                    onChange={handleMealDateTimeChange}
                                                />
                                            </div>

                                        </TabPanel>

                                        <TabPanel value="days" sx={{ padding: '24px 0' }}>

                                            <div
                                                className="meals-suggestions-add-list-item-footer-date"
                                            >
                                                Meal Day :&nbsp;
                                                {
                                                    (() => {
                                                        const {time_table} = eventMeal;

                                                        // If no dates, check for time_table.days
                                                        if (time_table?.days?.length > 0) {
                                                            return time_table.days[0][Object.keys(time_table.days[0])[0]]; // Display day (e.g., 'Day 1')
                                                        }

                                                        // If neither, display nothing
                                                        return '';
                                                    })()
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showSelectMealDay ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowMealDaySelect}
                                                />
                                            </div>

                                            <div
                                                className="meals-suggestions-add-list-item-footer-date-input"
                                                style={{display: showSelectMealDay ? 'block' : 'none'}}
                                            >

                                                {
                                                    renderMealDaysLayout()
                                                }

                                            </div>


                                            {/* for the meal time */}
                                            <div
                                                className="meals-suggestions-add-list-item-footer-time"
                                            >
                                                Meal Time :&nbsp;
                                                {
                                                    (() => {
                                                        const { time_table } = eventMeal;

                                                        // Check for time_table.dates first
                                                        if (time_table?.days?.length > 0) {
                                                            return time_table.days[0].begin_time;
                                                        }

                                                        // If neither, display nothing
                                                        return '';
                                                    })()
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectMealDayTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowMealDayTime}
                                                />
                                            </div>
                                            <div
                                                className="meals-suggestions-add-list-item-footer-time-input"
                                                style={{display: showClockSelectMealDayTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="Meal Time"
                                                    value={dayjs(eventMeal['time_table'].days[0]?.begin_time, 'h:mm A')}
                                                    onChange={handleMealDayTimeChange}
                                                />
                                            </div>

                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                                {/* add the icons to choose the meal  */}
                                <Grid item sx={{ display: 'flex', maxWidth: '100%', overflowX: 'auto' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',        // Horizontal flex layout
                                            flexDirection: 'row',   // Arrange items horizontally
                                            overflowX: 'auto',      // Enable horizontal scrolling
                                            whiteSpace: 'nowrap',   // Prevent wrapping to the next line
                                            gap: '16px',            // Space between the buttons
                                            padding: '10px',        // Optional: padding around the container
                                        }}
                                    >
                                        <IconButton
                                            title="Breakfast"
                                            sx={{
                                                display: 'flex',             // Make the IconButton a flex container
                                                flexDirection: 'column',      // Stack items vertically
                                                justifyContent: 'center',     // Center items vertically
                                                alignItems: 'center',         // Center items horizontally
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: eventMeal['name'] === 'breakfast' ? '#fef4ec' : '',
                                                border: eventMeal['name'] === 'breakfast' ? '1px solid #fccca5' : '0',
                                                color: '#f09637',
                                                cursor: 'pointer',
                                                padding: '10px' // Optional padding to give some space inside the button
                                            }}
                                            onClick={() => handleMealNameClick("breakfast")}
                                        >
                                            {<i className="icon-fried-egg"/>}
                                            <Typography variant="caption">Breakfast</Typography>
                                        </IconButton>
                                        <IconButton
                                            title="Brunch"
                                            sx={{
                                                display: 'flex',             // Make the IconButton a flex container
                                                flexDirection: 'column',      // Stack items vertically
                                                justifyContent: 'center',     // Center items vertically
                                                alignItems: 'center',         // Center items horizontally
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: eventMeal['name'] === 'brunch' ? '#fef4ec' : '',
                                                border: eventMeal['name'] === 'brunch' ? '1px solid #fccca5' : '0',
                                                color: '#f09637',
                                                cursor: 'pointer',
                                                padding: '10px' // Optional padding to give some space inside the button
                                            }}
                                            onClick={() => handleMealNameClick("brunch")}
                                        >
                                            {<i className="icon-toast"/>}
                                            <Typography variant="caption">Brunch</Typography>
                                        </IconButton>
                                        <IconButton
                                            title="Lunch"
                                            sx={{
                                                display: 'flex',             // Make the IconButton a flex container
                                                flexDirection: 'column',      // Stack items vertically
                                                justifyContent: 'center',     // Center items vertically
                                                alignItems: 'center',         // Center items horizontally
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: eventMeal['name'] === 'lunch' ? '#fef4ec' : '',
                                                border: eventMeal['name'] === 'lunch' ? '1px solid #fccca5' : '0',
                                                color: '#f09637',
                                                cursor: 'pointer',
                                                padding: '10px' // Optional padding to give some space inside the button
                                            }}
                                            onClick={() => handleMealNameClick("lunch")}
                                        >
                                            {<i className="icon-burger"/>}
                                            <Typography variant="caption">Lunch</Typography>
                                        </IconButton>
                                        <IconButton
                                            title="Snack"
                                            sx={{
                                                display: 'flex',             // Make the IconButton a flex container
                                                flexDirection: 'column',      // Stack items vertically
                                                justifyContent: 'center',     // Center items vertically
                                                alignItems: 'center',         // Center items horizontally
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: eventMeal['name'] === 'snack' ? '#fef4ec' : '',
                                                border: eventMeal['name'] === 'snack' ? '1px solid #fccca5' : '0',
                                                color: '#f09637',
                                                cursor: 'pointer',
                                                padding: '10px' // Optional padding to give some space inside the button
                                            }}
                                            onClick={() => handleMealNameClick("snack")}
                                        >
                                            {<i className="icon-apple"/>}
                                            <Typography variant="caption">Snack</Typography>
                                        </IconButton>
                                        <IconButton
                                            title="Dinner"
                                            sx={{
                                                display: 'flex',             // Make the IconButton a flex container
                                                flexDirection: 'column',      // Stack items vertically
                                                justifyContent: 'center',     // Center items vertically
                                                alignItems: 'center',         // Center items horizontally
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: eventMeal['name'] === 'dinner' ? '#fef4ec' : '',
                                                border: eventMeal['name'] === 'dinner' ? '1px solid #fccca5' : '0',
                                                color: '#f09637',
                                                cursor: 'pointer',
                                                padding: '10px' // Optional padding to give some space inside the button
                                            }}
                                            onClick={() => handleMealNameClick("dinner")}
                                        >
                                            {<i className="icon-platter"/>}
                                            <Typography variant="caption">Dinner</Typography>
                                        </IconButton>
                                        <IconButton
                                            title="Dessert"
                                            sx={{
                                                display: 'flex',             // Make the IconButton a flex container
                                                flexDirection: 'column',      // Stack items vertically
                                                justifyContent: 'center',     // Center items vertically
                                                alignItems: 'center',         // Center items horizontally
                                                boxShadow: 2,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: eventMeal['name'] === 'dessert' ? '#fef4ec' : '',
                                                border: eventMeal['name'] === 'dessert' ? '1px solid #fccca5' : '0',
                                                color: '#f09637',
                                                cursor: 'pointer',
                                                padding: '10px' // Optional padding to give some space inside the button
                                            }}
                                            onClick={() => handleMealNameClick("dessert")}
                                        >
                                            {<i className="icon-ice-cream"/>}
                                            <Typography variant="caption">Dessert</Typography>
                                        </IconButton>
                                    </Box>
                                </Grid>

                            </Box>

                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="meals-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{marginTop: '0'}}>
                                    <MealLocationTab
                                        eventMeal={eventMeal}
                                    />
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Participants" && (
                            <List dense sx={{ width: '100%' }}>
                                {selectedAttendeesArray
                                    .sort((a, b) =>
                                        `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                            `${formatName(b.lname)}, ${formatName(b.fname)}`
                                        )
                                    )
                                    .map((_attendee, index) => (
                                        <div key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleAttendeesToggle(_attendee.id)}
                                                        checked={eventMeal['attendees'].some(_att => _att.id === _attendee.id)}
                                                        inputProps={{ 'aria-labelledby': index }}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                            sx={{
                                                                boxShadow: '' +
                                                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                borderRadius: '10px',
                                                                textAlign: 'center',
                                                                fontWeight: '700',
                                                                backgroundColor: '#FFF',
                                                                color: 'black',
                                                                fontSize: '15px',
                                                                width: '30px',
                                                                height: '30px',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={index}
                                                        primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    ))
                                }

                                {/* Optional autocomplete for adding attendees */}
                                <Box mt={6} mb={1}>
                                    <Divider />
                                </Box>

                                {/* Autocomplete for adding attendees */}
                                <Autocomplete
                                    multiple
                                    options={attendeesReferenceArray.filter(
                                        (_attendee) => !selectedAttendeesArray.some((selected) => selected.id === _attendee.id)
                                    )}
                                    getOptionLabel={(option) => `${formatName(option.lname)}, ${formatName(option.fname)}`}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    {...stringAvatar(`${formatName(option.fname)}, ${formatName(option.lname)}`)}
                                                    sx={{
                                                        boxShadow: '' +
                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                        border: `1px solid ${stringToColor(`${formatName(option.fname)}, ${formatName(option.lname)}`)}`,
                                                        borderRadius: '10px',
                                                        textAlign: 'center',
                                                        fontWeight: '700',
                                                        backgroundColor: '#FFF',
                                                        color: 'black',
                                                        fontSize: '15px',
                                                        width: '30px',
                                                        height: '30px',
                                                    }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={`${formatName(option.lname)}, ${formatName(option.fname)}`} />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Add Attendees"
                                            placeholder="Search Attendees"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        newValue.forEach((newAttendee) => {
                                            handleAttendeesToggle(newAttendee.id)();
                                        });
                                    }}
                                />
                            </List>
                        )
                    }
                    {
                        selectedAction === "Costs" ? (
                            <div className="meals-suggestions-add-list-item-footer-costs-holder">
                                {/* Cost MealSummary */}
                                <Typography
                                    variant="body2"
                                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    Cost Summary
                                    {/* show the receipt icon if there is a receipt linked to this Meal */}
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning['receipts'].some(_receipt => _receipt.section_item === eventMeal.id) && (
                                            <i className='icon-receipt' />
                                        )
                                    }
                                </Typography>
                                <div
                                    className="meals-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="meals-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventMeal['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventMeal['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventMeal['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="meals-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="meals-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            type="number"
                                            label="Meal Total"
                                            value={eventMeal["amount_total"]}
                                            onChange={handleCostOverride}
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_meal is the Assignment area */}
                                <div className="meals-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.length === 0 ? (
                                            <div
                                                className="meals-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="meals-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_meal is the toggle section */}
                                                <div className="meals-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventMeal['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventMeal['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_meal is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventMeal['attendees']
                                                            .sort((a, b) => {
                                                                const _a = attendeesReferenceArray.find((_att) => _att.id === a.id) || { lname: '', fname: '' }; // Default to empty attendee
                                                                const _b = attendeesReferenceArray.find((_att) => _att.id === b.id) || { lname: '', fname: '' };

                                                                // Safeguard against undefined lname
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => {
                                                                const attendee = attendeesReferenceArray.find((_att) => _att.id === _attendee.id);

                                                                return (
                                                                    <div
                                                                        className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                        key={index}
                                                                    >
                                                                        <ListItem
                                                                            className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            secondaryAction={
                                                                                eventMeal['assign_equal'] ? (
                                                                                    <span
                                                                                        className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost"
                                                                                        style={{right:'5px'}}
                                                                                    >
                                                                                        {_attendee.cost}&nbsp;{eventMeal['currency']}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                        <TextField
                                                                                            label={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                                                                            value={_attendee.cost}
                                                                                            onChange={(e) => handleCustomAttendeeCost(_attendee.id, e)}
                                                                                            variant="standard"
                                                                                        />
                                                                                    </span>
                                                                                )
                                                                            }
                                                                            disablePadding
                                                                        >
                                                                            <ListItemAvatar>
                                                                                {attendee && (
                                                                                    <Avatar
                                                                                        {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                                                        sx={{
                                                                                            boxShadow: '' +
                                                                                                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                                                '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                                            border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                                                            borderRadius: '10px',
                                                                                            textAlign: 'center',
                                                                                            fontWeight: '700',
                                                                                            backgroundColor: '#FFF',
                                                                                            color: 'black',
                                                                                            fontSize: '15px',
                                                                                            width: '30px',
                                                                                            height: '30px'
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </ListItemAvatar>

                                                                            <ListItemText
                                                                                sx={{display: eventMeal['assign_equal'] ? 'block' : 'none'}}
                                                                                id={index}
                                                                                primary={attendee ? `${formatName(attendee.lname)}, ${formatName(attendee.fname)}` : ''}
                                                                            />
                                                                        </ListItem>


                                                                        {/* Business Expense Section */}
                                                                        <div
                                                                            className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Checkbox
                                                                                    checked={_attendee.is_business_expense || false}
                                                                                    onChange={(e) => handleBusinessExpenseToggle(_attendee.id, e.target.checked)}
                                                                                />
                                                                                <span style={{ marginLeft: '8px' }}>Business Expense</span>
                                                                            </div>

                                                                            {editModeMap[_attendee.id] ? (
                                                                                <TextField
                                                                                    label="Amount"
                                                                                    value={_attendee.business_expense_amount || 0.00}
                                                                                    onChange={(e) => handleBusinessExpenseAmountChange(_attendee.id, e.target.value)}
                                                                                    variant="standard"
                                                                                    onFocus={() => handleBusinessExpenseEnableOnEdit(_attendee.id)}
                                                                                />
                                                                            ) : (
                                                                                <span
                                                                                    className="meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost"
                                                                                >
                                                                                    {parseFloat(_attendee.business_expense_amount || 0).toFixed(2)}&nbsp;{eventMeal['currency']}
                                                                                </span>
                                                                            )}

                                                                            <Chip
                                                                                sx={editChipStyle}
                                                                                label={editModeMap[_attendee.id] ? 'Done' : 'Edit'}
                                                                                size="small"
                                                                                onClick={() => handleBusinessExpenseAmountEdit(_attendee.id)}
                                                                            />
                                                                        </div>

                                                                        {/* Divider */}
                                                                        <Divider/>

                                                                    </div>

                                                                );
                                                            })
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div
                                                    className="meals-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{mb: 1.5, flex: '1'}} variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span
                                                        className="meals-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventMeal['amount_total']) - eventMeal['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventMeal['currency']
                                                        }
                                                    </span>

                                                </div>

                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_booked_through"
                                                label="Booked Through"
                                                value={eventMeal['booked_through']}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_confirmation_number"
                                                label="Confirmation Number"
                                                value={eventMeal['confirmation_number']}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_name"
                                                label="Reservation Name"
                                                value={eventMeal['reservation_name']}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_number"
                                                label="Reservation Number"
                                                value={eventMeal['reservation_number']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_website"
                                                label="Website"
                                                value={eventMeal['website']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_bring"
                                                label="Bring"
                                                multiline
                                                rows={4}
                                                value={eventMeal['bring']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_details"
                                                label="Details"
                                                multiline
                                                rows={4}
                                                value={eventMeal['details']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={eventMeal['notes']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                </CardContent>

            </Card>
        </div>
    )
}

export default MealCard;

