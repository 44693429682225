import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Card, CardHeader, Chip} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import PAEvent from "../../../MasterEventObjects/PAEvent";
import "./attendees.css";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import {addWaitGradientStyle} from "../../ButtonStyle/AddWait";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, stringAvatar, stringToColor} from "../../Helpers/TextStyling";


function AttendeesAddPrevious() {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Attendees } = dataArray;

    const [sortedAttendees, setSortedAttendees] = useState([]);
    const [selectedAttendees, setSelectedAttendees] = useState([]);

    useEffect(() => {
        // Sort the attendees by lname whenever the Attendees array changes

        try {
            //only show Attendees that aren't already in the Event
            setSortedAttendees([...Attendees]
                .filter((_att) => {
                    return !PA_GlobalObject.Planning.attendees.some((planningAtt) => planningAtt.id === _att.id );
                })
                .sort((attendeeA, attendeeB) => {
                    const lnameA = attendeeA.lname.toLowerCase();
                    const lnameB = attendeeB.lname.toLowerCase();

                    // Compare the lname values
                    if (lnameA < lnameB) return -1;
                    if (lnameA > lnameB) return 1;
                    return 0;
                }));
        } catch (e){
            console.log(e);
        }

    }, [Attendees]);

    function toggleAttendeeSelection(attendee_id){
        console.log("Clicked on index : "+attendee_id);

        // Check if the attendee is already selected
        const isSelected = selectedAttendees.some((selected) => selected.id === attendee_id);

        console.log("Selected ? : "+isSelected);

        if (isSelected) {
            // If selected, remove from the selectedAttendees array
            setSelectedAttendees(selectedAttendees.filter((selected) => selected.id !== attendee_id));
        } else {
            // If not selected, add to the selectedAttendees array
            setSelectedAttendees([...selectedAttendees, sortedAttendees.find((_att) => _att.id === attendee_id)]);
        }

    }

    function handleAttendeesOK() {
        console.log("handleAttendeesOK");

        // Ensure selectedAttendees is not empty
        if (!selectedAttendees || selectedAttendees.length === 0) {
            console.log("No attendees selected.");
            return;
        }

        console.log("Selected Attendees: ", selectedAttendees);

        // Filter out attendees that already exist in the Planning attendees list
        const existingAttendeeIds = PA_GlobalObject.Planning.attendees.map((att) => att.id);
        const newAttendees = selectedAttendees
            .filter((_att) => !existingAttendeeIds.includes(_att.id))
            .map((_att, index) => ({
                ..._att,
                balance: Math.floor(Math.random() * 2000), // Add random balance
                role: PA_GlobalObject.Planning.attendees.length === 0 && index === 0 ? "admin" : "guest", // Assign "admin" role to the first attendee
            }));

        console.log("New Attendees to Add: ", newAttendees);

        if (newAttendees.length > 0) {
            // Update the global object state with new attendees
            set_PA_GlobalObject((prev) => ({
                Planning: {
                    ...prev.Planning,
                    attendees: [...prev.Planning.attendees, ...newAttendees], // Merge new attendees
                },
            }));
        } else {
            console.log("No new attendees to add.");
        }

        // Navigate to the Attendees page
        navigate("/Planner/Attendees");
    }

    function handlePromptChangeAddPreviousClose() {
        navigate('/Planner/Attendees')
    }


    return (

        <div className="attendee-list-container" >
            {/* show the header */}
            <div
                className="attendee-list-header"
            >
                <div className="attendee-list-header-text">
                    <h5>Previous Attendees</h5>
                </div>
                <div className="attendee-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptChangeAddPreviousClose}
                    />
                    <Chip
                        sx={selectedAttendees.length===0 ? addWaitGradientStyle : addChipStyle}
                        label={selectedAttendees.length===0?'Add': 'Add '+selectedAttendees.length}
                        size="small"
                        onClick={selectedAttendees.length===0 ? undefined : handleAttendeesOK}
                    />
                </div>
            </div>


            {/* show the list of sample attendees */}
            <div className="attendee-list-items-container">

                <Grid container direction="column">

                {
                    sortedAttendees.map((attendee, index) => (

                        <Grid item key={index} sx={{display:'flex'}}>

                            <Card
                                sx={{cursor:'pointer'}}
                                className="attendee-list-item"
                                onClick={() => toggleAttendeeSelection(attendee.id)}
                            >
                                <CardHeader
                                    sx={{
                                        padding:0
                                    }}
                                    avatar={
                                        <Avatar
                                            {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                            sx={{
                                                boxShadow: '' +
                                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                borderRadius: '10px',
                                                textAlign: 'center',
                                                fontWeight: '700',
                                                backgroundColor: '#FFF',
                                                color: 'black', // Text color (adjust as needed)
                                                fontSize: '15px',
                                                width: 30, // Adjust width
                                                height: 30, // Adjust height
                                            }}
                                        />
                                    }
                                    title={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                    subheader={attendee.email}
                                    action={
                                        selectedAttendees.some((selected) => selected.id === attendee.id) ? (
                                            <i className="icon-check attendee-list-item-add-item-checkmark" />
                                        ) : (
                                            <i className="attendee-list-item-add-item-no-checkmark" />
                                        )
                                    }

                                />
                            </Card>

                        </Grid>
                    ))
                }
                </Grid>
            </div>

        </div>

    )
}

export default AttendeesAddPrevious;
