import React, { useEffect, useState } from 'react';
import { Tab, TextField, Checkbox, FormControlLabel, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from '@mui/material/Grid2';
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";

function ActivityLocationTab(props) {
    const [eventActivity, setEventActivity] = useState(props.eventActivity);
    const [selectedTab, setSelectedTab] = useState('find');
    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null); // Selected location object
    const [manualLocation, setManualLocation] = useState({
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: ''
    });

    // Update activity when props change
    useEffect(() => {
        setEventActivity(props.eventActivity);
    }, [props.eventActivity]);

    // Generate unique locations from event data
    useEffect(() => {
        const { meals, lodging, activities } = usePA_GlobalStore.getState().PA_GlobalObject.Planning || {};
        const locations = new Set();

        const addLocation = (location) => {
            if (location && location.city && location.state) {
                locations.add(`${location.name} | ${location.address} | ${location.city}, ${location.state}`);
            }
        };

        meals?.forEach((meal) => addLocation(meal.location));
        lodging?.forEach((lodging) => addLocation(lodging.location));
        activities?.forEach((activity) => addLocation(activity.location));

        setUniqueLocations([...locations]);
    }, []);

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Handle manual location input change
    const handleManualInputChange = (field, value) => {
        setManualLocation((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    // Handle location selection
    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
    };

    return (
        <Grid item style={{ flex: '1' }}>
            <TabContext value={selectedTab}>
                <TabList
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: 'rgba(251, 251, 251, 0.8)',
                        backdropFilter: 'blur(6px)',
                    }}
                    centered
                    variant="fullWidth"
                    onChange={handleTabChange}
                    aria-label="Activity Location"
                >
                    <Tab label="Find" value="find" />
                    <Tab label="Locations" value="locations" />
                </TabList>

                {/* Find Tab */}
                <TabPanel value="find" sx={{ padding: '24px 0' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Enter Location Details
                    </Typography>
                    <Grid container spacing={2}>
                        {['name', 'address', 'city', 'state', 'zip', 'country'].map((field) => (
                            <Grid item xs={12} sm={6} key={field}>
                                <TextField
                                    label={field.charAt(0).toUpperCase() + field.slice(1)}
                                    variant="outlined"
                                    fullWidth
                                    value={manualLocation[field]}
                                    onChange={(e) => handleManualInputChange(field, e.target.value)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>

                {/* Locations Tab */}
                <TabPanel value="locations" sx={{ padding: '24px 0' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Select from Existing Locations
                    </Typography>
                    <Grid container spacing={2}>
                        {uniqueLocations.map((location, index) => (
                            <Grid item xs={12} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedLocation === location}
                                            onChange={() => handleLocationSelect(location)}
                                        />
                                    }
                                    label={location}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
            </TabContext>
        </Grid>
    );
}

export default ActivityLocationTab;
