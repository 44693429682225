import React, {useContext, useEffect, useRef, useState} from 'react';

import {Card, CardContent, CardMedia, Chip, Grid, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";

import TodoCard from "./TodoCard";
import './todo.css';

import dayjs from "dayjs";
import Badge from "@mui/material/Badge";

import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";



function TodoList(props) {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Account, Planning } = dataArray;

    const [selectedAttendee, setSelectedAttendee] = useState(Account.id);
    const [eventTodos, setEventTodos] = useState([]);


    const [anchorTodosListMenu, setAnchorTodosListMenu] = useState(null);
    const handleTodosListMenuOpenMenu = (event) => {
        setAnchorTodosListMenu(event.currentTarget);
    };
    const handleTodosListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorTodosListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Todo/AddNew');
        }
    };
    const handleTodosListMenuClose = () => {
        setAnchorTodosListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    useEffect(() => {
        console.log("Planning.to_do : "+JSON.stringify(Planning.to_do, null, '\t'));

        if (Planning.to_do !== undefined) {
            setEventTodos([...Planning.to_do]);
        }

        if (Planning.to_do && Planning.to_do.length > 0) {
            for (const _to_do of Planning.to_do) {
                console.log("_to_do : "+JSON.stringify(_to_do, null, '\t'));
            }
        }
    }, [Planning.to_do]);

    const updateTodoDetails = (updatedTodo) => {
        // Find the index of the updated Idea in eventTodos
        const todoIndex = eventTodos.findIndex((todo) => todo.id === updatedTodo.id);

        if (todoIndex !== -1) {
            // If the to_do item is found, update it in the event to_do array
        }

    };


    const handleChipClick = (_attendee_id) => {
        console.log("Show todo items for : "+_attendee_id);

        setSelectedAttendee(_attendee_id);

        if (Planning.to_do !== undefined) {
            const filteredItems = Planning.to_do.filter(item => item.assigned_to === _attendee_id);

            console.log("filteredItems : "+JSON.stringify(filteredItems, null, '\t'));

            // Group the filtered items by category and subcategory


        }
    }

    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        return '';
    }


    return (
        <div className="todo-list-container">

            {/* show the header */}
            <div
                className="todo-list-header"
            >
                <div className="todo-list-header-text">
                    <h5>To-Do</h5>
                </div>
                <div className="todo-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize: '16px'}}/>
                        }
                        size="small"
                        onClick={handleTodosListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorTodosListMenu}
                        id="library-menu"
                        open={Boolean(anchorTodosListMenu)}
                        onClose={handleTodosListMenuClose}

                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList
                            dense
                            sx={{width: 320, maxWidth: '100%'}}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleTodosListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of attendees */}
            <div className="todo-list-attendee-list-container">
                {Planning.attendees && Planning.attendees.length > 0 ? (
                    Planning.attendees
                        .sort((a, b) => {
                            // Check if the current attendee is the user (you)
                            if (a.id === Account.id) return -1; // You should come first
                            if (b.id === Account.id) return 1;  // If not you, the other attendee comes after

                            // Otherwise, sort alphabetically by nickname
                            const nameA = capitalizeFirstLetter(a.nickname).toLowerCase();
                            const nameB = capitalizeFirstLetter(b.nickname).toLowerCase();
                            return nameA.localeCompare(nameB);
                        })
                        .map((_att, index) => {
                            const badgeCount = Planning.to_do?.reduce((count, item) => {
                                if (item.assigned_to.includes(_att.id)) {
                                    // Count the item if it's assigned to the attendee and either:
                                    // - The attendee is the current user
                                    // - The item is not private
                                    if (_att.id === Account.id || !item.private) {
                                        return count + 1;
                                    }
                                }
                                return count;
                            }, 0);

                            return (
                                <Badge
                                    key={index}
                                    badgeContent={badgeCount}
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: 'white',
                                            border: '1px solid #ddd',
                                            width: '16px',
                                            height: '16px',
                                            borderRadius: '50%',
                                            top: '10px',
                                            right: '10px',
                                        },
                                    }}
                                >
                                    <Chip
                                        sx={selectedAttendee === _att.id ? selectedChipStyle : unselectedChipStyle}
                                        label={capitalizeFirstLetter(_att.nickname)}
                                        clickable
                                        onClick={() => handleChipClick(_att.id)}
                                        style={{ margin: '0.5rem' }}
                                    />
                                </Badge>
                            );
                        })

                ) : (
                    <div style={{padding: '20px'}}>
                        <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                        <Typography variant="caption">Need to add some Attendees first</Typography>
                    </div>
                )}
            </div>


            {/* show the list of Todos */}
            <div className="todo-list-items-container">

                {
                    eventTodos && eventTodos.length > 0 ? (
                        eventTodos
                            .filter(item => item.assigned_to === selectedAttendee)
                            .sort((a, b) => {
                                // First, compare by schedule_date
                                const dateComparison = dayjs(a.schedule_date).diff(dayjs(b.schedule_date));

                                // If schedule_date is the same, compare by schedule_begin_time
                                if (dateComparison !== 0) {
                                    return dateComparison;
                                } else {
                                    return dayjs(a.schedule_begin_time, 'HH:mm A').diff(dayjs(b.schedule_begin_time, 'HH:mm A'));
                                }
                            })
                            .map((todoDetails, index) => (

                                <Grid item key={index} sx={{display: 'flex'}}>

                                    <TodoCard
                                        to_do={todoDetails}
                                        updateTodoDetails={updateTodoDetails}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item>
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 140}}
                                        image={lodgingHeaderImage}
                                        title="Add Todo Items"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Todo Item
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Lodgings:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels,
                                            Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal
                                            Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and
                                            let you see what we've
                                            found. You can edit the results before we actually add the details to the
                                            Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click
                                            on the
                                            <i className="icon-menu2"/> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact
                                            Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

        </div>
    )

}

export default TodoList;
