import React, {useEffect, useState} from 'react';

import {DateCalendar, DayCalendarSkeleton, PickersDay, TimePicker} from "@mui/x-date-pickers";
import {
    Chip,
    FormControl,
    InputLabel, MenuItem,
    Select,
    Switch,
    Tab,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import './datesTimes.css';
import Typography from "@mui/material/Typography";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import {updateChipStyle} from "../../ButtonStyle/Update";
import {daysOfWeekMap} from "../../Helpers/DatesAndTimes";
import {useLocation} from "react-router-dom";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";


// Extend dayjs to work with localized formats and weekdays
dayjs.extend(localizedFormat);
dayjs.extend(weekday);



function DatesTimesContent(props) {

    const location = useLocation();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    useEffect(() => {
        if (location.state?.showSidebar !== undefined && location.state.showSidebar) {
            props.setShowEventItemConfig(location.state.showSidebar);
        }
    }, [location.state]);

    const [eventTimetable, setEventTimetable] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);

    const [datesCount, setDatesCount] = useState(0);

    const [ eventDaysCount, setEventDaysCount ] = useState(0);
    const [ eventDaysStartingOnDay, setEventDaysStartingOnDay ] = useState('');

    const [tabValue, setTabValue] = useState("dates");

    const [ datesTimeOptionChecked, setDatesTimeOptionChecked ] = useState(false);
    const [ daysTimeOptionChecked, setDaysTimeOptionChecked ] = useState(false);

    const [datesBeginTimeValue, setDatesBeginTimeValue] = useState(null);
    const [datesEndTimeValue, setDatesEndTimeValue] = useState(null);
    const [daysBeginTimeValue, setDaysBeginTimeValue] = useState(null);
    const [daysEndTimeValue, setDaysEndTimeValue] = useState(null);



    useEffect(() => {

        if (usePA_GlobalStore.getState().PA_GlobalObject.Planning?.time_table) {

            // Populate selectedDates from the event's time_table.dates
            const eventDates = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.map(dateObj => dayjs(dateObj.date, 'YYYY-MM-DD'));
            setDatesCount(eventDates.length);
            setSelectedDates(eventDates);

            // Handle begin and end times for Dates
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.length > 0) {
                const firstDateObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates[0];
                const lastDateObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates[usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.dates.length - 1];

                // Set the begin and end times for Dates (use default if not available)
                const beginTime = dayjs(firstDateObj['begin-time'] || '12:00 AM', 'h:mm A');
                const endTime = dayjs(lastDateObj['end-time'] || '11:59 PM', 'h:mm A');

                setDatesBeginTimeValue(beginTime);
                setDatesEndTimeValue(endTime);

                // Check if the event is a full-day event (begin-time is 12:00 AM and end-time is 11:59 PM)
                const isFullDayEvent = beginTime.format('h:mm A') === '12:00 AM' && endTime.format('h:mm A') === '11:59 PM';

                // Set the time option toggle based on whether it's a full-day event or not
                setDatesTimeOptionChecked(!isFullDayEvent); // Disable toggle if it's a full-day event
            } else {
                setDatesTimeOptionChecked(false); // Set to false if no dates are present
            }

            // Populate selectedDays from the event's time_table.days (e.g., 'day 1', 'Wednesday')
            const eventDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.map(dayObj => {
                const dayKey = Object.keys(dayObj)[0];   // e.g., 'day 1', 'day 2'
                const dayValue = dayObj[dayKey];         // e.g., 'Wednesday', 'Thursday'

                return { key: dayKey, value: dayValue }; // Store both key and value
            });

            setSelectedDays(eventDays);
            setEventDaysCount(eventDays.length);

            if (eventDays.length > 0) {
                setEventDaysStartingOnDay(eventDays[0].value);

                // Handle begin and end times for Days
                const firstDayObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days[0];
                const lastDayObj = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days[usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table.days.length - 1];

                const beginTime = dayjs(firstDayObj['begin-time'] || '12:00 AM', 'h:mm A');
                const endTime = dayjs(lastDayObj['end-time'] || '11:59 PM', 'h:mm A');

                setDaysBeginTimeValue(beginTime);
                setDaysEndTimeValue(endTime);

                // Check if the event is a full-day event for days
                const isFullDayEventForDays = beginTime.format('h:mm A') === '12:00 AM' && endTime.format('h:mm A') === '11:59 PM';

                // Set the time option toggle based on whether it's a full-day event for days or not
                setDaysTimeOptionChecked(!isFullDayEventForDays); // Disable toggle if it's a full-day event
            } else {
                setDaysTimeOptionChecked(false); // Set to false if no days are present
            }

            console.log("Set the SelectedDates : " + JSON.stringify(eventDates));
            console.log("Set the SelectedDays : " + JSON.stringify(eventDays));
            console.log("############################");

        }

        /*
            this holds our initial values to compare against when changes are made
         */
        setEventTimetable(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table);

    }, [PA_GlobalObject.Planning.time_table]);

    function handlePromptChangeDatesClose() {
        props.setShowEventItemConfig(false);
    }

    const handleTabValueChange = (event, newValue) => {
        console.log("handleTabValueChange");
        setTabValue(newValue);
    };
    useEffect(() => {
        console.log("tabValue : "+tabValue);
    }, [tabValue]);

    function handleEventDaysCountChange(e){
        console.log("handleEventDaysCountChange : "+parseInt(e.target.value, 10));

        const newDaysCount = parseInt(e.target.value, 10);
        setEventDaysCount(newDaysCount);

        // Validate the input and ensure it's a number
        if (isNaN(newDaysCount) || newDaysCount <= 0) {
            return; // Don't update if the value is invalid or zero
        }

        console.log("eventDaysStartingOnDay : "+eventDaysStartingOnDay);

        // We know the starting day (e.g., "Wednesday", "Friday") from state: eventDaysStartingOnDay
        const startDayIndex = daysOfWeekMap[eventDaysStartingOnDay] ?? NaN; // Fallback to NaN if the day is invalid

        console.log("startDayIndex : "+startDayIndex);

        // Array to store the new selectedDays
        const newSelectedDays = [];

        // Generate the selected days based on the count and starting day
        for (let i = 0; i < newDaysCount; i++) {
            const dayIndex = (startDayIndex + i) % 7; // Wrap around if the index goes beyond 6 (Saturday)
            const dayName = dayjs().day(dayIndex).format('dddd'); // Get the full day name (e.g., "Wednesday")

            newSelectedDays.push({
                key: `day ${i + 1}`, // "Day 1", "Day 2", etc.
                value: dayName,       // Corresponding day name
            });
        }

        // Update the selectedDays array in state
        setSelectedDays(newSelectedDays);
    }
    function handleEventDaysStartingOnChange(event) {
        const newStartingDay = event.target.value; // Get the new starting day (e.g., "Wednesday")
        console.log("handleEventDaysStartingOnChange : "+newStartingDay);

        // We already have the eventDaysCount from the state (how many days the event lasts)
        const newSelectedDays = [];

        // Get the index of the new starting day
        const startDayIndex = daysOfWeekMap[newStartingDay] ?? NaN; // Fallback to NaN if the day is invalid

        // Loop through the number of days (eventDaysCount) and calculate the days
        for (let i = 0; i < eventDaysCount; i++) {
            const dayIndex = (startDayIndex + i) % 7; // Wrap around the days of the week (if needed)
            const dayName = dayjs().day(dayIndex).format('dddd'); // Get the day name for each day

            // Push the new day to the array
            newSelectedDays.push({
                key: `day ${i + 1}`, // "Day 1", "Day 2", etc.
                value: dayName,       // The actual day name (e.g., "Wednesday")
            });
        }

        // Update the state with the new selectedDays array and the new starting day
        setSelectedDays(newSelectedDays);
        setEventDaysStartingOnDay(newStartingDay);
    }

    function handleUpdatedDatesBeginTime(newValue){
        setDatesBeginTimeValue(newValue);
    }
    function handleUpdatedDatesEndTime(newValue){
        setDatesEndTimeValue(newValue);
    }
    function handleUpdatedDaysBeginTime(newValue){
        setDaysBeginTimeValue(newValue);
    }
    function handleUpdatedDaysEndTime(newValue){
        setDaysEndTimeValue(newValue);
    }

    useEffect(() => {
        console.log("the updated selectedDates : "+selectedDates);
    }, [selectedDates]);

    useEffect(() => {
        console.log("the updated selectedDays : ", selectedDays);
    }, [selectedDays]);


    function getMarkersForDate(date, _planning) {

        const markers = [];

        // Check for meals
        const mealDates = _planning.meals.flatMap((entry) => {
            const _time_table = entry.time_table;
            return _time_table.dates.map((item) => dayjs(item.date, "YYYY-MM-DD")); // Return dayjs objects
        });
        if (mealDates?.some((mealDate) => mealDate.isSame(date, "day"))) {
            markers.push("meal");
        }

        // Check for lodging
        const lodgingDates = _planning.lodging.flatMap((entry) => {
            const _time_table = entry.time_table;
            return _time_table.dates.map((item) => dayjs(item.date, "YYYY-MM-DD")); // Return dayjs objects
        });
        if (lodgingDates?.some((lodgingDate) => lodgingDate.isSame(date, "day"))) {
            markers.push("lodging");
        }

        // Check for activities
        const activityDates = _planning.activities.flatMap((entry) => {
            const _time_table = entry.time_table;
            return _time_table.dates.map((item) => dayjs(item.date, "YYYY-MM-DD")); // Return dayjs objects
        });
        if (activityDates?.some((activityDate) => activityDate.isSame(date, "day"))) {
            markers.push("activity");
        }

        return markers;
    }


    function isHighlighted(date, _planning) {
        // Check if the provided date exists in the selectedDates array
        return selectedDates.some(selectedDate => date.isSame(selectedDate, 'day'));
    }

    function ServerDay(props) {
        const { day, outsideCurrentMonth, planningData, ...other } = props;

        const isSelected = !outsideCurrentMonth && isHighlighted(dayjs(day));
        const markers = getMarkersForDate(dayjs(day), planningData); // Get markers for the current day

        return (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                onClick={() => handleDayClick(day)}
                sx={{
                    ...(isSelected
                        ? {
                            backgroundColor: "#1976d2", // Blue circle for selected
                            color: "white", // Ensure date text remains white
                            borderRadius: "50%",
                            "&:hover": {
                                backgroundColor: "#115293",
                            },
                        }
                        : {
                            color: outsideCurrentMonth ? "#ccc" : "inherit", // Default color for text
                        }),
                    position: "relative", // Allow absolute positioning for markers
                }}
            >

                <span>{day.format("D")}</span>

                {/* Render dots for markers */}
                {markers.length > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            top: "4px", // Position dots near the top
                            right: "4px", // Align dots to the right
                            display: "flex",
                            gap: "3px", // Space between dots
                        }}
                    >
                        {markers.map((marker, index) => (
                            <span
                                key={index}
                                style={{
                                    width: "5px",
                                    height: "5px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                        marker === "meal"
                                            ? "#FF6347" // Red for meals
                                            : marker === "lodging"
                                                ? "#32CD32" // Green for lodging
                                                : "#FFD700", // Yellow for activities
                                }}
                            ></span>
                        ))}
                    </div>
                )}
            </PickersDay>
        );
    }


    function ServerDay1(props) {
        const { day, outsideCurrentMonth, ...other } = props;

        const isSelected = !outsideCurrentMonth && isHighlighted(dayjs(day));

        return (
            <PickersDay
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                onClick={() => handleDayClick(day)}
                sx={isSelected ? {
                    backgroundColor: '#1976d2', // Blue circle
                    color: 'white',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: '#115293',
                    },
                } : {}}
            />
        );
    }

    const handleDayClick = (day) => {
        const clickedDate = dayjs(day);

        if (selectedDates.length === 0) {
            // Case 1: No dates selected yet, so the first click selects one date.
            setSelectedDates([clickedDate]);

        } else if (selectedDates.length === 1) {
            // Case 2: Second click selects a second date and fills in all the dates in between.
            const firstDate = selectedDates[0];

            if (clickedDate.isBefore(firstDate)) {
                // If the second clicked date is before the first, fill dates in reverse.
                const newDates = [];
                let currentDate = clickedDate;
                while (currentDate.isBefore(firstDate) || currentDate.isSame(firstDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);
            } else {
                // Otherwise, fill dates from the first date to the second clicked date.
                const newDates = [];
                let currentDate = firstDate;
                while (currentDate.isBefore(clickedDate) || currentDate.isSame(clickedDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);
            }

        } else {
            // Case 3: Third or subsequent click.
            const firstDate = selectedDates[0];
            const lastDate = selectedDates[selectedDates.length - 1];

            if (clickedDate.isBefore(firstDate)) {
                // If clicked before the first date, extend the range to the new earlier date.
                const newDates = [];
                let currentDate = clickedDate;
                while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);

            } else if (clickedDate.isAfter(lastDate)) {
                // If clicked after the last date, extend the range to the new later date.
                const newDates = [...selectedDates];
                let currentDate = lastDate.add(1, 'day');
                while (currentDate.isBefore(clickedDate) || currentDate.isSame(clickedDate, 'day')) {
                    newDates.push(currentDate);
                    currentDate = currentDate.add(1, 'day');
                }
                setSelectedDates(newDates);

            } else {
                // If clicked within the current range, toggle the range based on click count.
                const isOddClick = selectedDates.length % 2 !== 0;

                if (isOddClick) {
                    // Shrink the range from the beginning to the clicked date.
                    const newDates = selectedDates.filter(date => date.isSameOrBefore(clickedDate, 'day'));
                    setSelectedDates(newDates);
                } else {
                    // Extend the range from the clicked date to the last date.
                    const newDates = selectedDates.filter(date => date.isSameOrAfter(clickedDate, 'day'));
                    setSelectedDates(newDates);
                }
            }
        }
    };

    // Render custom days Chips layout
    const renderDaysLayout = () => {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Helper function to get the index of the day of the week (0-6, where 0 = Sunday)
        const getDayOfWeekIndex = (dayName) => {
            return daysOfWeek.indexOf(dayName.slice(0, 3)); // Use the first 3 letters to match the day
        };

        // Organize selectedDays into rows of 7 (representing a week)
        const weeks = [];
        let currentWeek = Array(7).fill(null); // Start with an empty week (7 slots)

        selectedDays.forEach((day) => {
            const dayKey = day.key; // e.g., "day 1"
            const dayName = day.value; // e.g., "Wednesday"
            const dayIndex = getDayOfWeekIndex(dayName); // Get the index for the day (0-6)

            // Place the day into the correct spot in the current week array
            currentWeek[dayIndex] = day;

            // If we reach the end of the week (Saturday), push the week and start a new one
            if (dayIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null); // Reset for the next week
            }
        });

        // If there are any leftover days in the currentWeek, push it as the last week
        if (currentWeek.some((day) => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '5px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                const dayKey = day.key; // e.g., "day 1"
                                const dayName = day.value; // e.g., "Wednesday"

                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{dayName.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{ width: '40px', height: '40px', backgroundColor: 'rgba(0, 123, 255, 0.2)' }}
                                    />
                                );
                            } else {
                                // Render an empty spot for days with no event
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };


    const renderTimePickers = (
        isChecked,             // Toggle for showing time pickers
        beginTimeValue,        // State for the begin time value
        endTimeValue,          // State for the end time value
        handleBeginTimeChange, // Handler for the begin time change
        handleEndTimeChange,   // Handler for the end time change
        prefix                 // A prefix to differentiate between 'days' or 'dates'
    ) => {
        if (!isChecked) return null; // If the switch is off, don't render the time pickers

        return (
            <>
                <Grid item key={`${prefix}_begin_time`} sx={{ marginBottom: '20px' }}>
                    <TimePicker
                        sx={{ width: '100%' }}
                        id={`${prefix}_begin_time_picker`} // Prefix to differentiate
                        label="Begin Time"
                        value={beginTimeValue}
                        onChange={handleBeginTimeChange} // Handles time changes
                    />
                </Grid>
                <Grid item key={`${prefix}_end_time`} sx={{ marginBottom: '20px' }}>
                    <TimePicker
                        sx={{ width: '100%' }}
                        id={`${prefix}_end_time_picker`}
                        label="End Time"
                        value={endTimeValue}
                        onChange={handleEndTimeChange} // Handles time changes
                    />
                </Grid>
            </>
        );
    };


    function buildUpdatedTimeTable() {
        const newTimeTable = new TimeTable();

        // Process selectedDates with begin and end times
        selectedDates.forEach((date) => {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            const dayOfWeek = dayjs(date).format('dddd');

            newTimeTable.dates.push({
                date: formattedDate,
                dayOfWeek: dayOfWeek,
                'all-day': datesBeginTimeValue !== null && datesEndTimeValue !== null, // Set all-day if true times are provided
                'begin_time': datesBeginTimeValue ? dayjs(datesBeginTimeValue).format('h:mm A') : null,
                'end_time': datesEndTimeValue ? dayjs(datesEndTimeValue).format('h:mm A') : null,
                notes: '',
            });
        });

        // Process selectedDays with begin and end times
        selectedDays.forEach((day) => {
            const dayKey = day.key; // e.g., 'day 1'
            const dayValue = day.value; // e.g., 'Wednesday'

            newTimeTable.days.push({
                [dayKey]: dayValue,
                'all-day': daysBeginTimeValue !== null && daysEndTimeValue !== null, // Set all-day if true times are provided
                'begin_time': daysBeginTimeValue ? dayjs(daysBeginTimeValue).format('h:mm A') : null,
                'end_time': daysEndTimeValue ? dayjs(daysEndTimeValue).format('h:mm A') : null,
                notes: '',
            });
        });

        // Populate days from dates if days are empty
        if (newTimeTable.days.length === 0 && newTimeTable.dates.length > 0) {
            newTimeTable.dates.forEach((dateEntry, index) => {
                newTimeTable.days.push({
                    [`day ${index + 1}`]: dateEntry.dayOfWeek,
                    'all-day': dateEntry['all-day'],
                    'begin_time': dateEntry['begin_time'],
                    'end_time': dateEntry['end_time'],
                    notes: '',
                });
            });
        }

        console.log("New TimeTable: ", newTimeTable);

        // Save the new time_table
        setEventTimetable(newTimeTable);

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                time_table: newTimeTable,
            },
        }));
    }

    function buildUpdatedTimeTableOLD() {
        const newTimeTable = new TimeTable();

        // Process selectedDates with begin and end times
        selectedDates.forEach((date) => {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            const dayOfWeek = dayjs(date).format('dddd');

            newTimeTable.dates.push({
                date: formattedDate,
                dayOfWeek: dayOfWeek,
                'all-day': datesBeginTimeValue !== null && datesEndTimeValue !== null, // Set all-day if true times are provided
                'begin-time': datesBeginTimeValue ? dayjs(datesBeginTimeValue).format('h:mm A') : null,
                'end-time': datesEndTimeValue ? dayjs(datesEndTimeValue).format('h:mm A') : null,
                notes: '',
            });
        });

        // Process selectedDays with begin and end times
        selectedDays.forEach((day) => {
            const dayKey = day.key;  // e.g., 'day 1'
            const dayValue = day.value; // e.g., 'Wednesday'

            newTimeTable.days.push({
                [dayKey]: dayValue,
                'all-day': daysBeginTimeValue !== null && daysEndTimeValue !== null, // Set all-day if true times are provided
                'begin-time': daysBeginTimeValue ? dayjs(daysBeginTimeValue).format('h:mm A') : null,
                'end-time': daysEndTimeValue ? dayjs(daysEndTimeValue).format('h:mm A') : null,
                notes: '',
            });
        });

        console.log("New TimeTable: ", newTimeTable);

        // Save the new time_table
        setEventTimetable(newTimeTable);

        set_PA_GlobalObject((prev) => ({
            Planning: {
                ...prev.Planning, // Retain all existing fields in Planning
                time_table: newTimeTable,
            },
        }));

    }

    function handlePromptChangeDatesOK() {
        console.log("handlePromptChangeDatesOK");

        // 1. Check if Dates or Days Have Changed
        const originalDates = eventTimetable.dates || [];
        const originalDays = eventTimetable.days || [];

        // Compare selectedDates (new dates) with original eventTimetable.dates
        const datesChanged = JSON.stringify(originalDates) !== JSON.stringify(selectedDates);

        // Compare selectedDays (new days) with original eventTimetable.days
        const daysChanged = JSON.stringify(originalDays) !== JSON.stringify(selectedDays);

        if (datesChanged || daysChanged) {
            console.log("Dates or Days have changed!");

            buildUpdatedTimeTable();

            // 2. Check for Lodging, Meals, Flights, and Activities outside the new range
            const dateRangeStart = dayjs(selectedDates[0]);
            const dateRangeEnd = dayjs(selectedDates[selectedDates.length - 1]);

            const outOfRangeLodging = usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging.filter(lodge => {
                const lodgeStart = dayjs(lodge.begin_date, 'MM/DD/YYYY');
                const lodgeEnd = dayjs(lodge.end_date, 'MM/DD/YYYY');
                return lodgeStart.isBefore(dateRangeStart) || lodgeEnd.isAfter(dateRangeEnd);
            });

            const outOfRangeMeals = usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals.filter(meal => {
                const mealDate = dayjs(meal.time_table?.dates?.[0]?.date, 'YYYY-MM-DD');
                return mealDate.isBefore(dateRangeStart) || mealDate.isAfter(dateRangeEnd);
            });

            const outOfRangeFlights = usePA_GlobalStore.getState().PA_GlobalObject.Planning.flights.filter(flight => {
                const flightDepart = dayjs(flight.depart_timestamp);
                return flightDepart.isBefore(dateRangeStart) || flightDepart.isAfter(dateRangeEnd);
            });

            const outOfRangeActivities = usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities.filter(activity => {
                const activityStart = dayjs(activity.begin_date, 'MM/DD/YYYY');
                const activityEnd = dayjs(activity.end_date, 'MM/DD/YYYY');
                return activityStart.isBefore(dateRangeStart) || activityEnd.isAfter(dateRangeEnd);
            });

            // 3. Show Warning if Any Event Component Falls Outside the New Date Range
            // 3. Show Warning if Any Event Component Falls Outside the New Date Range
            if (outOfRangeLodging.length > 0 || outOfRangeMeals.length > 0 || outOfRangeFlights.length > 0 || outOfRangeActivities.length > 0) {

                let warningMessage = 'Some event components fall outside the newly selected dates/days:\n\n';

                if (outOfRangeLodging.length > 0) {
                    warningMessage += `Lodging: ${outOfRangeLodging.length} out of range.\n`;
                }

                if (outOfRangeMeals.length > 0) {
                    warningMessage += `Meals: ${outOfRangeMeals.length} out of range.\n`;
                }

                if (outOfRangeFlights.length > 0) {
                    warningMessage += `Flights: ${outOfRangeFlights.length} out of range.\n`;
                }

                if (outOfRangeActivities.length > 0) {
                    warningMessage += `Activities: ${outOfRangeActivities.length} out of range.\n`;
                }

                alert(warningMessage.trim()); // Display only relevant messages
                return; // Stop further processing until the user acknowledges
            }

        } else {
            console.log("No changes in dates or days detected.");
        }

    }





    return (

        <div className="dates-times-selection-container" >
            {/* show the header */}
            <div
                className="dates-times-selection-header"
            >
                <div className="dates-times-selection-header-text">
                    <h5>Event Dates / Days</h5>
                </div>
                <div className="dates-times-selection-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeDatesClose}
                    />
                    <Chip
                        sx={updateChipStyle}
                        label="Save"
                        size="small"
                        onClick={handlePromptChangeDatesOK}
                    />
                </div>
            </div>


            {/* show the calendar inputs */}

            <div className="supplies-list-items-container">
                <Grid container spacing={2} direction="column">

                    <Grid item >
                        <TabContext sx={{ position:'relative'}} value={tabValue}>
                            <Box
                                sx={{
                                    width: '350px',
                                    position: 'fixed',
                                    marginTop: '-5px',
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                    backdropFilter: 'blur(6px)',
                                    zIndex:'2'
                                }}
                            >
                                <TabList
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabValueChange}
                                    aria-label="Event Dates / Days"
                                >
                                    <Tab
                                        label={datesCount > 0 ? (datesCount === 1 ? '1 Date' : `${datesCount} Dates`) : 'Dates'}
                                        value="dates"
                                    />
                                    <Tab
                                        label={eventDaysCount > 0 ? (eventDaysCount === 1 ? '1 Day' : `${eventDaysCount} Days`) : 'Days'}
                                        value="days"
                                    />
                                </TabList>

                            </Box>
                            <TabPanel value="dates" sx={{ padding:'50px 10px 10px 10px'}}>

                                {/* show the read-only calendar with any dates highlighted */}
                                <Grid item key="calendar_picker">
                                    <DateCalendar
                                        value={selectedDates.length > 0 ? selectedDates[0] : dayjs()}
                                        renderLoading={() => <DayCalendarSkeleton />}
                                        showDaysOutsideCurrentMonth
                                        slots={{
                                            day: ServerDay, // Use the custom ServerDay component
                                        }}
                                        slotProps={{
                                            day: { planningData: usePA_GlobalStore.getState().PA_GlobalObject.Planning }, // Pass planningData
                                        }}
                                    />
                                </Grid>

                                <Grid item sx={{marginBottom:'20px'}} className="dates-times-selection-times-text">
                                    Is this an Event with Begin and End times?
                                    If so, choose the start time and end time.
                                </Grid>

                                <Grid item
                                      sx={{marginBottom:'20px', textAlign:'right'}}
                                      className="dates-times-selection-times-text">
                                    <Switch
                                        checked={datesTimeOptionChecked}
                                        onChange={(e) => setDatesTimeOptionChecked(e.target.checked)} // Simplify state handling
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>

                                {/* Render time pickers for Dates */}
                                {renderTimePickers(
                                    datesTimeOptionChecked,
                                    datesBeginTimeValue,
                                    datesEndTimeValue,
                                    handleUpdatedDatesBeginTime,
                                    handleUpdatedDatesEndTime,
                                    'dates'
                                )}

                            </TabPanel>

                            <TabPanel value="days" sx={{ padding:'50px 10px 10px 10px'}}>

                                {/* show the rows of corresponding days */}
                                <Grid item sx={{marginBottom:'20px'}} className="dates-times-selection-times-text">
                                {
                                    renderDaysLayout()
                                }
                                </Grid>

                                <TextField
                                    fullWidth
                                    sx={{marginBottom:'20px'}}
                                    id="event_date_days"
                                    label="Number of Days"
                                    value={eventDaysCount}
                                    onChange={handleEventDaysCountChange}
                                    type="number"            // Ensures only numbers are allowed
                                    inputProps={{
                                        min: 1               // Sets the minimum value for the input
                                    }}
                                />


                                <FormControl
                                    fullWidth
                                    sx={{marginBottom:'20px'}}
                                >
                                    <InputLabel id="demo-simple-select-label">Starting On Day</InputLabel>
                                    <Select
                                        labelId="event-days-select-label"
                                        id="event_days_select"
                                        value={eventDaysStartingOnDay}
                                        label="Starting On Day"
                                        onChange={handleEventDaysStartingOnChange}
                                    >
                                        <MenuItem value="">Not Selected</MenuItem>
                                        <MenuItem value="Sunday">Sunday</MenuItem>
                                        <MenuItem value="Monday">Monday</MenuItem>
                                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                                        <MenuItem value="Thursday">Thursday</MenuItem>
                                        <MenuItem value="Friday">Friday</MenuItem>
                                        <MenuItem value="Saturday">Saturday</MenuItem>
                                    </Select>
                                </FormControl>

                                <Grid item sx={{marginBottom:'20px'}} className="dates-times-selection-times-text">
                                    Is this an Event with Begin and End times?
                                    If so, choose the start time and end time.
                                </Grid>

                                <Grid item
                                      sx={{marginBottom:'20px', textAlign:'right'}}
                                      className="dates-times-selection-times-text">
                                    <Switch
                                        checked={daysTimeOptionChecked}
                                        onChange={(e) => setDaysTimeOptionChecked(e.target.checked)} // Simplify state handling
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>

                                {/* Render time pickers for Days */}
                                {renderTimePickers(
                                    daysTimeOptionChecked,
                                    daysBeginTimeValue,
                                    daysEndTimeValue,
                                    handleUpdatedDaysBeginTime,
                                    handleUpdatedDaysEndTime,
                                    'days'
                                )}

                            </TabPanel>

                        </TabContext>
                    </Grid>

                </Grid>
            </div>

        </div>
    )

}

export default DatesTimesContent;
