import React, {useContext, useEffect, useState} from 'react';

import {Paper} from "@mui/material";
import FlightSummary from "./FlightSummary";
import FlightInput from "./FlightInput";
import FlightSelection from "./FlightSelection";
import FlightGoogleMap from "./FlightGoogleMap";
import {PAContext} from "../../../Services/PAContext";

import sampleDuffelFlights from "../../../sampleData/sampleDuffelFlights";




const FlightPlanner = (props) => {


    const { dataArray } = useContext(PAContext);
    const { FlightPlanningPipeline } = dataArray;

    // State to track selected departure and destination airports
    const [departureAirport, setDepartureAirport] = useState(null);
    const [destinationAirport, setDestinationAirport] = useState(null);

    const [zoomInOnLocation, setZoomInOnLocation] = useState({});
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingFor);


    const [flights, setFlights] = useState(sampleDuffelFlights);       // All flights returned by API
    const [selectedFlights, setSelectedFlights] = useState([]); // Flights selected by user

    // Handle real-time changes in airport selection
    const handleAirportChange = ({ departure, destination }) => {
        setDepartureAirport(departure);
        setDestinationAirport(destination);
    };

    // Handle the results from the flight input
    const handleFlightSearch = (searchParams) => {
        console.log("searchParams : "+JSON.stringify(searchParams));

        const _sendString = {};
        _sendString.action = "E_getDuffelFlights";
        _sendString.departure_airport = searchParams.departure.iata;        //the three letter airport code
        _sendString.destination_airport = searchParams.destination.iata;    //the three letter airport code
        _sendString.date = searchParams.date;                               //departure date
        _sendString.max_connections = searchParams.maxConnections;
        _sendString.useTestData = searchParams.useTestData;                 // Include the test data toggle value

        console.log("_sendString : "+JSON.stringify(_sendString));


        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

    };

    useEffect(() => {
        console.log('flights : ', flights);
    }, [flights]);

    useEffect(() => {
        console.log("Something new in the FlightPlanningPipeline : "+FlightPlanningPipeline.length);

        try {
            for (let i = 0; i < FlightPlanningPipeline.length; i++) {
                const flightAction = FlightPlanningPipeline[i];
                console.log("flightAction : "+flightAction);
                if (flightAction.result === "ok" && flightAction.flights?.length > 0){
                    setFlights(flightAction.flights);
                }
                //remove the one item we just processed
                FlightPlanningPipeline.splice(i, 1);
            }

        } catch (e){
            console.log(e);
        }


    }, [FlightPlanningPipeline]);

    // This function will be shared between FlightSelection and FlightGoogleMap
    const handleFlightSelection = (flightId) => {
        console.log('handleFlightSelection : '+flightId);

        setSelectedFlights((prevSelectedFlights) => {
            const isAlreadySelected = prevSelectedFlights.some((flight) => flight.id === flightId);

            if (isAlreadySelected) {
                // Remove flight by id if already selected
                return prevSelectedFlights.filter((flight) => flight.id !== flightId);
            } else {
                // Find and add the flight by id if not selected
                const selectedFlight = flights.find((flight) => flight.id === flightId);
                return [...prevSelectedFlights, selectedFlight];
            }
        });
    };

    // Send selected flights to the map and summary components
    const handleMapUpdate = () => {
        return selectedFlights; // Pass selected flights to GoogleMap
    };

    const handleSummaryUpdate = () => {
        return selectedFlights; // Pass selected flights to FlightSummary
    };

    const [isFlightSummaryExpanded, setIsFlightSummaryExpanded] = useState(false);

    const toggleFlightSummary = () => {
        setIsFlightSummaryExpanded((prev) => !prev);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 100px)',
                backgroundColor: 'aliceblue',
                padding: '10px'
            }}
        >
            {/* First Row: FlightInput and FlightSummary */}
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: '10px', height: '130px'}}>
                {/* FlightInput */}
                <div style={{ flex: 2, marginRight: '10px' }}>
                    <Paper elevation={3} style={{ padding: '10px' }}>
                        <FlightInput
                            onSearch={handleFlightSearch}
                            onAirportChange={handleAirportChange}
                        />
                    </Paper>
                </div>

                {/* FlightSummary */}
                <div style={{ flex: 1, maxHeight: '100%', overflow: 'hidden' }}>
                    <Paper
                        elevation={3}
                        style={{
                            padding: '10px',
                            height: '100%',
                            overflowY: 'auto', // Ensure scrolling
                        }}
                    >
                        <FlightSummary flights={selectedFlights} />
                    </Paper>
                </div>
            </div>

            {/* Second Row: FlightSelection and FlightGoogleMap */}
            <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
                {/* FlightSelection */}
                <div style={{flex: 1, marginRight: '10px', flexDirection: 'column', maxWidth: '320px'}}>
                    <Paper elevation={3} style={{flexGrow: 1, overflowY: 'auto', padding: '10px', height: 'calc(100vh - 243px)'}}>
                        <FlightSelection
                            flights={flights}
                            selectedFlights={selectedFlights}
                            onSelectFlight={handleFlightSelection}
                        />
                    </Paper>
                </div>

                {/* FlightGoogleMap */}
                <div style={{flex: 2, display: 'flex', flexDirection: 'column', marginBottom: '5px'}}>
                    <Paper elevation={3} style={{flexGrow: 1, padding: '10px'}}>
                        <FlightGoogleMap
                            _global_map_ref={props._global_map_ref}
                            selectedFlights={selectedFlights}
                            onSelectFlight={handleFlightSelection}
                            departureAirport={departureAirport}
                            destinationAirport={destinationAirport}
                        />
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default FlightPlanner;