import { create } from 'zustand';

import PAEvent from "../MasterEventObjects/PAEvent";

const usePA_GlobalStore = create((set) => ({
    PA_GlobalObject: {
        Planning: new PAEvent(),

    },
    set_PA_GlobalObject: (update) =>
        set((state) => {
            const newState =
                typeof update === "function" ? update(state.PA_GlobalObject) : update;

            console.log("Updating PA_GlobalObject:", {
                update: newState,
            });

            return {
                PA_GlobalObject: { ...state.PA_GlobalObject, ...newState },
            };
        }),
}));

export default usePA_GlobalStore;
