

class PAAttendee {

    constructor(fname, lname, email) {
        const _temp = PAAttendee.generateRandomString(10);
        this.temp_id = _temp;
        this.id = _temp;
        this.created = 0;
        this.role = "guest";
        this.planner = false;
        this.image = "";
        this.fname = fname;
        this.lname = lname;
        this.nickname = fname;
        this.language = "en";

        this.address = "";
        this.city = "";
        this.state = "";
        this.zip = "";
        this.country = "";

        this.email = email;
        this.dob = "";

        this.phone_prefix = "";
        this.phone = "";
        this.mask_phone_number = true;

        this.events = [];

        this.documents = [];

        this.preferred_airport = "";

        this.push_notifications = false;
        this.push_keys = [];

        this.welcome_requested = false;
        this.welcome_message = "";

        this.rsvp_requested = false;
        this.rsvp_sent = false;
        this.rsvp = "na";
        this.rsvp_notes = "";
        this.notification = {};

        this.budget = 0;
        this.currency = "USD";

        this.total_costs = 0;
        this.activities_costs = 0;
        this.flight_costs = 0;
        this.lodging_costs = 0;
        this.meal_costs = 0;

        this.last_check = 0;
        this.status = 1;

    }

    static generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        return result;
    }
}

export default PAAttendee;
