import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Avatar, Button, ButtonGroup, Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText,
    ListItem,
    ListItemAvatar,
    Menu, MenuItem, MenuList, Tab, TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import {addChipStyle} from "../../ButtonStyle/Add";
import {DatePicker, PickersDay, TimePicker} from "@mui/x-date-pickers";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import {PAContext} from "../../../Services/PAContext";
import Box from "@mui/material/Box";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import ListItemIcon from "@mui/material/ListItemIcon";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import {editChipStyle} from "../../ButtonStyle/Edit";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";
import {formatName, stringAvatar, stringToColor} from "../../Helpers/TextStyling";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import sample_attendees from "../../../sampleData/Attendees";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const CustomTimelineDot = ({ day }) => {
    // Parse and format the date using dayjs
    const formattedDay = dayjs(day, "MM/DD/YYYY").format('dd'); // Get the two-letter day abbreviation

    return (
        <Box
            sx={{
                width: 30,
                height: 30,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body2">{formattedDay}</Typography>
        </Box>
    );
};


function LodgingCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Attendees, SampleAttendees  } = dataArray;

    const [eventHasDates, setEventHasDates] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days?.length > 0);

    const [ attendeesReferenceArray, setAttendeesReferenceArray ] = useState( dataArray.authenticated ? Attendees : SampleAttendees);
    const [ selectedAttendeesArray, setSelectedAttendeesArray ] = useState( []);
    useEffect(() => {
        setAttendeesReferenceArray(dataArray.authenticated ? Attendees : SampleAttendees);
    }, [dataArray.authenticated, Attendees, SampleAttendees]);

    const [eventLodging, setEventLodging] = useState(props.lodging);
    const [selectedAction, setSelectedAction] = useState("");
    const [lodgingTabSelected, setLodgingTabSelected] = useState("");

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);
    const [showClockSelectCheckInTime, setShowClockSelectCheckInTime] = useState(false);
    const [showClockSelectCheckOutTime, setShowClockSelectCheckOutTime] = useState(false);

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);

    const fetchedRef = useRef(false);

    const [selectedLodgingBeginDate, setSelectedLodgingBeginDate] = useState('');
    const [selectedLodgingEndDate, setSelectedLodgingEndDate] = useState('');
    const [selectedLodgingBeginDay, setSelectedLodgingBeginDay] = useState('');
    const [selectedLodgingEndDay, setSelectedLodgingEndDay] = useState('');
    const [selectedLodgingBeginTime, setSelectedLodgingBeginTime] = useState('');
    const [selectedLodgingEndTime, setSelectedLodgingEndTime] = useState('');
    useEffect(() => {
        createTimeTableForLodging();
    }, [selectedLodgingBeginDate, selectedLodgingEndDate,
        selectedLodgingBeginDay, selectedLodgingEndDay,
        selectedLodgingBeginTime, selectedLodgingEndTime]);


    useEffect(() => {
        console.log("props.lodging has been updated --- : ", props.lodging);

        if (props.lodging?.time_table) {
            const timeTable = props.lodging.time_table;

            console.log("the PROPS Lodging timeTable ", timeTable);

            setSelectedLodgingBeginDate((prev) =>
                prev || timeTable.dates?.[0]?.date || ''
            );
            setSelectedLodgingEndDate((prev) =>
                prev || timeTable.dates?.[timeTable.dates.length - 1]?.date || ''
            );
            setSelectedLodgingBeginDay((prev) =>
                prev || Object.keys(timeTable.days?.[0] || {})[0] || ''
            );
            setSelectedLodgingEndDay((prev) =>
                prev || Object.keys(timeTable.days?.[timeTable.days.length - 1] || {})[0] || ''
            );
            setSelectedLodgingBeginTime((prev) =>
                prev || timeTable.dates?.[0]?.checkin_time || timeTable.days?.[0]?.checkin_time || ''
            );
            setSelectedLodgingEndTime((prev) =>
                prev || timeTable.dates?.[timeTable.dates.length - 1]?.checkout_time || timeTable.days?.[timeTable.days.length - 1]?.checkout_time || ''
            );

        }

        if (eventLodging.attendees && eventLodging.attendees.length > 0) {
            console.log("attendeesReferenceArray : ", attendeesReferenceArray);
            const initialSelectedAttendees = eventLodging.attendees
                .map((lodgingAttendee) =>
                    attendeesReferenceArray.find((attendee) => attendee.id === lodgingAttendee.id)
                );

            console.log("initialSelectedAttendees : ", initialSelectedAttendees);
            setSelectedAttendeesArray(initialSelectedAttendees);
        }

        const fetchPhotos = async () => {
            // Ensure no redundant fetch
            if (
                fetchedRef.current ||
                (props.lodging.photos && props.lodging.photos.length > 0) ||
                !props.lodging.location.google_place_id
            ) {
                return; // Exit if photos already exist, have been fetched, or no place ID is available
            }

            fetchedRef.current = true; // Mark as fetched to prevent duplicate fetches

            const request = {
                placeId: props.lodging.location.google_place_id,
                fields: ['photo'],
            };

            try {
                const results = await new Promise((resolve, reject) => {
                    const _global_map_service = new window.google.maps.places.PlacesService(
                        props._global_map_ref.current
                    );
                    _global_map_service.getDetails(request, (place, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve(place);
                        } else {
                            reject(status);
                        }
                    });
                });

                if (results.photos) {
                    const newPhotos = results.photos.map(photo =>
                        photo.getUrl({ maxWidth: 175, maxHeight: 175 })
                    );

                    // Update state without mutating props
                    setEventLodging(prev => ({ ...prev, photos: newPhotos }));
                } else {
                    console.log("No photos available for this lodging.");
                }
            } catch (error) {
                console.error("Error fetching photos for lodging:", error);
            }
        };

        fetchPhotos();
    }, [props.lodging]);


    useEffect(() => {
        console.log("eventLodging has been triggered --- : ", eventLodging);

        set_PA_GlobalObject((prev) => {
            const updatedLodging = prev.Planning.lodging.map((lodging) =>
                lodging.id === eventLodging.id
                    ? { ...lodging, ...eventLodging } // Update the specific lodging
                    : lodging // Keep the other lodgings unchanged
            );

            return {
                Planning: {
                    ...prev.Planning, // Retain all existing fields in Planning
                    lodging: updatedLodging, // Update only the specific lodging in the array
                },
            };
        });

    }, [eventLodging]);

    useEffect(()=> {
        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move lodging attendees to the top of the list
            for (let i = eventLodging.attendees.length - 1; i >= 0; i--) {
                const lodgingAttendee = eventLodging.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === lodgingAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);

    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.lodging[amount_total] : "+props.lodging["amount_total"]+" and "+eventLodging["amount_total"]);
        if (props.lodging["amount_total"] !== eventLodging["amount_total"] || props.lodging["currency"] !== eventLodging["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventLodging["attendees"].some((_attendee, index) => {
                const initialAttendee = props.lodging["attendees"].find(a => a.id === _attendee.id);
                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
            //turn on the button

            console.log("an attendee cost has changed");

            setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);

    const [tabValue, setTabValue] = useState(eventHasDates || !eventHasDays ? "dates" : "days");
    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setTabValue(newValue);
    };

    function handlePromptShowLodgingBeginDateCalendar(){
        //toggle the button
        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }
    function handlePromptShowLodgingEndDateCalendar(){
        //toggle the button
        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);
    }
    function handleBeginDateChange(begin_date){
        setSelectedLodgingBeginDate(dayjs(begin_date).format('YYYY-MM-DD'));
    }
    function handleEndDateChange(end_date){
        setSelectedLodgingEndDate(dayjs(end_date).format('YYYY-MM-DD'));
    }

    const renderLodgingDaysLayoutForBegin = () => {
        const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]; // Week layout starting with Sunday

        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName.slice(0, 2)); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayKey === selectedLodgingBeginDay // Set the selected status based on the selectedMealDay
            });
        });

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name.slice(0, 2));
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '5px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{day.name.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: day.isSelected
                                                ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                                : 'rgba(0, 123, 255, 0.2)',
                                        }}
                                        clickable
                                        onClick={() => handleLodgingBeginDayChange(day.key, day.name)} // Pass full day object on click
                                    />
                                );
                            } else {
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };
    const renderLodgingDaysLayoutForEnd = () => {
        const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]; // Week layout starting with Sunday

        // Get all planned days from Planning.time_table.days
        const planningDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.days || [];

        // Create an array to store all possible day objects (even for multi-week events)
        const calendarDays = [];

        planningDays.forEach((dayObj, index) => {
            const dayKey = Object.keys(dayObj)[0]; // e.g., 'day 1'
            const dayName = Object.values(dayObj)[0]; // e.g., 'Wednesday'
            const dayOfWeekIndex = daysOfWeek.indexOf(dayName.slice(0, 2)); // Index for Su-Sa layout (0-6)

            // Add the day to the correct slot (considering multiple weeks)
            calendarDays.push({
                key: dayKey,
                name: dayName,
                index: index + 1, // Index representing day in Planning (1-based index)
                isSelected: dayKey === selectedLodgingEndDay // Set the selected status based on the selectedMealDay
            });
        });

        // Organize into weeks (rows of 7 days)
        const weeks = [];
        let currentWeek = Array(7).fill(null);

        calendarDays.forEach((dayObj) => {
            const dayOfWeekIndex = daysOfWeek.indexOf(dayObj.name.slice(0, 2));
            currentWeek[dayOfWeekIndex] = dayObj;

            // When the week is complete (Saturday), push the current week to the weeks array
            if (dayOfWeekIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = Array(7).fill(null);
            }
        });

        // Push the final incomplete week if it exists
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>
                {/* Iterate over weeks */}
                {weeks.map((week, weekIndex) => (
                    <Box key={weekIndex} sx={{ display: 'flex', gap: '5px' }}>
                        {/* Iterate over each day in the week */}
                        {week.map((day, dayIndex) => {
                            if (day) {
                                return (
                                    <Chip
                                        key={dayIndex}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{day.name.slice(0, 2)}</Typography> {/* Two-letter day */}
                                            </div>
                                        }
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: day.isSelected
                                                ? 'rgba(0, 123, 255, 0.6)' // Highlight selected day
                                                : 'rgba(0, 123, 255, 0.2)',
                                        }}
                                        clickable
                                        onClick={() => handleLodgingEndDayChange(day.key, day.name)} // Pass full day object on click
                                    />
                                );
                            } else {
                                return <Box key={dayIndex} sx={{ width: '40px', height: '40px' }} />;
                            }
                        })}
                    </Box>
                ))}
            </Box>
        );
    };
    const handleLodgingBeginDayChange = (dayKey, dayName) => {
        console.log("handleLodgingBeginDayChange", dayKey, ":", dayName);
        // Set the selected meal day in state
        setSelectedLodgingBeginDay(dayKey);
    };
    const handleLodgingEndDayChange = (dayKey, dayName) => {
        console.log("handleLodgingEndDayChange", dayKey, ":", dayName);
        // Set the selected meal day in state
        setSelectedLodgingEndDay(dayKey);
    };

    function handlePromptShowLodgingCheckInTime(){
        //toggle the button

        //post the update
        if (showClockSelectCheckInTime){
//            props.updateLodgingDetails(eventLodging);
        }

        //change button back to 'Edit'
        setShowClockSelectCheckInTime(!showClockSelectCheckInTime);
    }
    function handlePromptShowLodgingCheckOutTime(){
        //toggle the button

        //post the update
        if (showClockSelectCheckInTime){
//            props.updateLodgingDetails(eventLodging);
        }

        //change button back to 'Edit'
        setShowClockSelectCheckOutTime(!showClockSelectCheckOutTime);
    }

    function handleCheckInTimeChange(check_in_time){
        console.log("check_in_time : "+dayjs(check_in_time).format('h:mm A'));
        setSelectedLodgingBeginTime(dayjs(check_in_time).format('h:mm A'));
    }
    function handleCheckOutTimeChange(check_out_time){
        setSelectedLodgingEndTime(dayjs(check_out_time).format('h:mm A'));
    }

    // Function to generate a new TimeTable for the Lodging
    function createTimeTableForLodging() {
        console.log("createTimeTableForLodging");

        const eventTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning["time_table"];
        const newTimeTable = new TimeTable();

        // Parse the begin and end dates
        const lodgingBeginDate = dayjs(selectedLodgingBeginDate, "YYYY-MM-DD");

        // Determine lodgingEndDate
        const lodgingEndDate = selectedLodgingEndDate
            ? dayjs(selectedLodgingEndDate, "YYYY-MM-DD")
            : eventTimeTable?.dates?.[eventTimeTable.dates.length - 1]
                ? dayjs(eventTimeTable.dates[eventTimeTable.dates.length - 1]?.date, "YYYY-MM-DD")
                : lodgingBeginDate.add(1, 'day'); // Default to the next day if no other info exists

        console.log("lodgingBeginDate:", lodgingBeginDate.format('YYYY-MM-DD'));
        console.log("lodgingEndDate:", lodgingEndDate.format('YYYY-MM-DD'));

        // Use default times if not provided
        const checkinTime = selectedLodgingBeginTime || "4:00 PM";
        const checkoutTime = selectedLodgingEndTime || "10:00 AM";

        console.log("checkinTime:", checkinTime);
        console.log("checkoutTime:", checkoutTime);

        // Build a map of event dates to their corresponding day numbers (if eventTimeTable exists)
        const eventDateMap = {};
        if (eventTimeTable?.dates?.length > 0) {
            eventTimeTable.dates.forEach((eventDateObj, index) => {
                const eventDate = dayjs(eventDateObj.date, "YYYY-MM-DD");
                eventDateMap[eventDate.format("YYYY-MM-DD")] = index + 1; // Days are 1-based
            });
        }

        // Generate lodging time_table
        if (lodgingBeginDate.isValid() && lodgingEndDate.isValid()) {
            let currentDate = lodgingBeginDate;

            while (currentDate.isSameOrBefore(lodgingEndDate)) {
                const isFirstDay = currentDate.isSame(lodgingBeginDate, 'day');
                const isLastDay = currentDate.isSame(lodgingEndDate, 'day');

                const dayOfWeek = currentDate.format("dddd");
                const eventDay = eventDateMap[currentDate.format("YYYY-MM-DD")]; // Lookup day number

                newTimeTable.dates.push({
                    date: currentDate.format("YYYY-MM-DD"),
                    dayOfWeek: dayOfWeek,
                    checkin_time: isFirstDay ? checkinTime : undefined,
                    checkout_time: isLastDay ? checkoutTime : undefined,
                    notes: "", // Add additional notes if needed
                });

                // Only add to days if eventTimeTable exists and has matching day
                if (eventDay) {
                    newTimeTable.days.push({
                        [`day ${eventDay}`]: dayOfWeek,
                        checkin_time: isFirstDay ? checkinTime : undefined,
                        checkout_time: isLastDay ? checkoutTime : undefined,
                        notes: "", // Add additional notes if needed
                    });
                } else {
                    console.warn(
                        "Lodging date does not match any event date, skipping days entry:",
                        currentDate.format("YYYY-MM-DD")
                    );
                }

                currentDate = currentDate.add(1, 'day');
            }
        }

        console.log("Setting the new time_table:", newTimeTable);

        // Update the lodging timetable
        setEventLodging((prevState) => ({
            ...prevState,
            time_table: newTimeTable,
        }));
    }


    const handleOccupantsToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = eventLodging["attendees"].findIndex(item => item.id === _attendee_id);

        let updatedList = [...eventLodging["attendees"]];

        if (index !== -1) {
            // Remove attendee from lodging list
            updatedList.splice(index, 1);
        } else {

            // Find the attendee in the reference array
            const newAttendee = attendeesReferenceArray.find(att => att.id === _attendee_id);

            if (!newAttendee) {
                console.error(`Attendee with ID ${_attendee_id} not found.`);
                return;
            }

            // Add to global context if not already present
            const globalAttendees = usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees;
            if (!globalAttendees.some(att => att.id === _attendee_id)) {
                set_PA_GlobalObject(prev => ({
                    Planning: {
                        ...prev.Planning,
                        attendees: [...prev.Planning.attendees, newAttendee],
                    },
                }));
            }

            // Add the attendee to the meal attendee list
            const attendeeWithCost = {
                id: _attendee_id,
                cost: 0,
                is_business_expense: false,
                business_expense_amount_authorized: 0,
                business_expense_amount_authorized_by: "",
                business_expense_amount_authorized_timestamp: 0,
                business_expense_amount: 0,
                business_expense_paid: 0,
                business_expense_reference_id: "",
            };
            updatedList = [...eventLodging.attendees, attendeeWithCost];
        }

        if (eventLodging["assign_equal"]) {

            const lodgingCost = parseFloat(eventLodging["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
            const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

        }

        setEventLodging(prevState => ({
            ...prevState,
            attendees: updatedList
        }));

    };

    useEffect(() => {
        console.log('Attendees have been updated:', eventLodging.attendees);
//        props.updateLodgingDetails(eventLodging);
    }, [eventLodging.attendees]);

    function handleCostOverride(event) {
        const valueToAdd = parseFloat(event.target.value);

        setEventLodging(prevState => ({
            ...prevState,
            amount_total: valueToAdd
        }));

    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventLodging["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventLodging["attendees"]];
                let lodgingCost = parseFloat(eventLodging["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

//                props.updateLodgingDetails(eventLodging);

            }
        }

        setShowPriceOverride(!showPriceOverride);
    }

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventLodging(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventLodging["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventLodging(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventLodging["attendees"]];

            const lodgingCost = parseFloat(eventLodging["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventLodging(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventLodging(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);

        //update the Parent object
//        props.updateLodgingDetails(eventLodging);

        /*
                const updatedLodgingOccupantsCosts = lodgingOccupantsCosts.map((attendee) => {
                    if (attendee.id === _attendee_id) {
                        // Update the costs for the matching attendee
                        console.log("---- attendee : "+JSON.stringify(attendee));
                        return { ...attendee, costs: e.target.value };
                    }
                    console.log("---- attendee : "+JSON.stringify(attendee));
                    return attendee; // Keep other attendees unchanged
                });

                // Use the state updater function to work with the updated state
                setLodgingOccupantsCosts((prevCosts) => {

                    const _new_unassigned = parseFloat(lodgingCost - parseFloat(updatedLodgingOccupantsCosts.reduce((sum, attendee) => sum - -attendee.costs, 0)).toFixed(2));
                    console.log("_new_unassigned: " + _new_unassigned);
                    setLodgingOccupantsCostsUnassigned(_new_unassigned);

                    return updatedLodgingOccupantsCosts;
                });

         */

    }

    const [editModeMap, setEditModeMap] = useState({});

    const handleBusinessExpenseToggle = (attendeeId, isChecked) => {
        setEventLodging((prevLodging) => ({
            ...prevLodging,
            attendees: prevLodging.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: isChecked,
                        business_expense_amount: isChecked ? att.cost : 0, // Sync with cost if toggled on
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseAmountEdit = (attendeeId) => {
        setEditModeMap((prev) => ({
            ...prev,
            [attendeeId]: !prev[attendeeId], // Toggle edit mode
        }));
    };
    const handleBusinessExpenseAmountChange = (attendeeId, value) => {
        // Use a regex to allow only valid decimal numbers
        const validInput = value.match(/^\d*\.?\d{0,2}$/); // Allows up to two decimal places
        if (!validInput) return;

        setEventLodging((prevLodging) => ({
            ...prevLodging,
            attendees: prevLodging.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        business_expense_amount: value, // Keep raw string for input control
                    }
                    : att
            ),
        }));
    };
    const handleBusinessExpenseEnableOnEdit = (attendeeId) => {
        setEventLodging((prevLodging) => ({
            ...prevLodging,
            attendees: prevLodging.attendees.map((att) =>
                att.id === attendeeId
                    ? {
                        ...att,
                        is_business_expense: true, // Automatically toggle checkbox
                    }
                    : att
            ),
        }));
    };

    function handleSaveLodgingCostsAssignment(event){

//        props.updateLodgingDetails(eventLodging);

        setShowPriceChangesUpdateButton(false);

    }

    function handleBookedThroughChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            booked_through: _newValue
        }));
    }
    function handleConfirmationNumberChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            confirmation_number: _newValue
        }));
    }
    function handleReservationNameChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            reservation_name: _newValue
        }));
    }
    function handleReservationNumberChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            reservation_number: _newValue
        }));
    }
    function handleGateCodeChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            gate_code: _newValue
        }));
    }
    function handleDoorCodeChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            door_code: _newValue
        }));
    }
    function handleWifiNameChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            wifi_name: _newValue
        }));
    }
    function handleWifiPasswordChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            wifi_password: _newValue
        }));
    }
    function handleNotesChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            notes: _newValue
        }));
    }

    const handleFooterItemClick = (_item) => {
        setSelectedAction(_item);
        lodgingTabSelected === _item ? setLodgingTabSelected("") : setLodgingTabSelected(_item);
        setAnchorLodgingListItemMenu(null);
    };

    const [openDialogDateRange, setOpenDialogDateRange] = useState(false);
    function handleDialogDateRangeClose() {
        setOpenDialogDateRange(false);
    }
    function handleDialogDateRangeOK(){
        setOpenDialogDateRange(false);
    }

    const [anchorLodgingListItemMenu, setAnchorLodgingListItemMenu] = useState(null);
    const handleLodgingListItemMenuOpenMenu = (event) => {
        setAnchorLodgingListItemMenu(event.currentTarget);
    };
    const handleLodgingListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorLodgingListItemMenu(event.currentTarget);

    };
    const handleLodgingListItemMenuClose = () => {
        setAnchorLodgingListItemMenu(null);
    };




    // Check if time_table has dates or days
    const hasDates = usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.dates && usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.dates.length > 0;
    const hasDays = usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.days && usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table']?.days.length > 0;
    console.log("hasDates : "+hasDates+" : hasDays : "+hasDays);



    return (

        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >

                {/* add any photos if they exist */}
                {
                    eventLodging['photos'] && eventLodging['photos'].length >= 0 ? (
                        <div className="lodging-suggestions-add-list-item-photos">
                            {
                                eventLodging['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <div className="lodging-suggestions-add-list-item-photos">

                            {
                                Object.keys(eventLodging.Duffel).length > 0 ? (
                                    <CardMedia
                                        sx={{ height: 100 }}
                                        image={eventLodging.Duffel['accommodation']['photos'][0].url}
                                        title="green iguana"
                                    />
                                ) : (
                                    Object.keys(eventLodging.Place).length > 0 ? (
                                        <div className="lodging-suggestions-add-list-item-photos">
                                            {eventLodging.photos.map((attribution, index) => (
                                                <CardMedia
                                                    key={index}
                                                    component="img"
                                                    alt={`Attribution ${index + 1}`}
                                                    image={attribution} // Use the attribution as the image source
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <>No Photos</>
                                    )
                                )
                            }
                        </div>
                    )
                }

                <CardHeader
                    title={
                        (() => {
                            const timeTable = eventLodging['time_table'];

                            // Check if the time_table has dates
                            if (timeTable?.dates?.length > 0) {
                                const firstDate = dayjs(timeTable.dates[0].date, "YYYY-MM-DD");
                                const lastDate = dayjs(timeTable.dates[timeTable.dates.length - 1].date, "YYYY-MM-DD");

                                // If the dates are valid, format them as "Sat Jun 16 - Sun Jun 17" or "Sat - Sun"
                                if (firstDate.isValid() && lastDate.isValid()) {
                                    // If the month and year are the same, display the short format (days only)
                                    return `${firstDate.format('ddd, MMM D')} - ${lastDate.format('ddd, MMM D')}`;
                                }
                            }

                            // Fallback: Check time_table.days if no valid dates
                            if (timeTable?.days?.length > 0) {
                                const firstDayKey = Object.keys(timeTable.days[0])[0];
                                const lastDayKey = Object.keys(timeTable.days[timeTable.days.length - 1])[0];

                                const firstDay = timeTable.days[0][firstDayKey]; // e.g., 'Monday'
                                const lastDay = timeTable.days[timeTable.days.length - 1][lastDayKey]; // e.g., 'Wednesday'

                                // Return the day names
                                return `${firstDay} - ${lastDay}`;
                            }

                            // Fallback if no valid dates
                            return '';
                        })()
                    }
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleLodgingListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{ paddingBottom: '0' }}
                    titleTypographyProps={{ fontSize: '18px' }}
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorLodgingListItemMenu}
                    id="lodging-item-menu"
                    open={Boolean(anchorLodgingListItemMenu)}
                    onClose={handleLodgingListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="dates"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <ListItemIcon>
                                <i className="icon-calendar3" title="Dates"/>
                            </ListItemIcon>
                            <ListItemText>Dates</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {
                                    (() => {
                                        const beginDate = dayjs(eventLodging['time_table'].dates[0]?.date, 'YYYY-MM-DD');
                                        const endDate = dayjs(eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1]?.date, 'YYYY-MM-DD');
                                        if (!beginDate.isValid() || !endDate.isValid()) {
                                            return '';
                                        }
                                        const diffDays = endDate.diff(beginDate, 'day');
                                        return diffDays === 1 ? `${diffDays} night` : `${diffDays} nights`;
                                    })()
                                }
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="location"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <ListItemIcon>
                                <i className="icon-map-marker" title="Location"/>
                            </ListItemIcon>
                            <ListItemText>Location</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="occupants"
                            onClick={() => handleFooterItemClick('Occupants')}
                        >
                            <ListItemIcon>
                                <i className="icon-user-24" title="Occupants"/>
                            </ListItemIcon>
                            <ListItemText>Occupants</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {eventLodging['attendees'].length}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="costs"
                            onClick={() => handleFooterItemClick('Costs')}
                        >
                            <ListItemIcon>
                                <i className="icon-wallet" title="Costs"/>
                            </ListItemIcon>
                            <ListItemText>Costs</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {parseFloat(eventLodging['amount_total']).toFixed(2)} {eventLodging['currency']}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="details"
                            onClick={() => handleFooterItemClick('Details')}
                        >
                            <ListItemIcon>
                                <i className="icon-checklist2" title="Details"/>
                            </ListItemIcon>
                            <ListItemText>Details</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="delete"
                            onClick={() => handleFooterItemClick('Delete')}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates and Night Count (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{padding:'0 16px 16px 16px'}}>
                    {/* Left-aligned: Dates */}

                    <Typography variant="body2" color="textSecondary">
                        {/*
                        {(() => {
                            const beginDate = dayjs(eventLodging['begin_date'], "MM/DD/YYYY");
                            const endDate = dayjs(eventLodging['end_date'], "MM/DD/YYYY");

                            // Check if the dates are valid
                            if (!beginDate.isValid() || !endDate.isValid()) {
                                return '';
                            }

                            // Format dates as "Aug 3 - Aug 6"
                            const formattedBeginDate = beginDate.format('MMM D');
                            const formattedEndDate = endDate.format('MMM D');

                            return `${formattedBeginDate} - ${formattedEndDate}`;
                        })()}
                        */}
                    </Typography>


                    {/* Right-aligned: Night Count */}
                    <Typography variant="body2" color="primary" sx={{ textAlign: 'right' }}>
                        {(() => {
                            const beginDate = dayjs(eventLodging['time_table'].dates[0]?.date, 'YYYY-MM-DD');
                            const endDate = dayjs(eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1]?.date, 'YYYY-MM-DD');

                            // Check if the dates are valid
                            if (!beginDate.isValid() || !endDate.isValid()) {
                                return '';
                            }

                            // Calculate the number of nights
                            const diffDays = endDate.diff(beginDate, 'day');

                            return diffDays === 1 ? '1 Night' : `${diffDays} Nights`;
                        })()}
                    </Typography>
                </Box>

                <CardContent sx={{padding:0}}>

                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            margin: 0
                        }}
                    >
                        <TimelineItem sx={{marginTop:0}}>
                            <TimelineSeparator>
                                {
                                    eventLodging['time_table']?.dates?.length > 0 ? (
                                        // Check if dates exist
                                        <TimelineDot
                                            sx={{
                                                border: '1px solid #cbe0f7',
                                                bgcolor: 'transparent',
                                                color: '#151269',
                                            }}
                                        >
                                            <CustomTimelineDot day={dayjs(eventLodging['time_table'].dates[0]?.date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                        </TimelineDot>
                                    ) : eventLodging['time_table']?.days?.length > 0 ? (
                                        // Check if days exist and show the first two letters of the day
                                        <TimelineDot
                                            sx={{
                                                border: '1px solid #cbe0f7',
                                                bgcolor: 'transparent',
                                                color: '#151269',
                                            }}
                                        >
                                            {Object.entries(eventLodging['time_table'].days[0])
                                                .filter(([key]) => key.includes('day')) // Only process day keys
                                                .map(([key, value]) => value.substring(0, 2)) // Take the first two letters of the day
                                            }
                                        </TimelineDot>
                                    ) : (
                                        // Default to primary color TimelineDot
                                        <TimelineDot color="primary" />
                                    )
                                }
                                <TimelineConnector sx={{ bgcolor: '#cbe0f7' }} />
                            </TimelineSeparator>


                            <TimelineContent sx={{py: '12px', px: 2}}>
                                <div>
                                    <Typography component="div">
                                        {
                                            eventLodging['time_table']?.dates?.length > 0 && eventLodging['time_table'].dates[0]?.checkin_time ? (
                                                `${eventLodging['time_table'].dates[0].checkin_time} Check In`
                                            ) : eventLodging['time_table']?.days?.length > 0 && eventLodging['time_table'].days[0]?.checkin_time ? (
                                                `${eventLodging['time_table'].days[0].checkin_time} Check In`
                                            ) : (
                                                "Check-In"
                                            )
                                        }
                                    </Typography>
                                </div>

                                <div style={{paddingLeft: '10px'}}>
                                    <Typography variant="body2">
                                        {eventLodging['name']}
                                    </Typography>
                                    <Typography sx={{mb: 1.5, fontSize: '12px'}} color="text.secondary">
                                        {eventLodging['location'].address}
                                        <br/>
                                        {eventLodging['location'].city} {eventLodging['location'].state} {eventLodging['location'].zip}
                                    </Typography>
                                </div>
                            </TimelineContent>

                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                {
                                    eventLodging['time_table']?.dates?.length > 0 ? (
                                        // Check if dates exist
                                        <TimelineDot
                                            sx={{
                                                border: '1px solid #cbe0f7',
                                                bgcolor: 'transparent',
                                                color: '#151269',
                                            }}
                                        >
                                            <CustomTimelineDot day={dayjs(eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1]?.date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                        </TimelineDot>
                                    ) : eventLodging['time_table']?.days?.length > 0 ? (
                                        // Check if days exist and show the first two letters of the day
                                        <TimelineDot
                                            sx={{
                                                border: '1px solid #cbe0f7',
                                                bgcolor: 'transparent',
                                                color: '#151269',
                                            }}
                                        >
                                            {Object.entries(eventLodging['time_table'].days[eventLodging['time_table'].days.length - 1])
                                                .filter(([key]) => key.includes('day')) // Only process day keys
                                                .map(([key, value]) => value.substring(0, 2)) // Take the first two letters of the day
                                            }
                                        </TimelineDot>
                                    ) : (
                                        // Default to primary color TimelineDot
                                        <TimelineDot color="primary" />
                                    )
                                }
                            </TimelineSeparator>

                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography component="div">
                                    {
                                        eventLodging['time_table']?.dates?.length > 0 && eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1]?.checkout_time ? (
                                            `${eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1].checkout_time} Check Out`
                                        ) : eventLodging['time_table']?.days?.length > 0 && eventLodging['time_table'].days[eventLodging['time_table'].days.length - 1]?.checkout_time ? (
                                            `${eventLodging['time_table'].days[eventLodging['time_table'].days.length - 1].checkout_time} Check Out`
                                        ) : (
                                            "Check-Out"
                                        )
                                    }
                                </Typography>
                            </TimelineContent>

                        </TimelineItem>

                    </Timeline>

                </CardContent>

                <CardActions sx={{paddingTop:'0', marginBottom:'10px'}}>
                    <div className="lodging-suggestions-add-list-item-footer-container">
                        {/* add the stay dates */}
                        <div
                            style={{
                                color: '#f09637',
                                backgroundColor: lodgingTabSelected && selectedAction === "Dates" ? '#EDEDED' : 'initial'
                            }}
                            className="lodging-suggestions-add-list-item-footer-dates-container"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <i className="icon-calendar3" title="Dates"/>
                        </div>
                        {/* add the lodging location */}
                        <div
                            style={{
                                color: '#f00',
                                backgroundColor: lodgingTabSelected && selectedAction === "Location" ? '#EDEDED' : 'initial'
                            }}
                            className="lodging-suggestions-add-list-item-footer-location-container"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <i className="icon-map-marker" title="Location"/>
                        </div>
                        {/* add the stay occupants */}
                        <div
                            style={{
                                color: '#f09637',
                                backgroundColor: lodgingTabSelected && selectedAction === "Occupants" ? '#EDEDED' : 'initial'
                            }}
                            className="lodging-suggestions-add-list-item-footer-occupants-container"
                            onClick={() => handleFooterItemClick('Occupants')}
                        >
                            <i className="icon-user-24" title="Occupants"/>
                        </div>
                        {/* add the stay costs */}
                        <div
                            style={{
                                color: '#f09637',
                                backgroundColor: lodgingTabSelected && selectedAction === "Costs" ? '#EDEDED' : 'initial'
                            }}
                            className="lodging-suggestions-add-list-item-footer-costs-container"
                            onClick={()=>handleFooterItemClick('Costs')}
                        >
                            <i className="icon-wallet"  title="Costs"/>
                        </div>
                        {/* add the stay details */}
                        <div
                            style={{
                                color: '#f00',
                                backgroundColor: lodgingTabSelected && selectedAction === "Details" ? '#EDEDED' : 'initial'
                            }}
                            className="lodging-suggestions-add-list-item-footer-details-container"
                            onClick={() => handleFooterItemClick('Details')}
                        >
                            <i className="icon-settings-icons" title="Details"/>
                        </div>
                    </div>
                </CardActions>

                <CardContent sx={{padding:'20px 0', display:lodgingTabSelected?'block':'none'}}>
                    {
                        selectedAction === "Dates" ? (
                            <div className="lodging-suggestions-add-list-item-footer-dates-holder">

                                <Grid item sx={{maxWidth: '100%'}} >
                                    <TabContext sx={{ position:'relative'}} value={tabValue}>
                                        <TabList
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                                backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                                backdropFilter: 'blur(6px)',
                                            }}
                                            centered
                                            variant="fullWidth"
                                            onChange={handleTabChange}
                                            aria-label="Activity Date / Day"
                                        >
                                            <Tab
                                                label="Date"
                                                value="dates"
                                            />
                                            <Tab
                                                label="Day"
                                                value="days"
                                            />
                                        </TabList>

                                        <TabPanel value="dates" sx={{padding: '24px 0'}}>

                                            {/* for the checkin date */}
                                            <div className="lodging-suggestions-add-list-item-footer-dates-checkin">
                                                {
                                                    eventLodging['time_table'].dates && eventLodging['time_table'].dates[0]?.date ? (
                                                        dayjs(eventLodging['time_table'].dates[0]?.date, 'YYYY-MM-DD').isValid() ? (
                                                            <>
                                                                Check In :&nbsp;
                                                                {dayjs(eventLodging['time_table'].dates[0]?.date, 'YYYY-MM-DD').format('D MMM, YYYY')}
                                                                <Chip
                                                                    sx={editChipStyle}
                                                                    label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                                    size="small"
                                                                    onClick={handlePromptShowLodgingBeginDateCalendar}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Check In :&nbsp;
                                                                <Chip
                                                                    sx={editChipStyle}
                                                                    label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                                    size="small"
                                                                    onClick={handlePromptShowLodgingBeginDateCalendar}
                                                                />
                                                            </>
                                                        )
                                                    ) : (
                                                        <>
                                                            Check In :&nbsp;
                                                            <Chip
                                                                sx={editChipStyle}
                                                                label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                                size="small"
                                                                onClick={handlePromptShowLodgingBeginDateCalendar}
                                                            />
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-dates-checkin-input"
                                                style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}
                                            >
                                                <DatePicker
                                                    sx={{ width: '100%' }}
                                                    label="Check In Date"
                                                    value={
                                                        eventLodging?.time_table?.dates?.[0]?.date
                                                            ? dayjs(eventLodging.time_table.dates[0]?.date, 'YYYY-MM-DD')
                                                            : dayjs() // Default to today's date if no time_table dates exist
                                                    }
                                                    onChange={handleBeginDateChange}
                                                    shouldDisableDate={(date) => {
                                                        const plannerTimeTable = usePA_GlobalStore.getState().PA_GlobalObject.Planning['time_table'];

                                                        if (!plannerTimeTable?.dates?.length) {
                                                            // If there are no valid dates in the planner time table, allow all dates
                                                            return false;
                                                        }

                                                        // Get valid dates from Planner['time_table']
                                                        const validDates = plannerTimeTable.dates.map((entry) =>
                                                            dayjs(entry.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        );

                                                        // Disable the date if it's not in the valid dates list
                                                        return !validDates.includes(dayjs(date).format('YYYY-MM-DD'));
                                                    }}
                                                    minDate={
                                                        dayjs(
                                                            usePA_GlobalStore
                                                                .getState()
                                                                .PA_GlobalObject.Planning['time_table']?.dates?.[0]?.date || dayjs().subtract(1, 'year') // Default to today
                                                        )
                                                    }
                                                    maxDate={
                                                        dayjs(
                                                            usePA_GlobalStore
                                                                .getState()
                                                                .PA_GlobalObject.Planning['time_table']?.dates?.slice(-1)?.[0]?.date || dayjs().add(1, 'year') // Default to a year from today
                                                        )
                                                    }
                                                />

                                            </div>

                                            {/* for the checkin time */}
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkin"
                                            >
                                                Time :&nbsp;
                                                {
                                                    eventLodging['time_table'].dates[0]?.checkin_time
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectCheckInTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingCheckInTime}
                                                />
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkin-input"
                                                style={{display: showClockSelectCheckInTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="Check In Time"
                                                    value={dayjs(eventLodging['time_table'].dates[0]?.checkin_time, 'h:mm A')}
                                                    onChange={handleCheckInTimeChange}
                                                />
                                            </div>


                                            <div
                                                className="lodging-suggestions-add-list-item-footer-dates-checkout"
                                            >
                                                {
                                                    eventLodging['time_table'].dates && eventLodging['time_table'].dates.slice(-1)[0]?.date ? (
                                                        dayjs(eventLodging['time_table'].dates.slice(-1)[0]?.date, 'YYYY-MM-DD').isValid() ? (
                                                            <>
                                                                Check Out :&nbsp;
                                                                {dayjs(eventLodging['time_table'].dates.slice(-1)[0]?.date, 'YYYY-MM-DD').format('D MMM, YYYY')}
                                                                <Chip
                                                                    sx={editChipStyle}
                                                                    label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                                    size="small"
                                                                    onClick={handlePromptShowLodgingEndDateCalendar}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Check Out :&nbsp;
                                                                <Chip
                                                                    sx={editChipStyle}
                                                                    label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                                    size="small"
                                                                    onClick={handlePromptShowLodgingEndDateCalendar}
                                                                />
                                                            </>
                                                        )
                                                    ) : (
                                                        <>
                                                            Check Out :&nbsp;
                                                            <Chip
                                                                sx={editChipStyle}
                                                                label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                                size="small"
                                                                onClick={handlePromptShowLodgingEndDateCalendar}
                                                            />
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-dates-checkout-input"
                                                style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                                <DatePicker
                                                    sx={{ width: '100%' }}
                                                    label="Check Out Date"
                                                    value={
                                                        eventLodging?.time_table?.dates?.slice(-1)?.[0]?.date
                                                            ? dayjs(eventLodging.time_table.dates.slice(-1)?.[0]?.date, 'YYYY-MM-DD')
                                                            : null // Use null if no valid date is available
                                                    }
                                                    onChange={handleEndDateChange}

                                                    minDate={
                                                        (() => {
                                                            const lodgingBeginDate = eventLodging?.time_table?.dates?.[0]?.date;
                                                            const eventBeginDate = usePA_GlobalStore
                                                                .getState()
                                                                .PA_GlobalObject.Planning['time_table']?.dates?.[0]?.date;

                                                            // Use lodgingBeginDate if available, otherwise fallback to eventBeginDate
                                                            return lodgingBeginDate
                                                                ? dayjs(lodgingBeginDate, 'YYYY-MM-DD')
                                                                : eventBeginDate
                                                                    ? dayjs(eventBeginDate, 'YYYY-MM-DD')
                                                                    : null; // No minimum date
                                                        })()
                                                    }
                                                    maxDate={
                                                        (() => {
                                                            const eventEndDate = usePA_GlobalStore
                                                                .getState()
                                                                .PA_GlobalObject.Planning['time_table']?.dates?.slice(-1)?.[0]?.date;

                                                            // Use lodgingEndDate if available, otherwise fallback to eventEndDate
                                                            return eventEndDate
                                                                ? dayjs(eventEndDate, 'YYYY-MM-DD')
                                                                : dayjs().add(1, 'year')
                                                        })()
                                                    }
                                                />

                                            </div>

                                            {/* for the checkout time */}
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkout"
                                            >
                                                Time :&nbsp;
                                                {
                                                    eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1]?.checkout_time
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectCheckOutTime ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingCheckOutTime}
                                                />
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkout-input"
                                                style={{display: showClockSelectCheckOutTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{width: '100%'}}
                                                    label="Check Out Time"
                                                    value={dayjs(eventLodging['time_table'].dates[eventLodging['time_table'].dates.length - 1]?.checkout_time, 'h:mm A')}
                                                    onChange={handleCheckOutTimeChange}
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel value="days" sx={{padding: '24px 0'}}>

                                            {/* for the checkin date */}
                                            <div className="lodging-suggestions-add-list-item-footer-dates-checkin">
                                                {
                                                    eventLodging['time_table'].days && eventLodging['time_table'].days[0] ? (
                                                        <>
                                                            Check In :&nbsp;
                                                            {
                                                                Object.entries(eventLodging['time_table'].days[0]).map(([key, value]) => (
                                                                    <span key={key}>
                                                                        {key.includes('day') ? `${key}: ${value}` : ''}
                                                                    </span>
                                                                ))
                                                            }
                                                            <Chip
                                                                sx={editChipStyle}
                                                                label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                                size="small"
                                                                onClick={handlePromptShowLodgingBeginDateCalendar}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            Check In :&nbsp;
                                                            <Chip
                                                                sx={editChipStyle}
                                                                label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                                size="small"
                                                                onClick={handlePromptShowLodgingBeginDateCalendar}
                                                            />
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-dates-checkin-input"
                                                style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}>
                                                {
                                                    renderLodgingDaysLayoutForBegin()
                                                }
                                            </div>

                                            {/* for the checkin time */}
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkin"
                                            >
                                                Check in Time :&nbsp;
                                                {
                                                    eventLodging['time_table'].days[0]?.checkin_time
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectCheckInTime?'Done':'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingCheckInTime}
                                                />
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkin-input"
                                                style={{display: showClockSelectCheckInTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{ width: '100%' }}
                                                    label="Check In Time"
                                                    value={dayjs(eventLodging['time_table'].days[0]?.checkin_time, 'h:mm A')}
                                                    onChange={handleCheckInTimeChange}
                                                />
                                            </div>


                                            <div
                                                className="lodging-suggestions-add-list-item-footer-dates-checkout"
                                            >
                                                {
                                                    eventLodging['time_table'].days && eventLodging['time_table'].days[0] !== undefined ? (
                                                        <>
                                                            Check Out :&nbsp;
                                                            {
                                                                Object.entries(eventLodging['time_table'].days[eventLodging['time_table'].days.length - 1]).map(([key, value]) => (
                                                                    <span key={key}>
                                                                        {key.includes('day') ? `${key}: ${value}` : ''}
                                                                    </span>
                                                                ))
                                                            }
                                                            <Chip
                                                                sx={editChipStyle}
                                                                label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                                size="small"
                                                                onClick={handlePromptShowLodgingEndDateCalendar}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            Check Out :&nbsp;
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                                size="small"
                                                                onClick={handlePromptShowLodgingEndDateCalendar}
                                                            />
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-dates-checkout-input"
                                                style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                                {
                                                    renderLodgingDaysLayoutForEnd()
                                                }
                                            </div>

                                            {/* for the checkout time */}
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkout"
                                            >
                                                Check out Time :&nbsp;
                                                {
                                                    eventLodging['time_table'].days[eventLodging['time_table'].days.length - 1]?.checkout_time
                                                }
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showClockSelectCheckOutTime?'Done':'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingCheckOutTime}
                                                />
                                            </div>
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-times-checkout-input"
                                                style={{display: showClockSelectCheckOutTime ? 'block' : 'none'}}>
                                                <TimePicker
                                                    sx={{ width: '100%' }}
                                                    label="Check Out Time"
                                                    value={dayjs(eventLodging['time_table'].days[eventLodging['time_table'].days.length - 1]?.checkout_time, 'h:mm A')}
                                                    onChange={handleCheckOutTimeChange}
                                                />
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="lodging-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                    <Grid item style={{ flex: '1' }}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_name"
                                                    label="Name"
                                                    value={eventLodging['location'].name}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_address"
                                                    label="Address"
                                                    value={eventLodging['location'].address}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_city"
                                                    label="City"
                                                    value={eventLodging['location'].city}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_state"
                                                    label="State"
                                                    value={eventLodging['location'].state}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_zip"
                                                    label="Zip"
                                                    value={eventLodging['location'].zip}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_country"
                                                    label="Country"
                                                    value={eventLodging['location'].country}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_phone"
                                                    label="Phone"
                                                    value={eventLodging['location'].phone}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Occupants" && (
                            <List dense sx={{ width: '100%' }}>
                                {selectedAttendeesArray
                                    .sort((a, b) =>
                                        `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(
                                            `${formatName(b.lname)}, ${formatName(b.fname)}`
                                        )
                                    )
                                    .map((_attendee, index) => (
                                        <div key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleOccupantsToggle(_attendee.id)}
                                                        checked={eventLodging['attendees'].some(_att => _att.id === _attendee.id)}
                                                        inputProps={{ 'aria-labelledby': index }}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                            sx={{
                                                                boxShadow: '' +
                                                                    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                borderRadius: '10px',
                                                                textAlign: 'center',
                                                                fontWeight: '700',
                                                                backgroundColor: '#FFF',
                                                                color: 'black',
                                                                fontSize: '15px',
                                                                width: '30px',
                                                                height: '30px',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={index}
                                                        primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    ))
                                }

                                {/* Optional autocomplete for adding attendees */}
                                <Box mt={6} mb={1}>
                                    <Divider />
                                </Box>

                                {/* Autocomplete for adding attendees */}
                                <Autocomplete
                                    multiple
                                    options={attendeesReferenceArray.filter(
                                        (_attendee) => !selectedAttendeesArray.some((selected) => selected.id === _attendee.id)
                                    )}
                                    getOptionLabel={(option) => `${formatName(option.lname)}, ${formatName(option.fname)}`}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    {...stringAvatar(`${formatName(option.fname)}, ${formatName(option.lname)}`)}
                                                    sx={{
                                                        boxShadow: '' +
                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                        border: `1px solid ${stringToColor(`${formatName(option.fname)}, ${formatName(option.lname)}`)}`,
                                                        borderRadius: '10px',
                                                        textAlign: 'center',
                                                        fontWeight: '700',
                                                        backgroundColor: '#FFF',
                                                        color: 'black',
                                                        fontSize: '15px',
                                                        width: '30px',
                                                        height: '30px',
                                                    }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={`${formatName(option.lname)}, ${formatName(option.fname)}`} />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Add Attendees"
                                            placeholder="Search Attendees"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        newValue.forEach((newAttendee) => {
                                            handleOccupantsToggle(newAttendee.id)();
                                        });
                                    }}
                                />
                            </List>
                        )
                    }
                    {
                        selectedAction === "Costs" ? (
                            <div className="lodging-suggestions-add-list-item-footer-costs-holder">
                                {/* Cost FlightSummary */}
                                <Typography variant="body2">
                                    Cost Summary
                                </Typography>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-costs-header"
                                >
                                    {
                                        dayjs(eventLodging['end_date']).diff(dayjs(eventLodging['begin_date']), 'day')
                                    }
                                    &nbsp;night
                                    {
                                        dayjs(eventLodging['end_date']).diff(dayjs(eventLodging['begin_date']), 'day')===1?'':'s'
                                    }
                                    &nbsp;at&nbsp;
                                    {
                                        (eventLodging['amount_total']/dayjs(eventLodging['end_date']).diff(dayjs(eventLodging['begin_date']), 'day')).toFixed(2)
                                    }
                                    /night
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="lodging-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventLodging['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventLodging['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventLodging['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="lodging-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            type="number"
                                            label="Lodging Total"
                                            value={eventLodging["amount_total"]}
                                            onChange={handleCostOverride}
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_lodging is the Assignment area */}
                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.length === 0 ? (
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="lodging-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_lodging is the toggle section */}
                                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventLodging['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventLodging['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_lodging is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventLodging['attendees']
                                                            .sort((a, b) => {
                                                                const _a = attendeesReferenceArray.find((_att) => _att.id === a.id) || { lname: '', fname: '' }; // Default to empty attendee
                                                                const _b = attendeesReferenceArray.find((_att) => _att.id === b.id) || { lname: '', fname: '' };

                                                                // Safeguard against undefined lname
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => {
                                                                const attendee = attendeesReferenceArray.find((_att) => _att.id === _attendee.id);

                                                                return (
                                                                    <div
                                                                        className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                        key={index}
                                                                    >
                                                                        <ListItem
                                                                            className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            secondaryAction={
                                                                                eventLodging['assign_equal'] ? (
                                                                                    <span
                                                                                        className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost"
                                                                                        style={{right:'5px'}}
                                                                                    >
                                                                                        {_attendee.cost}&nbsp;{eventLodging['currency']}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                        <TextField
                                                                                            label={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                                                                            value={_attendee.cost}
                                                                                            onChange={(e) => handleCustomOccupantCost(_attendee.id, e)}
                                                                                            variant="standard"
                                                                                        />
                                                                                    </span>
                                                                                )
                                                                            }
                                                                            disablePadding
                                                                        >
                                                                            <ListItemAvatar>
                                                                                {attendee && (
                                                                                    <Avatar
                                                                                        {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                                                        sx={{
                                                                                            boxShadow: '' +
                                                                                                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                                                '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                                                '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                                            border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                                                            borderRadius: '10px',
                                                                                            textAlign: 'center',
                                                                                            fontWeight: '700',
                                                                                            backgroundColor: '#FFF',
                                                                                            color: 'black',
                                                                                            fontSize: '15px',
                                                                                            width: '30px',
                                                                                            height: '30px'
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </ListItemAvatar>

                                                                            <ListItemText
                                                                                sx={{display: eventLodging['assign_equal'] ? 'block' : 'none'}}
                                                                                id={index}
                                                                                primary={attendee ? `${formatName(attendee.lname)}, ${formatName(attendee.fname)}` : ''}
                                                                            />
                                                                        </ListItem>


                                                                        {/* Business Expense Section */}
                                                                        <div
                                                                            className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'space-between',
                                                                            }}
                                                                        >
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Checkbox
                                                                                    checked={_attendee.is_business_expense || false}
                                                                                    onChange={(e) => handleBusinessExpenseToggle(_attendee.id, e.target.checked)}
                                                                                />
                                                                                <span style={{ marginLeft: '8px' }}>Business Expense</span>
                                                                            </div>

                                                                            {editModeMap[_attendee.id] ? (
                                                                                <TextField
                                                                                    label="Amount"
                                                                                    value={_attendee.business_expense_amount || 0.00}
                                                                                    onChange={(e) => handleBusinessExpenseAmountChange(_attendee.id, e.target.value)}
                                                                                    variant="standard"
                                                                                    onFocus={() => handleBusinessExpenseEnableOnEdit(_attendee.id)}
                                                                                />
                                                                            ) : (
                                                                                <span
                                                                                    className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost"
                                                                                >
                                                                                    {parseFloat(_attendee.business_expense_amount || 0).toFixed(2)}&nbsp;{eventLodging['currency']}
                                                                                </span>
                                                                            )}

                                                                            <Chip
                                                                                sx={editChipStyle}
                                                                                label={editModeMap[_attendee.id] ? 'Done' : 'Edit'}
                                                                                size="small"
                                                                                onClick={() => handleBusinessExpenseAmountEdit(_attendee.id)}
                                                                            />
                                                                        </div>

                                                                        {/* Divider */}
                                                                        <Divider/>

                                                                    </div>

                                                                );
                                                            })
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span className="lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventLodging['amount_total']) - eventLodging['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventLodging['currency']
                                                        }
                                                    </span>

                                                </div>

                                                {/* show the update button if changes were made */}

                                                {
                                                    showPriceChangesUpdateButton ? (
                                                        <div style={{cursor: 'pointer'}} onClick={handleSaveLodgingCostsAssignment}>
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label='Save Changes'
                                                                size="small"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_booked_through"
                                                label="Booked Through"
                                                value={eventLodging['booked_through']}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_confirmation_number"
                                                label="Confirmation Number"
                                                value={eventLodging['confirmation_number']}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_reservation_name"
                                                label="Reservation Name"
                                                value={eventLodging['reservation_name']}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_reservation_number"
                                                label="Reservation Number"
                                                value={eventLodging['reservation_number']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_gate_code"
                                                label="Gate Code"
                                                value={eventLodging['gate_code']}
                                                onChange={handleGateCodeChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_door_code"
                                                label="Door Code"
                                                value={eventLodging['door_code']}
                                                onChange={handleDoorCodeChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_wifi_name"
                                                label="Wifi Name"
                                                value={eventLodging['wifi_name']}
                                                onChange={handleWifiNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_wifi_password"
                                                label="Wifi Password"
                                                value={eventLodging['wifi_password']}
                                                onChange={handleWifiPasswordChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={eventLodging['notes']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        ) : (
                            <></>
                        )
                    }
                </CardContent>
            </Card>

            <Dialog
                open={openDialogDateRange}
                onClose={handleDialogDateRangeClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The date for the Lodging is outside of the date range for the Event.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDateRangeOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default LodgingCard;
