import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PAContext} from "../../Services/PAContext";

import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar} from "@mui/material";
import PAEvent from "../../MasterEventObjects/PAEvent";
import PAAttendee from "../../MasterEventObjects/Attendee";
import {browserStorageUpdateSpecificEvent, getEventFromDatabase} from "../../Services/BrowserDatabaseFunctions";
import usePA_GlobalStore from "../../Services/ZustandGlobalStore";
import PALocation from "../../MasterEventObjects/Location";




function FeaturesList(props) {

    const navigate = useNavigate();

    const { PA_GlobalObject, set_PA_GlobalObject } = usePA_GlobalStore();

    const { dataArray } = useContext(PAContext);
    const { Account, authenticated } = dataArray;

    const [currentFeature, setCurrentFeature] = useState(props.currentFeature);

    useEffect(() => {
        console.log("setCurrentFeature : "+setCurrentFeature);
        setCurrentFeature(props.currentFeature);
    }, [props.currentFeature]);

    const [needToSave, setNeedToSave] = useState(false);
    useEffect(() => {
        console.log("PA_GlobalObject.Planning : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.need_to_save);
        setNeedToSave(usePA_GlobalStore.getState().PA_GlobalObject.Planning.need_to_save);

        /*
            see if anything needs to change at the high level
            this may include activities, lodging, meals, flights, supplies, etc

         */


    }, [PA_GlobalObject.Planning]);

    const [ totalBudget, setTotalBudget ] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_total);
    const [ totalBudgetActual, setTotalBudgetActual ] = useState(usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_actual);
    useEffect(() => {
        console.log("updated budget_actual or budget_total : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_actual+" : "+usePA_GlobalStore.getState().PA_GlobalObject.Planning.budget_total);
//        setTotalBudget(props.eventBudgetTotal);
//        setTotalBudgetActual(props.eventBudgetActual);
    }, [PA_GlobalObject.Planning.budget_actual, PA_GlobalObject.Planning.budget_total]);

    const handleIconClick = (featureName) => {
        // Handle the click event here
        console.log(`Clicked on ${featureName}`);

        //decide whether to show the console or not
        if (featureName !== "Save" && featureName !== "Clear"){
            if (!props.showEventItemConfig){
                //if it's not showing then show it
                props.setShowEventItemConfig(true);
                setCurrentFeature(featureName);

            } else {
                //if it is showing then decide whether it is just changing features and needs to stay open
                // - or - if they are just meaning to close it
                if (featureName === currentFeature){
                    props.setShowEventItemConfig(false);
                    setCurrentFeature("Map");
                } else {
                    setCurrentFeature(featureName);
                }
            }
        }


        switch (featureName) {
            case "Save":
                savePlanning();
                break;
            case "Details":
                navigate('/Planner/Details')
                break;
            case "Location":
                navigate('/Planner/Location')
                break;
            case "Dates":
                navigate('/Planner/Dates')
                break;
            case "Map":
                props.setShowEventItemConfig(false);
                navigate('/Planner/Map')
                break;
            case "Itinerary":
                navigate('/Planner/Itinerary')
                break;
            case "Documents":
                navigate('/Planner/Documents')
                break;
            case "Activities":
                navigate('/Planner/Activities')
                break;
            case "Ideas":
                navigate('/Planner/Ideas')
                break;
            case "Attendees":
                navigate('/Planner/Attendees')
                break;
            case "Lodging":
                navigate('/Planner/Lodging')
                break;
            case "Flights":
                navigate('/Planner/Flights')
                break;
            case "carRentals":
                navigate('/Planner/CarRentals')
                break;
            case "Cruises":
                navigate('/Planner/Cruises')
                break;
            case "Messages":
                navigate('/Planner/Messages')
                break;
            case "Meals":
                navigate('/Planner/Meals')
                break;
            case "Photos":
                navigate('/Planner/Photos')
                break;
            case "Supplies":
                navigate('/Planner/Supplies')
                break;
            case "Receipts":
                navigate('/Planner/Receipts')
                break;
            case "To-Do":
                navigate('/Planner/Todo')
                break;
            case "Weather":
                navigate('/Planner/Weather')
                break;
            case "Clear":
                clearPlanning();
                break;
            default:
        }

        props.setShowEventItemExtendedConfig(false);
    };

    const theme = createTheme({
        palette: {
            badge: {
                main: '#cbe0f7'
            },
        }
    });


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    async function savePlanning(){

        console.log("savePlanning : ", usePA_GlobalStore.getState().PA_GlobalObject.Planning);
        console.log("authenticated : "+authenticated);
        console.log("Account.id : "+Account.id);

        //are they logged in
        if (authenticated && Account.id !== undefined){
            console.log("is logged in");

            //check if an Event name has been given
            //is this a bucket list item or an actual Event
            if (usePA_GlobalStore.getState().PA_GlobalObject.Planning.title === ""){
                //show the DialogBox to get the title


            } else {
                console.log("fine to proceed");
                /*
                    a few things to do here
                    - compare with

                */

                const databaseEvent = await getEventFromDatabase(usePA_GlobalStore.getState().PA_GlobalObject.Planning.id);

                if (databaseEvent){

                    // Perform the comparison
                    const changes = compareEvents(usePA_GlobalStore.getState().PA_GlobalObject.Planning, databaseEvent);
                    if (Object.keys(changes).length > 0) {
                        console.log("There are changes : ",changes);

                        //update the browser database

                        browserStorageUpdateSpecificEvent(usePA_GlobalStore.getState().PA_GlobalObject.Planning).then(success => {

                           if (success){
                               if (dataArray.Websocket){

                                   const _sendString = {};
                                   _sendString.action = "setEventAllChanges";
                                   _sendString.event_id = usePA_GlobalStore.getState().PA_GlobalObject.Planning.id;
                                   _sendString.event_exists = true;
                                   _sendString.updates = changes;

                                   dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                               }
                           } else {
                                //there were problems updating the Event in the database

                           }
                        });

                    } else {
                        console.log("There are NO changes");
                        //disable the save button

                    }

                } else {
                    //this Event doesn't exist so it must be coming straight from Planning
                    if (dataArray.Websocket){

                        const _sendString = {};
                        _sendString.action = "updateEventUsingNewVersion";
                        _sendString.event_id = usePA_GlobalStore.getState().PA_GlobalObject.Planning.id;
                        _sendString.event_exists = false;
                        _sendString.updates = usePA_GlobalStore.getState().PA_GlobalObject.Planning;

                        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                    }
                }

            }

        } else {
            console.log("needs to login");
            setSnackbarOpen(true);
        }

    }


    function compareEvents(planningEvent, databaseEvent) {
        const updatedSections = {};

        if (!databaseEvent) {
            return { all: planningEvent }; // If no old event exists, consider everything new
        }

        // Iterate through the keys of the event
        for (const key in planningEvent) {
            console.log("looking for changes to : "+key);

            if (planningEvent.hasOwnProperty(key) && !deepCompare(planningEvent[key], databaseEvent[key])) {
                updatedSections[key] = planningEvent[key];
            }
        }

        return updatedSections;
    }

    function deepCompare(obj1, obj2) {

        // If both are the same reference or both are null/undefined, return true
        if (obj1 === obj2) return true;

        // If either is not an object or array, compare directly
        if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }

        // If they are arrays, compare lengths and each item
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) return false;

            return obj1.every((item, index) => deepCompare(item, obj2[index]));
        }

        // If they are objects, compare keys and values
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        return keys1.every(key => deepCompare(obj1[key], obj2[key]));
    }


    function clearPlanning(){
        //show the prompt
        setOpenDialogDelete(true);

    }

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

        const _new_event = new PAEvent();
        _new_event.title = "My New Event";
        _new_event.status = 10;

        //see if they are logged in
        if (Object.keys(Account).length === 0){
            //create the default Planner Attendee
            console.log("Need to create the default Planner Attendee");
            const _planner = new PAAttendee(
                'Sample',
                'Planning',
                'sample.planning@plannedadventure.com');
            _planner.role = 'planner';
            _planner.planner = true;
            _planner.nickname = 'Planner';

            _new_event.attendees.push(_planner);
        } else {
            //they are logged in so use their account
            console.log("User is logged in");

            const _planner = new PAAttendee(
                Account.fname,
                Account.lname,
                Account.email);
            _planner.role = 'planner';
            _planner.planner = true;
            _planner.nickname = 'Planner';

            const _att_location = new PALocation();
            _att_location.address = Account.address;
            _att_location.city = Account.city;
            _att_location.state = Account.state;
            _att_location.zip = Account.zip;
            _att_location.country = Account.country;
            _planner.location = _att_location;

            _new_event.attendees.push(_planner);

        }

        set_PA_GlobalObject({ Planning: _new_event });

//        props.setClearEvent(true);

    }




    return (

        <ThemeProvider theme={theme}>

            {/* add the Details icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Details"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Details' ? '#fef4ec' : '',
                        border: currentFeature === 'Details' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Details")}
                >
                    {<i className="icon-settings-icons"/>}
                </IconButton>
                {
                    Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0
                    && usePA_GlobalStore.getState().PA_GlobalObject.Planning.title?.length > 3 ? (
                        <i className="icon-check"
                           style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                    ) : (
                        <i className="icon-warning"
                           style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red'}}/>
                    )
                }
            </div>

            {/* add the Budget icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Budget"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Budget' ? '#fef4ec' : '',
                        border: currentFeature === 'Budget' ? '1px solid #fccca5' : '0',
                        color: '#F00',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Budget")}
                >
                    {<i className="icon-wallet"/>}
                </IconButton>
                {/* add the green check or the warning sign */}
                {
                    parseFloat(props.eventBudgetActual) > parseFloat(props.eventBudgetTotal) ? (
                        <i className="icon-warning"
                           style={{
                               position: 'absolute',
                               top: 10,
                               right: 5,
                               fontSize: '11px',
                               color: 'red'
                           }}
                        />
                    ) : (
                        <i className="icon-check"
                           style={{
                               position: 'absolute',
                               top: 10,
                               right: 5,
                               fontSize: '11px',
                               color: 'green'
                           }}
                        />
                    )
                }
            </div>


            {/* add the Location icon */}
            {/*

            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Location"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Location' ? '#fef4ec' : '',
                        border: currentFeature === 'Location' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Location")}
                >
                    {<i className="icon-map-marker"/>}
                </IconButton>
                {Object.keys(Planning).length !== 0 && Planning.locations.length > 0 ? (
                    <i className="icon-check"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                ) : (
                    <></>
                )}
            </div>

            */}


            {/* add the Dates icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Dates"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Dates' ? '#fef4ec' : '',
                        border: currentFeature === 'Dates' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Dates")}
                >
                    {<i className="icon-calendar3"/>}
                </IconButton>
                {Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0
                    && usePA_GlobalStore.getState().PA_GlobalObject.Planning.time_table?.dates.length > 0 ? (
                    <i className="icon-check"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'green'}}/>
                ) : (
                    <i className="icon-warning"
                       style={{position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red'}}/>
                )}
            </div>

            {/* add the Map icon */}
            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Map"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Map' ? '#fef4ec' : '',
                        border: currentFeature === 'Map' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Map")}
                >
                    <i className="icon-map"/>
                </IconButton>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Itinerary"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Itinerary' ? '#fef4ec' : '',
                        color: '#f09637',
                        border: currentFeature === 'Itinerary' ? '1px solid #fccca5' : '0',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Itinerary")}
                >
                    {<i className="icon-calendar"/>}
                </IconButton>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.documents?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Documents"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Documents' ? '#fef4ec' : '',
                            border: currentFeature === 'Documents' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Documents")}
                    >
                        {<i className="icon-file-check"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.activities?.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                >
                    <IconButton
                        title="Activities"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Activities' ? '#fef4ec' : '',
                            border: currentFeature === 'Activities' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Activities")}
                    >
                        {<i className="icon-list-heart"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.ideas?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Ideas"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Ideas' ? '#fef4ec' : '',
                            border: currentFeature === 'Ideas' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Ideas")}
                    >
                        {
                            <i className="icon-lamp-bright"/>
                        }
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0
                            ? usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees === undefined
                                ? 0
                                : usePA_GlobalStore.getState().PA_GlobalObject.Planning.attendees.filter(attendee => attendee.status !== 4).length
                            : 0
                    }
                    color="badge"
                >
                    <IconButton
                        title="Attendees"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Attendees' ? '#fef4ec' : '',
                            border: currentFeature === 'Attendees' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Attendees")}
                    >
                        {<i className="icon-user-24"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.lodging?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Lodging"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Lodging' ? '#fef4ec' : '',
                            border: currentFeature === 'Lodging' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Lodging")}
                    >
                        {<i className="icon-bed"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.flights?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Flights"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Flights' ? '#fef4ec' : '',
                            border: currentFeature === 'Flights' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Flights")}
                    >
                        {<i className="icon-airplane"/>}
                    </IconButton>
                </Badge>
            </div>

            {/*
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.car_rentals.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Car Rentals"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'carRentals' ? '#fef4ec' : '',
                            border: currentFeature === 'carRentals' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("carRentals")}
                    >
                        {<i className="icon-car2"/>}
                    </IconButton>
                </Badge>
            </div>
            */}

            {/*
            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(Planning).length !== 0 ? (
                            Planning.cruises.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Cruises"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'cruises' ? '#fef4ec' : '',
                            border: currentFeature === 'cruises' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("cruises")}
                    >
                        {<i className="icon-ship"/>}
                    </IconButton>
                </Badge>
            </div>
            */}

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.messages?.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                    max={Infinity}
                    sx={{
                        '& .MuiBadge-badge': {
                            minWidth: 'auto', // Adjust the minimum width
                            height: 'auto', // Adjust the height
                            padding: '0 6px', // Adjust padding for better fit
                            fontSize: '0.75rem', // Adjust the font size
                            lineHeight: '1', // Ensure line height doesn't cause overflow
                        }
                    }}
                >
                    <IconButton
                        title="Messaging"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Messages' ? '#fef4ec' : '',
                            border: currentFeature === 'Messages' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Messages")}
                    >
                        {<i className="icon-smartphone-text"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.meals?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Meals"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Meals' ? '#fef4ec' : '',
                            border: currentFeature === 'Meals' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Meals")}
                    >
                        {<i className="icon-fork-knife2"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.photos?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Photos"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Photos' ? '#fef4ec' : '',
                            border: currentFeature === 'Photos' ? '1px solid #fccca5' : '0',
                            color: '#f09637',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Photos")}
                    >
                        {<i className="icon-picture2"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.supplies?.length
                        ) : (
                            0
                        )
                    }
                    color="badge"
                >
                    <IconButton
                        title="Supplies"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Supplies' ? '#fef4ec' : '',
                            border: currentFeature === 'Supplies' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Supplies")}
                    >
                        {<i className="icon-checklist2"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.receipts?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="Receipts"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'Receipts' ? '#fef4ec' : '',
                            border: currentFeature === 'Receipts' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("Receipts")}
                    >
                        {<i className="icon-receipt"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <Badge
                    badgeContent={
                        Object.keys(usePA_GlobalStore.getState().PA_GlobalObject.Planning)?.length !== 0 ? (
                            usePA_GlobalStore.getState().PA_GlobalObject.Planning.to_do?.length
                        ) : (
                            0
                        )
                    }
                    color="badge">
                    <IconButton
                        title="To-Do"
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontWeight: '700',
                            backgroundColor: currentFeature === 'To-Do' ? '#fef4ec' : '',
                            border: currentFeature === 'To-Do' ? '1px solid #fccca5' : '0',
                            color: '#F00',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleIconClick("To-Do")}
                    >
                        {<i className="icon-checklist-time"/>}
                    </IconButton>
                </Badge>
            </div>

            <div style={{position: 'relative', padding: '8px'}}>
                <IconButton
                    title="Weather"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Weather' ? '#fef4ec' : '',
                        color: '#F00',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Weather")}
                    disabled={!props.weatherReportReceived}
                >
                    <i className="icon-sun-wind"/>
                </IconButton>
                {
                    props.weatherReportReceived ? (
                        <i className="icon-check"
                           style={{position: 'absolute', top: 5, right: 5, fontSize: '11px', color: 'green'}}/>
                    ) : (
                        <></>
                    )
                }
            </div>

            {/* add the Save icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Save to Database"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Save' ? '#fef4ec' : '',
                        border: currentFeature === 'Save' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Save")}
                >
                    {<i className="icon-floppy-disk"/>}
                </IconButton>
                {needToSave && (
                    <i
                        className="icon-warning"
                        style={{ position: 'absolute', top: 10, right: 5, fontSize: '11px', color: 'red' }}
                    />
                )}

            </div>

            {/* add the Clear icon */}
            <div style={{position: 'relative', padding: '8px'}}>

                <IconButton
                    title="Clear"
                    sx={{
                        boxShadow: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: currentFeature === 'Clear' ? '#fef4ec' : '',
                        border: currentFeature === 'Clear' ? '1px solid #fccca5' : '0',
                        color: '#f09637',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleIconClick("Clear")}
                >
                    {<i className="icon-trash"/>}
                </IconButton>
            </div>


            <Dialog
                open={openDialogDelete}
                onClose={handleDialogDeleteClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to clear all of the information for this Event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}  // Closes after 3 seconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                    Please log in to save your planning.
                </Alert>
            </Snackbar>

        </ThemeProvider>

    )
}

export default FeaturesList;
