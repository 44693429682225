import {browserStorageUpdateSpecificEvent} from "../../../Services/BrowserDatabaseFunctions";

import {convertEventVersion1To2} from "../../Helpers/EventConverter";
import usePA_GlobalStore from "../../../Services/ZustandGlobalStore";


function mergeChanges(existing = [], changes = []) {
    if (!Array.isArray(existing)) {
        throw new Error("Expected existing to be an array");
    }

    if (!Array.isArray(changes)) {
        throw new Error("Expected changes to be an array");
    }

    // Create a map of existing items by their IDs for efficient lookup
    const existingMap = new Map(existing.map(item => [item.id, item]));

    // Process the changes
    changes.forEach(change => {
        existingMap.set(change.id, change); // Add or update items in the map
    });

    // Filter out any items from the existing array that are not in the changes
    const changeIds = new Set(changes.map(change => change.id));
    existingMap.forEach((_, id) => {
        if (!changeIds.has(id)) {
            existingMap.delete(id); // Remove items not present in the changes
        }
    });

    // Return a new array with the merged changes
    return Array.from(existingMap.values());
}


function E_getEventChangesResults(_incoming, PA_GlobalObject, set_PA_GlobalObject) {
    console.log("E_getEventChangesResults : ", _incoming);

    if (_incoming.result === 'ok' && _incoming.events?.length > 0) {
        console.log("Event(s) need updating: " + _incoming.events.length);

        _incoming.events.forEach((_evt) => {

            const _convertedEvent = convertEventVersion1To2(_evt);

            browserStorageUpdateSpecificEvent(_convertedEvent)
                .then(() => {
                    console.log("Updated browser storage for: " + _convertedEvent.title);

                    // Update Planning if the event matches
                    if (PA_GlobalObject.Planning.id === _convertedEvent.id) {
                        console.log("Updating Planning object with event details");

                        // Get the current Planning object
                        const currentPlanning = PA_GlobalObject.Planning;

                        // Create a copy of the current Planning object
                        const updatedPlanning = {
                            ...currentPlanning,
                            title: _convertedEvent.title,
                            description: _convertedEvent.description,
                            eventIntro: _convertedEvent.eventIntro,
                            coverImage: _convertedEvent.coverImage,
                            image: _convertedEvent.image,
                            cover_image_thumbnail: _convertedEvent.cover_image_thumbnail,
                            host: _convertedEvent.host,
                            budget_total: _convertedEvent.budget_total,
                            budget_currency: _convertedEvent.budget_currency,
                            budget_actual: _convertedEvent.budget_actual,
                        };

                        // Merge all changes into the new Planning object
                        updatedPlanning.time_table = mergeChanges(currentPlanning.time_table, _convertedEvent.time_table);
                        updatedPlanning.attendees = mergeChanges(currentPlanning.attendees, _convertedEvent.attendees);
                        updatedPlanning.activities = mergeChanges(currentPlanning.activities, _convertedEvent.activities);
                        updatedPlanning.lodging = mergeChanges(currentPlanning.lodging, _convertedEvent.lodging);
                        updatedPlanning.meals = mergeChanges(currentPlanning.meals, _convertedEvent.meals);
                        updatedPlanning.ideas = mergeChanges(currentPlanning.ideas, _convertedEvent.ideas);
                        updatedPlanning.flights = mergeChanges(currentPlanning.flights, _convertedEvent.flights);
                        updatedPlanning.car_rentals = mergeChanges(currentPlanning.car_rentals, _convertedEvent.car_rentals);
                        updatedPlanning.cruises = mergeChanges(currentPlanning.cruises, _convertedEvent.cruises);
                        updatedPlanning.supplies = mergeChanges(currentPlanning.supplies, _convertedEvent.supplies);
                        updatedPlanning.to_do = mergeChanges(currentPlanning.to_do, _convertedEvent.to_do);
                        updatedPlanning.documents = mergeChanges(currentPlanning.documents, _convertedEvent.documents);
                        updatedPlanning.receipts = mergeChanges(currentPlanning.receipts, _convertedEvent.receipts);
                        updatedPlanning.messages = mergeChanges(currentPlanning.messages, _convertedEvent.messages);
                        updatedPlanning.menu_items = mergeChanges(currentPlanning.menu_items, _convertedEvent.menu_items);
                        updatedPlanning.weather = mergeChanges(currentPlanning.weather, _convertedEvent.weather);
                        updatedPlanning.suggestions = mergeChanges(currentPlanning.suggestions, _convertedEvent.suggestions);
                        updatedPlanning.photos = mergeChanges(currentPlanning.photos, _convertedEvent.photos);

                        // Update the global store with the merged Planning object
                        console.log("The updated Planning object:", updatedPlanning);
                        set_PA_GlobalObject({ Planning: updatedPlanning });
                    }
                })
                .catch((error) => {
                    console.error("Error updating browser storage for event: " + _convertedEvent.title, error);
                });

        });
    } else {
        console.log("No Events need updating or result is not ok");
    }
}

export default E_getEventChangesResults;



export function E_setEventAllChangesResults(_incoming, _planning){
    console.log("E_setEventAllChangesResults : "+JSON.stringify(_incoming, null, '\t'));

    if (_incoming.result === 'ok') {
        //update the
        browserStorageUpdateSpecificEvent(_planning).then(_success => {
            if (_success){
                console.log("successful save to browser database");
            } else {
                console.log("problems saving Event to browser database");
            }

        });

    } else {
        // Handle problems getting the updated Events list
        console.error("Problems getting the updated Events list");
    }

}

export function E_updateEventNameResults(_incoming,
                                         _dataarray_events,
                                         _dataarray_planning,
                                         setDataArray){

    console.log("new event name : "+JSON.stringify(_incoming));

    if (_incoming.result === 'ok' && _incoming.event_name.length > 0) {
        console.log("Event name needs updating: " + _incoming.event_name.length);

        const updatedEvents = [..._dataarray_events];
        let updatedPlanning = {..._dataarray_planning};

        const eventIndex = updatedEvents.findIndex((event) => event.id === _incoming.event_id);

        console.log("eventIndex : " + eventIndex);

        if (eventIndex !== -1) {
            updatedEvents[eventIndex].title = _incoming.event_name;

            browserStorageUpdateSpecificEvent(updatedEvents[eventIndex])
                .then(() => {
                    // Update Planning if the event matches
                    if (updatedPlanning.id === _incoming.event_id) {
                        console.log("Updating Planning object with event name");
                        updatedPlanning.title = _incoming.event_name;
                    }

                    // Update React state
                    setDataArray((prevDataArray) => ({
                        ...prevDataArray,
                        Events: updatedEvents,
                        Planning: updatedPlanning,
                    }));
                });
        }
    }
}