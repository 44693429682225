
import Location from "./Location";
import TimeTable from "./TimeTable";
import PAAttendee from "./Attendee";



class Lodging {
    constructor(){

        const _temp = PAAttendee.generateRandomString(10);
        this.id = _temp;
        this.temp_id = _temp;

        this.name = "";

        this.time_table = new TimeTable();

        this.begin_date = "";
        this.end_date = "";

        this.begin_day = "";
        this.end_day = "";

        this.check_in = "";
        this.check_out = "";

        this.location = new Location();
        this.time_zone = ""
        this.phone = "";

        this.booked_through = "";
        this.confirmation_number = "";
        this.reservation_name = "";
        this.reservation_number = "";
        this.gate_code = "";
        this.door_code = "";
        this.wifi_name = "";
        this.wifi_password = "";

        this.attendees = [];	//array of occupants and their cost

        this.photos = [];

        this.amount_total = 0.00;
        this.currency = "USD";
        this.assign_equal = true;

        this.price_watch = false;
        this.price_history = [];

        this.notes= "";

        this.Duffel = [];
        this.Place = {};

        this.status = 0;
    }
}

export default Lodging;
