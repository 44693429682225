import TimeTable from "../../MasterEventObjects/TimeTable";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const possibleFormats = [
    'YYYY-MM-DDTHH:mm:ss',   // ISO 8601 format like "2020-05-16T00:00:00"
    'MM/DD/YYYY',            // Format like "09/25/2024"
    'YYYY-MM-DD',            // Basic ISO date format "2020-05-16"
];


export const createTimeTableForLodging = (begin_date, end_date, event_time_table) => {
    console.log("Lodging begin_date, end_date, event_time_table : " + begin_date + " : " + end_date + " : " + event_time_table);

    const timeTable = new TimeTable();

    // Extract event start and end dates from event.time_table.dates
    const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event time_table
    const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event time_table

    // Parse the begin and end dates for the lodging using multiple formats
    const start = dayjs(begin_date, possibleFormats);  // Lodging begin date
    const end = dayjs(end_date, possibleFormats);      // Lodging end date

    // Check if the dates are valid
    if (!start.isValid() || !end.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
        console.error("Invalid dates provided for the lodging or event.");
        return timeTable;
    }

    // Generate lodging dates between the start and end dates, inclusive
    let currentDate = start;
    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
        const dayOfWeek = currentDate.format("dddd"); // Store the day of the week

        // Calculate the day number relative to the event start date
        const dayNumber = currentDate.diff(eventStart, 'day') + 1;

        if (currentDate.isSame(start, 'day')) {
            timeTable.dates.push({
                date: currentDate.format("YYYY-MM-DD"), // Store date in YYYY-MM-DD format
                dayOfWeek: dayOfWeek,                   // Store day of the week (e.g., "Monday")
                checkin_time: '4:00 PM',                // Set checkin time
                notes: "",                              // You can add notes as needed
            });
            // Add the corresponding day to the TimeTable days
            timeTable.days.push({
                [`day ${dayNumber}`]: dayOfWeek,
                checkin_time: '4:00 PM',
                notes: "", // Additional notes can be added here
            });
        } else if (currentDate.isSame(end, 'day')) {
            timeTable.dates.push({
                date: currentDate.format("YYYY-MM-DD"), // Store date in YYYY-MM-DD format
                dayOfWeek: dayOfWeek,                   // Store day of the week (e.g., "Monday")
                checkout_time: '10:00 AM',                // Set checkin time
                notes: "",                              // You can add notes as needed
            });
            // Add the corresponding day to the TimeTable days
            timeTable.days.push({
                [`day ${dayNumber}`]: dayOfWeek,
                checkout_time: '10:00 AM',
                notes: "", // Additional notes can be added here
            });
        } else {
            // Push the current date to the dates array
            timeTable.dates.push({
                date: currentDate.format("YYYY-MM-DD"), // Store date in YYYY-MM-DD format
                dayOfWeek: dayOfWeek,                   // Store day of the week (e.g., "Monday")
                notes: "",                              // You can add notes as needed
            });

            // Add the corresponding day to the TimeTable days
            timeTable.days.push({
                [`day ${dayNumber}`]: dayOfWeek,
                notes: "", // Additional notes can be added here
            });
        }



        // Move to the next day
        currentDate = currentDate.add(1, 'day');
    }

    console.log("returning : "+JSON.stringify(timeTable));

    return timeTable;
};

export const createTimeTableForActivity = (begin_date, end_date, all_day_activity, begin_time, end_time, event_time_table) => {
    console.log("begin_date, end_date, all_day_activity, begin_time, end_time, event_time_table : " + begin_date + " : " + end_date + " : " + all_day_activity + " : " + begin_time + " : " + end_time + " : " + event_time_table);

    const timeTable = new TimeTable();

    // Extract event start and end dates from event.time_table.dates
    const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event time_table
    const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event time_table

    // Define possible date formats that might be used for begin_date and end_date
    const possibleFormats = ["MM/DD/YYYY", "M/D/YYYY", "YYYY-MM-DD"];

    // Helper function to parse a date using multiple formats
    const parseDate = (date) => {
        // Try to parse the date using one of the possible formats
        let parsedDate = null;
        for (let format of possibleFormats) {
            parsedDate = dayjs(date, format, true); // The true flag enforces strict parsing
            if (parsedDate.isValid()) {
                break; // Exit the loop once a valid date is found
            }
        }
        return parsedDate;
    };

    // Parse the begin and end dates for the activity using the helper function
    const start = parseDate(begin_date); // Activity begin date
    const end = parseDate(end_date);     // Activity end date

    // Check if the dates are valid
    if (!start.isValid() || !end.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
        console.error("Invalid dates provided for the activity or event.");
        return timeTable;
    }

    // Generate activity dates between the start and end dates, inclusive
    let currentDate = start;
    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
        const dayOfWeek = currentDate.format("dddd"); // Store the day of the week

        // Calculate the day number relative to the event start date
        const dayNumber = currentDate.diff(eventStart, 'day') + 1;

        // Determine if it's an all-day event or has specific begin and end times
        const allDay = all_day_activity;
        const beginTime = !allDay ? begin_time : null;  // If it's not all-day, use the begin_time
        const endTime = !allDay ? end_time : null;      // If it's not all-day, use the end_time

        // Push the current date to the dates array
        timeTable.dates.push({
            date: currentDate.format("YYYY-MM-DD"),    // Store date in YYYY-MM-DD format
            dayOfWeek: dayOfWeek,                      // Store day of the week (e.g., "Monday")
            'all-day': allDay,                         // Set based on the all_day_activity flag
            begin_time: beginTime,                     // Begin time for non-all-day activities
            end_time: endTime,                         // End time for non-all-day activities
            notes: "",                                 // You can add notes as needed
        });

        // Add the corresponding day to the TimeTable days
        timeTable.days.push({
            [`day ${dayNumber}`]: dayOfWeek,
            'all-day': allDay,
            begin_time: beginTime,   // Include the begin_time for non-all-day activities
            end_time: endTime,       // Include the end_time for non-all-day activities
            notes: "",  // Additional notes can be added here
        });

        // Move to the next day
        currentDate = currentDate.add(1, 'day');
    }

    return timeTable;
};

export const createTimeTableForMeal = (meal_date, meal_time, event_time_table) => {
    console.log("meal_date, meal_time, event_time_table : " + meal_date + " : " + meal_time + " : " + event_time_table);

    const timeTable = new TimeTable();

    // Extract event start and end dates from event_time_table.dates
    const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event_time_table
    const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event_time_table

    // Define possible date formats for the meal_date
    const possibleFormats = ["MM/DD/YYYY", "M/D/YYYY", "YYYY-MM-DD"];

    // Helper function to parse a date using multiple formats
    const parseDate = (date) => {
        let parsedDate = null;
        for (let format of possibleFormats) {
            parsedDate = dayjs(date, format, true); // Strict parsing
            if (parsedDate.isValid()) {
                break; // Stop as soon as we get a valid date
            }
        }
        return parsedDate;
    };

    // Parse the meal_date using the helper function
    const mealDate = parseDate(meal_date);

    // Check if the mealDate and event start/end dates are valid
    if (!mealDate.isValid() || !eventStart.isValid() || !eventEnd.isValid()) {
        console.error("Invalid dates provided for the meal or event.");
        return timeTable;
    }

    // Ensure that the meal date is within the event timeline
    if (mealDate.isBefore(eventStart) || mealDate.isAfter(eventEnd)) {
        console.error("Meal date is outside of the event timeline.");
        return timeTable;
    }

    const dayOfWeek = mealDate.format("dddd"); // Store the day of the week

    // Calculate the day number relative to the event start date
    const dayNumber = mealDate.diff(eventStart, 'day') + 1;

    // Add the meal information to the timeTable
    timeTable.dates.push({
        date: mealDate.format("YYYY-MM-DD"),      // Store date in YYYY-MM-DD format
        dayOfWeek: dayOfWeek,                     // Store day of the week (e.g., "Monday")
        'all-day': false,                         // Meal is not an all-day event
        begin_time: meal_time,                    // Store meal time
        notes: "",                                // Add notes if needed
    });

    // Add the corresponding day to the TimeTable days
    timeTable.days.push({
        [`day ${dayNumber}`]: dayOfWeek,
        'all-day': false,
        begin_time: meal_time,  // Store meal time
        notes: "",  // Add any additional notes here
    });

    return timeTable;
};

export const createTimeTableForFlight = (depart_timestamp, arrive_timestamp, event_time_table) => {
    console.log("depart_timestamp, arrive_timestamp, event_time_table : " + depart_timestamp + " : " + arrive_timestamp + " : " + event_time_table);

    const timeTable = new TimeTable();

    // Helper function to determine if the timestamp is Unix (numeric) or ISO string
    const parseTimestamp = (timestamp) => {
        if (typeof timestamp === 'number') {
            // Assume it's a Unix timestamp if it's a number
            return dayjs.unix(timestamp);
        } else if (typeof timestamp === 'string') {
            // Assume it's an ISO string if it's a string
            return dayjs(timestamp);
        } else {
            // Invalid format
            console.error('Invalid timestamp format: ', timestamp);
            return null;
        }
    };

    // Parse the depart and arrive timestamps using the helper function
    const departDateTime = parseTimestamp(depart_timestamp); // Flight departure timestamp
    const arriveDateTime = parseTimestamp(arrive_timestamp); // Flight arrival timestamp

    // Check if the timestamps are valid
    if (!departDateTime?.isValid() || !arriveDateTime?.isValid()) {
        console.error("Invalid timestamps provided.");
        return timeTable;
    }

    // Extract event start and end dates from event_time_table.dates
    const eventStart = dayjs(event_time_table.dates[0].date, "YYYY-MM-DD"); // First date in event time_table
    const eventEnd = dayjs(event_time_table.dates[event_time_table.dates.length - 1].date, "YYYY-MM-DD"); // Last date in event time_table

    if (!eventStart.isValid() || !eventEnd.isValid()) {
        console.error("Invalid event time table dates.");
        return timeTable;
    }

    // Ensure that the flight's timestamps are within the event timeline
    if (departDateTime.isBefore(eventStart) || arriveDateTime.isAfter(eventEnd)) {
        console.error("Flight dates are outside of the event timeline.");
        return timeTable;
    }

    // Extract the day of the week for departure and arrival
    const departDayOfWeek = departDateTime.format("dddd"); // e.g., "Monday"
    const arriveDayOfWeek = arriveDateTime.format("dddd"); // e.g., "Tuesday"

    // Calculate the day number relative to the event start date
    const departDayNumber = departDateTime.diff(eventStart, 'day') + 1;
    const arriveDayNumber = arriveDateTime.diff(eventStart, 'day') + 1;

    // Add the departure information to the timeTable
    timeTable.dates.push({
        date: departDateTime.format("YYYY-MM-DD"),    // Departure date in YYYY-MM-DD format
        dayOfWeek: departDayOfWeek,                   // Day of the week for departure
        'all-day': false,                             // Flights are not all-day events
        depart_time: departDateTime.format('h:mm A'), // Format the departure time
        notes: "",                                    // Any additional notes can be added here
    });

    // Add the arrival information to the timeTable
    timeTable.dates.push({
        date: arriveDateTime.format("YYYY-MM-DD"),    // Arrival date in YYYY-MM-DD format
        dayOfWeek: arriveDayOfWeek,                   // Day of the week for arrival
        'all-day': false,                             // Flights are not all-day events
        arrive_time: arriveDateTime.format('h:mm A'), // Format the arrival time
        notes: "",                                    // Any additional notes can be added here
    });

    // Add the corresponding days to the TimeTable days
    timeTable.days.push({
        [`day ${departDayNumber}`]: departDayOfWeek,
        'all-day': false,
        depart_time: departDateTime.format('h:mm A'),
        notes: "",
    });

    timeTable.days.push({
        [`day ${arriveDayNumber}`]: arriveDayOfWeek,
        'all-day': false,
        arrive_time: arriveDateTime.format('h:mm A'),
        notes: "",
    });

    return timeTable;
};